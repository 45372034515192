import { put, call } from 'redux-saga/effects';
import { getNews } from '../service/news_events';
import { getNewsSuccess } from '../action/news_eventsaction';
import * as types from '../action.type';

export function* getNewsSaga(data) {
    const response = yield call(getNews, data);
    if (response && !response.error) {
        yield put(getNewsSuccess(response.data));
    } else {
        yield put({ type: types.GET_NEWS_EVENTS_ERROR, response });
    }
}
