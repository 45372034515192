import Grid from "@material-ui/core/Grid";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import Dropdowns from "_Shared/Dropdowns";
import SubFooter from "_Shared/SubFooter";
import { usePhoneLandScape } from "_Utils/Helpers";
import { DownloadsList } from "_Constants/Goals";
import { getdownloadAction } from "../../store/action/download";
import { getProductCat } from "_Store/action/productcategory";
import { get_all_product_Action } from "_Store/action/productAction";
const useStyles = makeStyles(() => ({
  root: { paddingTop: 122 },
}));

const Downloads = () => {
  const classes = useStyles();
  const isPhone = usePhoneLandScape();
  const dispatch = useDispatch();
  const downloadFiles = useSelector((state) => state.getDownloadReducer);
  const AllProducts = useSelector((state) => state.get_ALL_ProductReducer);
  const AllProductCat = useSelector((state) => state.getProductCat);
  useEffect(() => {
    dispatch(getdownloadAction());
    dispatch(get_all_product_Action());
    dispatch(getProductCat());
  }, []);
  let productCategory = [
    ...new Set(
      AllProductCat &&
        AllProductCat.allProductCat &&
        AllProductCat.allProductCat.map((item) => {
          return item;
        })
    ),
  ];

  const btnText = (
    <>
      <PhoneAndroidIcon />
      &nbsp;&nbsp;
      <div>CONTACT US</div>
    </>
  );

  const DownloadsArr = DownloadsList?.topics.map((topic) => ({
    topic: topic.title,
    expanded: false,
    query: topic.query,
  }));
  const ProductCatArr =
    productCategory &&
    productCategory.map((topic) => ({
      topic: topic,
      expanded: false,
    }));

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <div className="centerDiv flexColumn">
          <div className={isPhone ? "text32" : "text56"}>Downloads</div>
          <div className={isPhone ? "text16 darkGrey" : "text20 darkGrey"}>
            Frequently downloaded assets.
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={12} sm={12}>
        <Grid md={12} xs={12} sm={12} lg={12} item>
          <Dropdowns
            listArr={downloadFiles}
            staticData={DownloadsArr}
            productCategory={ProductCatArr}
            AllProducts={AllProducts}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} sm={12}>
        <div className="centerDiv">
          <SubFooter
            heading="Enquire Now"
            subHeading="Build your network infrastructure with us"
            btnText={btnText}
            btnHref="/contact#form"
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Downloads;
