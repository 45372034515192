import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { useTablet, GenerateIcon } from "_Utils/Helpers";
import LogoIcon from "_Icons/logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "4% 5% 0% 5%",
    position: "fixed",
    zIndex: 30,
    top: "10",
    backgroundColor: "transparent",
    [theme.breakpoints.up("sm")]: {
      padding: "2% 4% 0% 5%",
    },
  },
  logo: {
    width: 45,
    height: 45,
    [theme.breakpoints.up("sm")]: {
      width: 50,
      height: 50,
    },
    [theme.breakpoints.up("md")]: {
      width: 60,
      height: 60,
    },
  },
  iconBtn: {
    marginTop: 5,
  },
  menuIcon: {
    width: 25,
    height: 25,
    [theme.breakpoints.up("sm")]: {
      width: 30,
      height: 30,
    },
    [theme.breakpoints.up("md")]: {
      width: 35,
      height: 35,
    },
  },
  drawerPaper: {
    background: "#292827",
    width: "100vw",
    height: "100vh",
    color: "#FFFFFF",
    overflow: "hidden",
  },
  drawerContainer: { width: "100vw", height: "100vh" },
  drawerList: { listStyle: "none", width: "100%" },
  drawerButton: {
    fontFamily: "MavenPro-Regular",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "150%",
    marginBottom: 40,
    width: 110,
    [theme.breakpoints.up("md")]: {
      fontSize: 24,
      width: 150,
    },
  },
  contactButton: {
    padding: "8px 16px",
    width: 104,
    height: 40,
    // background: 'linear-gradient(90deg, #FD6A02 10%, #FF5500 100%)',
    // borderRadius: 32,
    [theme.breakpoints.up("md")]: {
      fontSize: 24,
      width: 160,
      height: 56,
    },
  },
  xIcon: { position: "absolute", top: 20, right: 30, color: "#fff" },
  contactBtn: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginBottom: 40,
  },
  menuButton: {
    background: "linear-gradient(to left, #030201, #030201) no-repeat",
    cursor: "pointer",
    backgroundPosition: "right",
    backgroundSize: "63% 4px",
    border: "solid #030201",
    borderWidth: "4px 0 0",
    borderRadius: 3,
    height: 20,
    width: 28,
  },
}));

const HeaderMobile = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isTab = useTablet();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const GetDrawerChoices = () => {
    return (
      <List component="nav" className={classes.drawerList}>
        <Grid container>
          <Grid container item xs={12} sm={6} md={12} justifyContent="center">
            <ListItem
              button
              className={classes.drawerButton}
              onClick={() => {
                navigate("/products");
                setDrawerOpen(false);
              }}
            >
              <div className="stretch centerDiv">PRODUCTS</div>
            </ListItem>
          </Grid>
          <Grid container item xs={12} sm={6} md={12} justifyContent="center">
            <ListItem
              button
              className={classes.drawerButton}
              onClick={() => {
                navigate("/solutions");
                setDrawerOpen(false);
              }}
            >
              <div className="stretch centerDiv">SOLUTIONS</div>
            </ListItem>
          </Grid>
          <Grid container item xs={12} sm={6} md={12} justifyContent="center">
            <ListItem
              button
              className={classes.drawerButton}
              onClick={() => {
                navigate("/software");
                setDrawerOpen(false);
              }}
            >
              <div className="stretch centerDiv">SOFTWARE</div>
            </ListItem>
          </Grid>
          <Grid container item xs={12} sm={6} md={12} justifyContent="center">
            <ListItem
              button
              className={classes.drawerButton}
              onClick={() => {
                navigate("/about");
                setDrawerOpen(false);
              }}
            >
              <div className="stretch centerDiv">ABOUT</div>
            </ListItem>
          </Grid>
          <Grid container item xs={12} sm={6} md={12} justifyContent="center">
            <ListItem
              button
              className={classes.drawerButton}
              onClick={() => {
                navigate("/careers");
                setDrawerOpen(false);
              }}
            >
              <div className="stretch centerDiv">CAREER</div>
            </ListItem>
          </Grid>
          <Grid container item xs={12} sm={6} md={12} justifyContent="center">
            <ListItem
              button
              className={`${classes.contactButton} orangeBtn`}
              onClick={() => {
                navigate("/contact");
                setDrawerOpen(false);
              }}
            >
              <div className="stretch centerDiv" style={{ fontWeight: 700 }}>
                CONTACT
              </div>
            </ListItem>
          </Grid>
        </Grid>
      </List>
    );
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={3}>
        <NavLink to="/">
          {/* <img src={Logo} className={classes.logo} alt="logo" /> */}
          <GenerateIcon
            iconPath={LogoIcon}
            alt="logo"
            className={classes.logo}
          />
        </NavLink>
      </Grid>
      <Grid
        container
        item
        xs={9}
        justifyContent="flex-end"
        onClick={() => setDrawerOpen(true)}
      >
        <div className="glass">
          <IconButton className={classes.iconBtn}>
            {/* <MenuOutlinedIcon className={classes.menuIcon} /> */}
            <Grid item className={classes.menuButton} />
          </IconButton>
        </div>
      </Grid>
      <Drawer
        anchor={"left"}
        classes={{ paper: classes.drawerPaper }}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Grid item md={12}>
          <IconButton
            justifyContent="flex-end"
            className={classes.xIcon}
            onClick={() => setDrawerOpen(false)}
          >
            <CloseOutlinedIcon />
          </IconButton>
          <div className={`centerDiv ${classes.drawerContainer}`}>
            <GetDrawerChoices />
          </div>
        </Grid>
      </Drawer>
    </Grid>
  );
};

export default HeaderMobile;
