import React from "react";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { Grid, Typography, makeStyles } from "@material-ui/core";

const Software = (props) => {
  const {
    bannerImage,
    rightDiv,
    leftDiv,
    DevContent_Software,
    OpenWrt,
    both_Iopsys_Gen,
  } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      position: "relative ",
      height: OpenWrt ? "720px" : both_Iopsys_Gen ? "100%" : "850px",
      overFlowX: "inherit",
      [theme.breakpoints.down("xs")]: {
        height: OpenWrt ? "720px" : both_Iopsys_Gen ? "100%" : "900px",
      },
    },
    bannerImage: {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
    attrText: {
      padding: "0px 20px",
      textAlign: "start",
      fontSize: 14,
      lineHeight: "150%",
      color: "#FFFFFF",
      marginTop: 24,
      [theme.breakpoints.down("xs")]: {
        marginTop: 8,
      },
    },
    right_content_div: {
      position: "absolute",
      width: "100%",
      top: OpenWrt ? "30%" : 130,

      [theme.breakpoints.down("xs")]: {
        top: 280,
        padding: 16,
      },
      [theme.breakpoints.between("lg", "md")]: {
        top: 50,
      },
      [theme.breakpoints.down("md")]: {
        padding: 16,
      },
    },
    left_content_div: {
      position: "absolute",
      width: "100%",
      bottom: 50,
      padding: 24,
      display: "flex",
      overFlowX: "inherit",
      [theme.breakpoints.down("md")]: {
        bottom: 0,
        padding: 16,
      },
    },
  }));
  const classes = useStyles();
  return (
    <Grid
      md={12}
      xs={12}
      sm={12}
      lg={12}
      item
      container
      className={classes.root}
      id={props.id}
    >
      <Grid md={12} xs={12} sm={12} item className={classes.bannerImage}>
        <img
          src={bannerImage}
          alt="bannerImage"
          height="100%"
          width="100%"
          style={{ objectFit: "cover" }}
        />
        {rightDiv ? (
          <Grid
            md={12}
            xs={12}
            lg={12}
            sm={12}
            item
            className={classes.right_content_div}
          >
            <Grid lg={11} item container justifyContent="flex-end">
              <Grid
                lg={7}
                sm={12}
                md={12}
                xs={12}
                item
                container
                justifyContent="flex-end"
                style={{}}
              >
                <Grid lg={11} item>
                  {rightDiv && (
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      item
                      container
                      justifyContent="flex-end"
                    >
                      <AnimatedDiv children={rightDiv} animation="fade-left" />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid lg={1} item container justifyContent="flex-end" />
          </Grid>
        ) : (
          <Grid
            md={12}
            xs={12}
            lg={12}
            sm={12}
            item
            className={classes.left_content_div}
          >
            <Grid lg={1} item />
            <Grid lg={11} item>
              <Grid
                lg={7}
                sm={12}
                md={12}
                xs={12}
                item
                container
                justifyContent="flex-end"
              >
                <Grid lg={11} item>
                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    item
                    container
                    justifyContent="flex-end"
                  >
                    <AnimatedDiv children={leftDiv} animation="fade-right" />
                  </Grid>

                  {DevContent_Software ? (
                    <Grid
                      container
                      justifyContent="flex-start"
                      className={classes.attrText}
                    >
                      <div>
                        Icons made by{" "}
                        <b>
                          <a
                            href="https://www.freepik.com"
                            className="link white"
                            title="Freepik"
                            style={{ color: "#fff" }}
                          >
                            Freepik,{" "}
                          </a>
                        </b>{" "}
                        <b>
                          <a
                            href="https://www.flaticon.com/authors/nhor-phai"
                            title="Nhor Phai"
                            className="link white"
                            style={{ color: "#fff" }}
                          >
                            Nhor Phai,{" "}
                          </a>
                        </b>{" "}
                        <b>
                          <a
                            href="https://www.flaticon.com/authors/pixel-perfect"
                            title="Pixel perfect"
                            className="link white"
                            style={{ color: "#fff" }}
                          >
                            Pixel perfect
                          </a>
                        </b>{" "}
                        from{" "}
                        <b>
                          <a
                            href="https://www.flaticon.com/"
                            title="Flaticon"
                            className="link white"
                            style={{ color: "#fff" }}
                          >
                            www.flaticon.com
                          </a>
                        </b>
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Software;
