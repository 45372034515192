import * as types from '../action.type';

export const postContactReducer = (state = {}, action) => {
    const response = action.response;

    switch (action.type) {
        case types.POST_CONTACT_SUCCESS:
            return response;
        case types.POST_CONTACT_ERROR:
            return state;
        default:
            return state;
    }
};
