import { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { getNews } from "_Store/action/news_eventsaction";
import { BlackBtn } from "_Shared/Buttons";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { usePhoneLandScape, useSmallPhone } from "_Utils/Helpers";
import { CardMedia } from "@material-ui/core";

const News = (props) => {
  const { _news, partial } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      paddingTop: 130,
      [theme.breakpoints.down("md")]: {
        paddingTop: "100px",
        padding: 48,
      },
    },

    parent: {
      borderRadius: 4,
      display: "block",
      [theme.breakpoints.down("sm")]: {
        padding: 16,
      },
    },
    newsSection: { marginTop: 32 },
    card: {
      width: "100%",
      boxShadow: "none",
      background: "#fff",
      height: "100%",
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
    },
    cardContent: {
      padding: 24,
    },
    cardImage: {
      height: 220,
    },
    cardImg: {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
    cardTextDiv: {
      display: "block",
    },
    title: {
      margin: "4px 0px 8px 0px",
      [theme.breakpoints.up("md")]: {
        margin: "4px 0px 16px 0px",
      },
    },
    archiveBtn: {
      marginTop: 48,
      [theme.breakpoints.up("md")]: {
        marginTop: 56,
      },
    },
  }));

  const isPhone = usePhoneLandScape();
  const classes = useStyles();

  useEffect(() => {
    props.getNews();
  }, []);

  let NewsData = _news && _news.newsEvents;
  NewsData = partial ? NewsData?.slice(0, 3) : NewsData;
  const isMobile = useSmallPhone();
  return (
    <Grid
      container
      item
      justifyContent="center"
      xs={12}
      className={classes.root}
    >
      <Grid container item justifyContent="center" xs={12}>
        <AnimatedDiv
          children={
            <div>
              <div
                className={isPhone ? "text32" : "text56 textCenter"}
                style={{ textAlign: isPhone ? "center" : "" }}
              >
                News &amp; Events
              </div>
              <div
                className={
                  isPhone ? "text16 darkGrey w400" : "text20 darkGrey w400"
                }
                style={{ textAlign: "center" }}
              >
                Latest from us
              </div>
            </div>
          }
          animation="fade-up"
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sm={12}
        container
        style={{
          marginTop: 48,
          marginBottom: window.location.pathname === "/news" ? 100 : 0,
        }}
      >
        <Grid lg={1} item />
        <Grid
          lg={10}
          xs={12}
          sm={12}
          item
          md={12}
          container
          justifyContent="center"
          spacing={isMobile ? 0 : 4}
        >
          <Grid container spacing={4}>
            {NewsData &&
              NewsData.map((obj, i) => (
                <Grid
                  item
                  xs={12}
                  className={classes.parent}
                  sm={6}
                  lg={4}
                  key={i}
                  container
                  justifyContent="space-between"
                >
                  <Grid
                    lg={12}
                    container
                    item
                    justifyContent="flex-end"
                    style={{
                      background: "#fff",
                      height: "100%",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <Link to={`/news/${obj._id}`} className="link">
                      <Grid
                        md={12}
                        xs={12}
                        sm={12}
                        item
                        container
                        justifyContent="space-between"
                      >
                        <AnimatedDiv
                          children={
                            <>
                              <CardMedia
                                className={classes.cardImage}
                                image={obj.imgUrl}
                              />

                              <CardContent className={`${classes.cardContent}`}>
                                <span className={classes.cardTextDiv}>
                                  <div
                                    className={
                                      isPhone
                                        ? "text10 primary mp-semi"
                                        : "text12 primary mp-semi"
                                    }
                                  >
                                    {obj.type.toUpperCase()}
                                  </div>
                                  <div
                                    className={
                                      isPhone
                                        ? `${classes.title} text16 secondary mp-bold`
                                        : `${classes.title} text24 secondary mp-bold`
                                    }
                                  >
                                    {obj.title}
                                  </div>
                                  <div
                                    className={
                                      isPhone
                                        ? "text12 darkGrey w400"
                                        : "text16 darkGrey w400"
                                    }
                                  >
                                    {obj.shortDesc}
                                  </div>
                                </span>
                              </CardContent>
                            </>
                          }
                          animate="fade-up"
                        />
                      </Grid>
                    </Link>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid lg={1} item />
      </Grid>
      {partial && (
        <Grid
          container
          item
          xs={12}
          md={12}
          sm={12}
          justifyContent="center"
          className={classes.archiveBtn}
        >
          <Grid
            xs={12}
            md={12}
            sm={12}
            lg={12}
            container
            justifyContent="center"
          >
            {/* <AnimatedDiv
              children={
                <BlackBtn
                  text="View Archive"
                  width={isPhone ? 130 : 160}
                  height={isPhone ? 33 : 40}
                  padding={isPhone ? "0px" : "8px 8px"}
                  outlined={true}
                  btnHref="/news"
                />
              }
              animate="fade-up"
            /> */}
            <BlackBtn
              text="View Archive"
              width={isPhone ? 130 : 160}
              height={isPhone ? 33 : 40}
              padding={isPhone ? "0px" : "8px 8px"}
              outlined={true}
              btnHref="/news"
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = ({ getNewsReducer }) => {
  return { _news: getNewsReducer };
};

export default connect(mapStateToProps, { getNews })(News);
