import { put, call } from "redux-saga/effects";
import {
  getproductService,
  getALLproductService,
} from "../service/productService";
import {
  getProductActionSuccess,
  get_all_product_ActionSuccess,
} from "../action/productAction";
import * as types from "../action.type";
export function* getProductSaga(data) {
  const response = yield call(getproductService, data);
  if (response && !response.error) {
    yield put(getProductActionSuccess(response.data));
  } else {
    yield put({ type: types.GETPRODUCT_ERROR, response });
  }
}

export function* get_ALL_ProductSaga(data) {
  const response = yield call(getALLproductService, data);
  if (response && !response.error) {
    yield put(get_all_product_ActionSuccess(response.data));
  } else {
    yield put({ type: types.GET_ALL_PRODUCT_ERROR, response });
  }
}
