import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { usePhoneLandScape } from "_Utils/Helpers";
import RewardsImg from "_Images/rewards/rewards.png";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "100px 20px 50px 20px",
    [theme.breakpoints.up("md")]: {
      padding: "120px 30px 75px 30px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "140px 125px 100px 125px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "140px 175px 125px 175px",
    },
  },
  heading: {
    marginBottom: 30,
    [theme.breakpoints.up("md")]: {
      marginBottom: 48,
    },
  },
  img: {
    width: 225,
    height: 150,
    marginBottom: 20,
    [theme.breakpoints.up("xs")]: {
      width: 300,
      height: 180,
      marginBottom: 30,
    },
    [theme.breakpoints.up("sm")]: {
      width: 320,
      height: 200,
      marginBottom: 0,
    },
    [theme.breakpoints.up("md")]: {
      width: 350,
      height: 200,
      marginBottom: 0,
    },
    [theme.breakpoints.up("lg")]: {
      width: 445,
      height: 300,
    },
  },
  rewardsText: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px 15px 0px 15px",
    },
  },
  listItem: {
    color: "#2C2E30",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "150%",
    marginBottom: 12,
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
    },
  },
}));
const Participate = () => {
  const classes = useStyles();
  const isPhone = usePhoneLandScape();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.heading}>
        <AnimatedDiv
          children={
            <>
              <div
                className={
                  isPhone ? "text24 primary textCenter" : "text56 textCenter"
                }
                style={{ marginBottom: 8 }}
              >
                Vulnerability Reward Program
              </div>
              <div
                className={
                  isPhone
                    ? "text14 darkGrey textCenter"
                    : "text20 darkGrey textCenter"
                }
              >
                Find bug, get rewarded!
              </div>
            </>
          }
          animation="fade-up"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <AnimatedDiv
          children={
            <div className="centerDiv stretch">
              <img src={RewardsImg} alt="rewards" className={classes.img} />
            </div>
          }
          animation={isPhone ? "fade-up" : "fade-right"}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.rewardsText}>
        <AnimatedDiv
          children={
            <>
              <div
                className={
                  isPhone
                    ? "text16 secondary mp-bold"
                    : "text24 secondary mp-bold"
                }
                style={{ marginBottom: 16 }}
              >
                <b>How to participate?</b>
              </div>
              <div className={classes.listItem}>
                1. Find the bug in any of our products – ONT, OLT, Mobile apps,
                NMS tool
              </div>
              <div className={classes.listItem}>
                2. Create a step-wise method of replication of the bug before
                sending it to us.
              </div>
              <div className={classes.listItem}>
                3. Mail us the bug at{" "}
                <a
                  href="mailto:Security-disclosure@gxgroup.eu"
                  className="link primary"
                >
                  Security-disclosure@gxgroup.eu
                </a>
              </div>
              <div className={classes.listItem}>
                4. The bug will be verified by the concerned team here at GX
                India Pvt. Ltd.
              </div>
            </>
          }
          animation={isPhone ? "fade-up" : "fade-left"}
        />
      </Grid>
    </Grid>
  );
};

export default Participate;
