import * as types from "../action.type";

export const postContact = (data) => {
  return {
    type: types.POST_CONTACT,
    data,
  };
};

export const postContactSuccess = (data) => {
  return {
    type: types.POST_CONTACT_SUCCESS,
    data,
  };
};

export const postContactError = (data) => {
  return {
    type: types.POST_CONTACT_ERROR,
    data,
  };
};
