import api from '_Utils/Api';

export const getProductCat = (req) =>
    api
        .get('/productCategory/getAllProductCat')
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return err.response;
        });
