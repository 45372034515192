import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { getProductCat } from "_Store/action/productcategory";
import { get_all_product_Action } from "_Store/action/productAction";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { useDispatch, useSelector } from "react-redux";
import { usePhoneLandScape, slugify, useSmallPhone } from "_Utils/Helpers";
import { IconButton } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AOS from "aos";
import "aos/dist/aos.css";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "rgba(3, 2, 1, 0.85)",
    [theme.breakpoints.down("md")]: {
      paddingBottom: 48,
    },
  },
  heading: {
    display: "block",
    marginTop: 280,
    [theme.breakpoints.down("md")]: {
      marginTop: 200,
    },
  },
  arrowIcon: {
    fontSize: 8,
    borderRadius: "50%",
    color: "#000",
    padding: 8,
    [theme.breakpoints.up("md")]: {
      fontSize: 12,
    },
  },
  viewBtn: {
    alignItems: "flex-end",
    position: "absolute",
    bottom: 40,
    marginTop: 40,
    background: "linear-gradient(90deg, #FD6A02 10%, #FF5500 100%)",
    padding: "8px 24px",
    borderRadius: 32,
    color: "#FFF3EB",
    width: "max-content",
  },
  viewMoreLink: {
    textDecoration: "none",
    color: "#FFF3EB",
    background: "linear-gradient(90deg, #FD6A02 10%, #FF5500 100%)",
    borderRadius: 32,
  },
  text16: {
    fontWeight: 700,
    color: "#FFF3EB",
  },
  title: {
    fontSize: 40,
    fontWeight: "bold",
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
  },
  subtitle: {
    fontSize: 20,
    color: "#FFFFFF",
    marginTop: 8,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  blackBox: {
    background:
      "linear-gradient(147.7deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 100.77%)",
    height: "300px",
    padding: 40,
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.3)",
    boxSizing: "border-box",
    boxShadow: "8px 8px 16px rgba(255, 255, 255, 0.04)",
    backdropFilter: "blur(16px)",
    borderRadius: "16px",
    marginTop: 80,
    left: -80,
    [theme.breakpoints.down("md")]: {
      left: 0,
      marginTop: -54,
      margin: 16,
    },
  },
  imageGrid: {
    height: 512,
    width: "100%",
    marginBottom: 120,
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      height: 270,
    },
  },
  arrows: {
    display: "flex",
    marginTop: 24,
    marginLeft: 24,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      marginLeft: 0,
    },
  },
  arrowbutton: {
    padding: 8,
  },
  titleGrid: {
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
      padding: 24,
    },
  },
  caroselGrid: {
    marginTop: 50,
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
}));

const Products = ({ _product, getProductCat }) => {
  const classes = useStyles();
  const isPhone = usePhoneLandScape();

  const dispatch = useDispatch();
  const AllProducts = useSelector((state) => state.get_ALL_ProductReducer);
  useEffect(() => {
    getProductCat();
    dispatch(get_all_product_Action());
  }, []);
  // productCategory=Other Equipment
  let productList = _product && _product.allProductCat;
  let otherData = AllProducts
    ? AllProducts &&
      AllProducts.allProduct &&
      AllProducts &&
      AllProducts.allProduct.filter(
        (item) => item.productCategory === "Other Equipment"
      )
    : null;
  let emsTitle = otherData
    ? otherData && otherData[0] && otherData[0].title
    : null;
  let emsdesc = otherData
    ? otherData && otherData[0] && otherData[0].shortDesc
    : null;
  let emsprodCat = otherData && otherData[0] && otherData[0].productCategory;
  let emsImage = otherData && otherData[0] && otherData[0].featuredImage;

  // const [productData] = useState(productList);
  const [slideIndex, setSlideIndex] = useState(0);
  const [prevSlide, setprevSlide] = useState(false);
  const [nextSlide, setnextSlide] = useState(false);
  // arrows slider
  const previous = () => {
    if (slideIndex === 0) {
      return setSlideIndex(productList && productList.length - 1);
    }

    setSlideIndex(slideIndex - 1);
    setprevSlide(true);
    setnextSlide(false);

    setTimeout(() => {
      setprevSlide(false);
    }, 500);
  };
  const next = () => {
    // if (slideIndex === productList && productList.length - 1) {
    //   return setSlideIndex(0);
    // }
    setSlideIndex(slideIndex + 1);
    setnextSlide(true);
    setprevSlide(false);
    setTimeout(() => {
      setnextSlide(false);
    }, 500);
  };
  let length = productList && productList.length - 1;

  return (
    <Grid container className={classes.root}>
      <Grid container item xs={12}>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          className={classes.heading}
        >
          <AnimatedDiv
            children={
              <div className={classes.titleGrid}>
                <div
                  className={isPhone ? "text32" : "text56"}
                  // data-aos={prevSlide ? "fade-right" : "fade-up"}
                >
                  Our Products
                </div>
                <div
                  className={
                    isPhone ? "text16 lightGrey w400" : "text20 lightGrey w400"
                  }
                >
                  Market leading portfolio for tailor-made needs
                </div>
              </div>
            }
            // animation="fade-up"
          />
        </Grid>

        {/* carosoul */}

        <Grid
          md={12}
          xs={12}
          sm={12}
          lg={12}
          item
          container
          alignItems="center"
          className={classes.caroselGrid}
        >
          <Grid md={1} item />

          <Grid md={12} xs={12} sm={12} lg={10} item container>
            <Grid
              lg={7}
              xs={12}
              sm={12}
              md={12}
              item
              container
              justifyContent="center"
              data-aos={"fade-right"}
              data-aos-once={true}
            >
              <Grid md={12} xs={12} sm={12} lg={12} item>
                <div
                  style={{
                    transition: "1s",
                    opacity: 1,
                    height: isPhone ? 270 : 512,
                    width: "100%",
                    marginBottom: isPhone ? 0 : 120,
                  }}
                  className={
                    prevSlide
                      ? "mySlides fade"
                      : nextSlide
                      ? "mySlides fadeRight"
                      : ""
                  }
                >
                  <img
                    src={productList && productList[slideIndex].imgUrl}
                    alt="rd"
                    height="100%"
                    width="100%"
                    style={{
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              lg={5}
              xs={12}
              sm={12}
              md={12}
              item
              style={{ opacity: 1 }}
              data-aos={"fade-left"}
              data-aos-once={true}
            >
              <Grid md={12} xs={12} sm={12} lg={12} item>
                <div className={classes.blackBox}>
                  <div className={classes.title}>
                    {productList && productList[slideIndex].title}
                  </div>
                  <div className={classes.subtitle}>
                    {productList && productList[slideIndex].shortDesc}
                  </div>
                  <Grid>
                    <div className={classes.viewBtn}>
                      <a
                        href={
                          "/products#" +
                          slugify(
                            productList && productList[slideIndex].title
                              ? productList && productList[slideIndex].title
                              : ""
                          )
                        }
                        className={classes.viewMoreLink}
                      >
                        <span className={classes.text16}>VIEW MODELS</span>
                      </a>
                    </div>
                  </Grid>
                </div>
                <Grid
                  md={12}
                  xs={12}
                  sm={12}
                  lg={12}
                  item
                  container
                  className={classes.arrows}
                >
                  <IconButton
                    disabled={slideIndex === 0 ? true : false}
                    className={classes.arrowbutton}
                    onClick={previous}
                  >
                    <ArrowForwardIosIcon
                      className={classes.arrowIcon}
                      style={{
                        background:
                          slideIndex === 0
                            ? "rgba(255, 255, 255, 0.5)"
                            : "#FFFFFF",
                        transform: "rotate(180deg)",
                      }}
                    />
                  </IconButton>

                  <IconButton
                    className={classes.arrowbutton}
                    disabled={slideIndex === length ? true : false}
                    onClick={next}
                  >
                    <ArrowForwardIosIcon
                      className={classes.arrowIcon}
                      style={{
                        background:
                          slideIndex === length
                            ? "rgba(255, 255, 255, 0.5)"
                            : "#FFFFFF",
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid md={1} item />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ getProductCat }) => {
  return { _product: getProductCat };
};

export default connect(mapStateToProps, { getProductCat })(Products);
