import Eric from "_Images/management/Eric-van-Schagen.jpeg";
import Gerlas from "_Images/management/Gerlas-van-dan-Hoven.jpeg";
import Gijs from "_Images/management/Gijs-de-Rooij.jpeg";
import Paritosh from "_Images/management/Paritosh-Prajapati.jpeg";
import Sanjay from "_Images/management/Sanjay-Kaul.jpeg";

export const managementData = [
  {
    pic: Sanjay,
    title: "Sanjay Kaul",
    address: "Board Advisor - GX Group",
    contact: "President CISCO - APAC & Japan",
  },
  {
    pic: Paritosh,
    title: "Paritosh Prajapati",
    address: "CEO - GX Group",
    contact: null,
  },
  {
    pic: Gijs,
    title: "Gijs de Rooij",
    address: "CFO - GX Group",
    contact: null,
  },
  {
    pic: Gerlas,
    title: "Gerlas vd Hoven",
    address: "Shareholder - GX Group",
    contact: "CEO - Genexis BV",
  },

  {
    pic: Eric,
    title: "Eric van Schagen",
    address: "Shareholder - GX Group",
    contact: "CEO - SIMAC Techniek NV",
  },
];
