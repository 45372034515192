import { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Header from "_Shared/Header";
import HeaderMobile from "_Shared/HeaderMobile";
import Footer from "_Shared/Footer";
import Home from "_Pages/home";
import About from "_Pages/about";
import BandWidth from "_Pages/bandwidth";
import Careers from "_Pages/careers";
import Contact from "_Pages/contact";
import Downloads from "_Pages/downloads";
import FullNews from "_Pages/news";
import NewsDetail from "_Pages/news/components/NewsDetail";
import Products from "_Pages/products/";
import ProductDetail from "_Pages/product-detail";
import RewardsProgram from "_Pages/reward-program";
import Software from "_Pages/software";
import Solutions from "_Pages/solutions";
import { usedownLaptop } from "_Utils/Helpers";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const IndexRoutes = () => {
  const isPhablet = usedownLaptop();

  return (
    <BrowserRouter>
      <ScrollToTop />
      {isPhablet ? <HeaderMobile /> : <Header />}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact element={<BandWidth />} path="/bandwidth" />
        <Route exact element={<Careers />} path="/careers" />
        <Route exact element={<Contact />} path="/contact" />
        <Route exact element={<Downloads />} path="/downloads" />
        <Route exact element={<FullNews />} path="/news" />
        <Route element={<NewsDetail />} path="/news/:id" />
        <Route exact element={<Products />} path="/products" />
        <Route exact element={<ProductDetail />} path="/product-detail" />
        <Route exact element={<RewardsProgram />} path="/reward-program" />
        <Route exact element={<Software />} path="/software" />
        <Route exact element={<Solutions />} path="/solutions" />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default IndexRoutes;
