import * as types from "../action.type";
// get all opening
export const getdownloadAction = (data) => {
  return {
    type: types.DOWNLOAD,
    data,
  };
};
export const getDownloadSuccess = (data) => {
  return {
    type: types.DOWNLOAD_SUCCESS,
    data,
  };
};

export const getDownloadError = (data) => {
  return {
    type: types.DOWNLOAD_ERROR,
    data,
  };
};
