import Button from "@material-ui/core/Button";
import { usePhoneLandScape } from "_Utils/Helpers";

export const OrangeBtn = ({
  text = "",
  width = "100%",
  height = "100%",
  padding = 0,
  btnType,
  btnHref,
  onBtnClick,
  weight,
}) => {
  const isPhone = usePhoneLandScape();

  return (
    <Button
      style={{
        background: "linear-gradient(90deg, #FD6A02 10%, #FF5500 100%)",
        borderRadius: 32,
        fontSize: isPhone ? 14 : 16,
        fontFamily: "MavenPro-Regular",
        width: width,
        height: height,
        padding: padding,
        color: "#FFF3EB",
        fontWeight: weight ? weight : "normal",
      }}
      type={btnType}
      href={btnType || onBtnClick ? null : btnHref}
      onClick={btnType ? null : () => onBtnClick()}
    >
      {text}
    </Button>
  );
};

export const BlackBtn = ({
  text = "",
  width = "100%",
  height = "100%",
  padding = 0,
  outlined = false,
  btnHref,
  onBtnClick,
}) => {
  const isPhone = usePhoneLandScape();
  return (
    <Button
      style={{
        background: outlined ? "transparent" : "#030201",
        border: "2px solid #030201",
        borderRadius: 32,
        fontSize: isPhone ? 14 : 16,
        fontFamily: "MavenPro-Regular",
        fontWeight: 600,
        width: width,
        height: height,
        padding: padding,
      }}
      href={btnHref ?? null}
      onClick={btnHref ? null : () => onBtnClick()}
    >
      <span className={outlined ? "secondary" : "white"}>{text}</span>
    </Button>
  );
};

export const WhiteBtn = ({
  text = "",
  width = "100%",
  height = "100%",
  padding = 0,
  btnHref = "/",
}) => {
  const isPhone = usePhoneLandScape();

  return (
    <Button
      style={{
        background: "#FFFFFF",
        borderRadius: 32,
        fontSize: isPhone ? 14 : 16,
        fontFamily: "MavenPro-Regular",
        fontWeight: 600,
        width: width,
        height: height,
        padding: padding,
        color: "#030201",
      }}
      href={btnHref}
    >
      {text}
    </Button>
  );
};
