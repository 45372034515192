import api from '_Utils/Api';

export const getAllVacancy = (req) =>
    api
        .get('/career/getJobVacancy', {
            withCredentials: true,
            data: req.data,
        })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return err.response;
        });

export const JobApplyService = (req) => {
    let JobData = req.data;
    let data = new FormData();
    data.append('name', JobData.name);
    data.append('email', JobData.email);
    data.append('phone', JobData.phone);
    data.append('code', JobData.code);
    data.append('Experience', JobData.Experience);
    data.append('highestQualification', JobData.highestQualification);
    data.append('resumeUrl', JobData.resumeUrl);
    data.append('jobRole', JobData.jobProfile);
    data.append('others', JobData.others);
    data.append('User_choice', JobData.User_Choice);
    data.append('linkedInProfile', JobData.linkedInProfile);

    return api
        .post('/career/apply', data)
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return err.response;
        });
};
