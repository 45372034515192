import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { WhiteBtn } from "_Shared/Buttons";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { usePhoneLandScape, usePhablet } from "_Utils/Helpers";

const SubFooter = ({
  heading,
  subHeading,
  homepage,
  btnText,
  extended = false,
  extendedLong = false,
  longText = true,
  btnHref = "/",
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      background: "#2C2E30",
      marginTop: extendedLong ? -100 : extended ? 175 : 0,
      paddingTop: extendedLong ? 100 : extended ? 200 : 0,
      [theme.breakpoints.down("sm")]: {
        marginTop: extended ? 208 : 0,
        paddingTop: extendedLong ? 100 : extended ? 200 : 0,
      },
      [theme.breakpoints.up("md")]: {
        marginTop: extended ? 150 : 0,
        paddingTop: extendedLong ? 300 : extended ? 100 : 0,
      },

      [theme.breakpoints.up("lg")]: {
        paddingTop: extendedLong ? 450 : extended ? 100 : 0,
      },
      [theme.breakpoints.up("xl")]: {
        paddingTop: extendedLong ? 475 : extended ? 175 : 0,
      },
    },
    content: {
      marginTop: extendedLong ? 50 : 100,
      marginBottom: 50,
      padding: "25px 30px 25px 30px",
      [theme.breakpoints.up("sm")]: {
        marginTop: 30,
        marginBottom: 30,
      },
      [theme.breakpoints.up("md")]: {
        marginTop: extended ? 80 : 50,
        marginBottom: 50,
      },
      [theme.breakpoints.up("lg")]: {
        paddingBottom: 50,
        marginBottom: 50,
      },
    },
    footerText: {
      [theme.breakpoints.between("md", "lg")]: {
        height: 200,
      },
    },
    whiteBtn: {
      marginTop: 40,
      [theme.breakpoints.up("lg")]: { marginTop: 0 },
    },
    btnDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("xl")]: { justifyContent: "flex-end" },
    },
    headerText: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      fontSize: longText ? 24 : 32,
      fontFamily: "MavenPro-Bold",
      lineHeight: "150%",
      [theme.breakpoints.up("sm")]: {
        textAlign: "start",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: longText ? 30 : 56,
      },
      [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-start",
        fontSize: longText ? 40 : 56,
      },
    },
    subHeader: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      fontSize: 16,
      lineHeight: "150%",
      padding: "0px 20px",
      [theme.breakpoints.up("sm")]: {
        textAlign: "start",
        padding: 0,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 20,
      },
      [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-start",
      },
    },
    attrText: {
      padding: "0px 20px",
      textAlign: "center",
      fontSize: 14,
      lineHeight: "150%",
      color: "#FFFFFF",
      marginBottom: 50,
      marginTop: extendedLong ? 0 : 157,
      [theme.breakpoints.up("sm")]: {
        textAlign: "center",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
        marginTop: 32,
      },
    },
  }));

  const classes = useStyles();
  const isPhone = usePhoneLandScape();
  const isTablet = usePhablet();
  return (
    <Grid container className={classes.root}>
      {extendedLong && (
        <Grid item xs={12} container>
          <AnimatedDiv
            children={
              <div className={classes.attrText}>
                <div>
                  Icons made by{" "}
                  <b>
                    <a
                      href="https://www.freepik.com"
                      className="link white"
                      title="Freepik"
                      style={{ color: "#fff" }}
                    >
                      Freepik,{" "}
                    </a>
                  </b>{" "}
                  <b>
                    <a
                      href="https://www.flaticon.com/authors/nhor-phai"
                      title="Nhor Phai"
                      className="link white"
                      style={{ color: "#fff" }}
                    >
                      Nhor Phai,{" "}
                    </a>
                  </b>{" "}
                  <b>
                    <a
                      href="https://www.flaticon.com/authors/pixel-perfect"
                      title="Pixel perfect"
                      className="link white"
                      style={{ color: "#fff" }}
                    >
                      Pixel perfect
                    </a>
                  </b>{" "}
                  from{" "}
                  <b>
                    <a
                      href="https://www.flaticon.com/"
                      title="Flaticon"
                      className="link white"
                      style={{ color: "#fff" }}
                    >
                      www.flaticon.com
                    </a>
                  </b>
                </div>
              </div>
            }
            // animation="fade-up"
          />
        </Grid>
      )}
      <Grid
        container
        item
        xs={12}
        md={12}
        lg={12}
        sm={12}
        className={classes.content}
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          lg={12}
          className={classes.footerText}
          justifyContent="center"
          alignItems="center"
        >
          <Grid lg={2} item />
          <Grid
            lg={8}
            xs={12}
            sm={12}
            md={12}
            item
            container
            justifyContent="space-between"
            style={{ display: "flex" }}
          >
            <Grid
              item
              lg={6}
              sm={8}
              md={8}
              xs={12}
              container
              justifyContent={isTablet && isPhone ? "center" : "flex-start"}
            >
              <Grid
                item
                md={12}
                container
                justifyContent={isTablet && isPhone ? "center" : "flex-start"}
              >
                <div className={`${classes.headerText} primary`}>{heading}</div>
              </Grid>

              <Grid
                item
                md={12}
                container
                justifyContent={isTablet && isPhone ? "center" : "flex-start"}
              >
                <div
                  className={`${classes.subHeader} lightGrey`}
                  style={{ marginTop: isPhone ? 4 : 16 }}
                >
                  {subHeading}
                </div>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={2}
              container
              className={classes.whiteBtn}
            >
              <div className={`${classes.btnDiv} stretch`}>
                <AnimatedDiv
                  children={
                    <Grid
                      container={isTablet ? true : false}
                      justifyContent={isTablet ? "center" : ""}
                    >
                      <WhiteBtn
                        text={btnText}
                        width={isPhone ? 169 : 205}
                        height={isPhone ? 40 : 56}
                        btnHref={btnHref}
                      />
                    </Grid>
                  }
                  animation={isPhone ? "fade-up" : "fade-left"}
                />
              </div>
            </Grid>
          </Grid>
          <Grid lg={2} item />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubFooter;
