import api from '_Utils/Api';

export const getNews = (req) =>
    api
        .get('/NewsEvents/getNews', {
            withCredentials: true,
            data: req.data,
        })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return err.response;
        });
