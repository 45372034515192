import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FD6A02",
    },
    secondary: {
      light: "#FFF3EB",
      main: "#000000",
    },
    initial: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: "MavenPro-Regular",
  },
  breakpoints: {
    values: {
      xs: 321,
      sm: 550,
      md: 768,
      lg: 1024,
      xl: 1200,
    },
  },
});
export default theme;
