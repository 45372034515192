import { useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PeopleImg from "_Images/about/people.png";

const useStyles = makeStyles((theme) => ({
  imgDiv: {
    position: "absolute",
    zIndex: 1,
    width: "100%",
    height: "90vh",
    background: `url(${PeopleImg})`,
    backgroundSize: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    bottom: 0,
    top: 80,
    transform: "1s ease",
    [theme.breakpoints.down("sm")]: {
      height: "40vh",
    },
    [theme.breakpoints.down("md")]: {
      top: 0,
    },
    [theme.breakpoints.down("xs")]: {
      height: "20vh",
    },
  },
}));

const ShrinkingPoster = () => {
  const classes = useStyles();

  useEffect(() => {
    const adjustImg = () => {
      const el = document.getElementById("largeImg");
      let offset = window.pageYOffset - 700;
      let diff = (offset - el.offsetHeight) / 40;

      let res = diff < 0 ? 0 : diff < 20 ? diff : offset - el.offsetHeight;
      el.style.backgroundSize = 100 - res + "%";
    };
    window.addEventListener("scroll", adjustImg, true);
    return () => window.removeEventListener("scroll", adjustImg, true);
  });

  return <div id="largeImg" className={classes.imgDiv}></div>;
};

export default ShrinkingPoster;
