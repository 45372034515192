import * as types from '../action.type';

//get
export const getProductCat = (data) => {
    return {
        type: types.GET_PRODUCT_CATEGORY,
        data,
    };
};
export const getProductCatSuccess = (data) => {
    return {
        type: types.GET_PRODUCT_CATEGORY_SUCCESS,
        data,
    };
};

export const getProductCatError = (data) => {
    return {
        type: types.GET_PRODUCT_CATEGORY_ERROR,
        data,
    };
};
