import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import { OrangeBtn } from "_Shared/Buttons";
import { RHFTextField } from "_Shared/FormComps";
import { usePhoneLandScape } from "_Utils/Helpers";

const BandwidthForm = ({ formSubmit }) => {
  const isPhone = usePhoneLandScape();
  const { register, handleSubmit, errors } = useForm();

  const submitForm = (data) => {
    console.log(data);

    formSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Grid
        container
        md={12}
        xs={12}
        sm={12}
        lg={12}
        item
        justifyContent="center"
      >
        <Grid item xs={10}>
          <RHFTextField
            name="num_subs"
            label="Number of Subscribers"
            inputRef={register({
              required: {
                value: true,
                message: "Please fill this field",
              },
              min: {
                value: 250,
                message: "Please enter atleast 250 customers",
              },
            })}
            error={errors?.num_subs ? true : false}
            helperText={errors?.num_subs?.message}
          />
        </Grid>
        <Grid item xs={10}>
          <RHFTextField
            name="avg_speed"
            label="Avg. Internet speed per user"
            inputRef={register({
              required: {
                value: true,
                message: "Please fill this field",
              },
              minLength: {
                value: 1,
                message: "Please enter atleast 1 character",
              },
            })}
            error={errors?.avg_speed ? true : false}
            helperText={errors?.avg_speed?.message}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{ marginTop: 32 }}
        >
          <OrangeBtn
            text="CALCULATE BANDWIDTH"
            btnType="submit"
            width={isPhone ? 196 : 224}
            height={isPhone ? 32 : 40}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default BandwidthForm;
