import AboutBanner from "./components/AboutBanner";
import AboutCeo from "./components/AboutCeo";
import ShrinkingPoster from "./components/ShrinkingPoster";
import Certificates from "./components/Certificates";
import GlassBgCard from "_Shared/GlassBgCard";
import { Grid } from "@material-ui/core";
const About = () => {
  const GlassDiv = (
    <GlassBgCard
      content={
        <>
          <b>GX Group</b> is a European market leading Fiber-to-the-Home (FTTH)
          company with 20 years experience, focused on Manufacturing and
          Development of FTTH products. Quality and innovation is in our focus
          where we invest major part in our R&amp;D team developing next
          generation PON (GPON/XGPON/XGSPON) solutions.
        </>
      }
      padding_xs="72px 24px 40px 24px"
      padding_md="190px 56px 56px 56px"
    />
  );

  return (
    <div
      style={{
        background: "linear-gradient(104.04deg, #FFFFFF 0%, #FFF3EB 100%)",
      }}
    >
      <AboutBanner />
      <AboutCeo />
      <Grid
        style={{
          position: "relative",
        }}
      >
        <ShrinkingPoster />
      </Grid>

      <Certificates />
    </div>
  );
};

export default About;
