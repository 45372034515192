import Grid from "@material-ui/core/Grid";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { FooterConstants } from "_Constants/Footer";
import { GenerateIcon } from "_Utils/Helpers";
import FacebookIcon from "_Icons/facebook.svg";
import LinkedInIcon from "_Icons/linkedin.svg";
import LogoBlack from "_Images/logo-black.png";
import { getdownloadAction } from "../store/action/download";
import { getProductCat } from "_Store/action/productcategory";
import { slugify } from "_Utils/Helpers";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 25,
    [theme.breakpoints.up("sm")]: { padding: 48 },
  },

  menuList: {
    textDecoration: "none",
    color: "#0a1219",
    outline: 0,
    textTransform: "none",
  },
  logoImage: {
    height: 63,
    width: 145,
    marginBottom: 16,
  },
  mailDiv: {
    marginBottom: 12,
  },
  iconDiv: {
    paddingBottom: 40,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.up("lg")]: {
      paddingBottom: 24,
    },
  },
  socialIcon: {
    width: 20,
    height: 20,
    marginRight: 15,
  },
  listItem: { marginTop: 16 },
  crText: {
    [theme.breakpoints.down(1023)]: {
      display: "none",
    },
  },
  btmText: {
    marginBottom: 20,
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  firstCol: {
    [theme.breakpoints.up("lg")]: {
      alignContent: "flex-end",
    },
  },
  firstDiv: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 12,
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  listDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 48,
    [theme.breakpoints.up("sm")]: {
      alignItems: "flex-start",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 20,
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: 0,
    },
  },
  alignText: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const downloadFiles = useSelector((state) => state.getDownloadReducer);
  const allProduct_Cat = useSelector((state) => state.getProductCat);
  let productCategory = allProduct_Cat.allProductCat;

  useEffect(() => {
    dispatch(getdownloadAction());
    dispatch(getProductCat());
  }, []);

  let csrLink = downloadFiles
    ? downloadFiles &&
      downloadFiles.downloadFiles &&
      downloadFiles.downloadFiles.filter(
        (item) => item.type === "corporateReference"
      )
    : null;

  return (
    <Grid container justifyContent="space-between" className={classes.root}>
      <Grid
        container
        item
        xs={12}
        sm={6}
        md={4}
        lg={5}
        xl={4}
        className={classes.firstCol}
      >
        <Grid item xs={12} className={classes.firstDiv}>
          <img src={LogoBlack} alt="logo" className={classes.logoImage} />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} md={7} lg={5} xl={4} className={classes.firstDiv}>
            <a href="mailto:info@gxgroup.eu" className="link">
              <span className="text14 darkGrey mp-semi">info@gxgroup.eu</span>
            </a>
          </Grid>
          <Grid item xs={12} md={5} lg={6} className={classes.iconDiv}>
            <a href="https://www.facebook.com/gxgroup.eu">
              <GenerateIcon
                iconPath={FacebookIcon}
                alt="facebook"
                className={classes.socialIcon}
              />
            </a>
            <a href="https://www.linkedin.com/company/gxgroup">
              <GenerateIcon
                iconPath={LinkedInIcon}
                alt="linkedin"
                className={classes.socialIcon}
              />
            </a>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <div className={`${classes.crText} text12 secondary mp-semi`}>
            Copyright © 2021 GX Group | Powered by GX Group
          </div>
        </Grid>
      </Grid>
      {FooterConstants.map((fc, index) => (
        <Grid
          container
          item
          xs={12}
          sm={fc.sm}
          md={fc.md}
          lg={fc.lg}
          key={index}
        >
          <div className={classes.listDiv}>
            <div className="text16 secondary mp-bold">
              <b>{fc.heading}</b>
            </div>
            {fc.heading === "PRODUCTS" || fc.heading === "QUICK LINKS" ? (
              ""
            ) : (
              <span className={classes.alignText}>
                {csrLink?.map((obj, i) => (
                  <a className="link" href={obj.docUrl} target="_blank" key={i}>
                    <div
                      className={`${classes.listItem} text12 secondary mp-semi`}
                    >
                      {obj.title.toUpperCase()}
                    </div>
                  </a>
                ))}
              </span>
            )}
            {fc?.links?.map((link, index2) => (
              <a className="link" href={link.to ? link.to : ""} key={index2}>
                <div className={`${classes.listItem} text12 secondary mp-semi`}>
                  {link.name}
                </div>
              </a>
            ))}
            {fc.heading === "CORPORATE REFERENCE"
              ? ""
              : !fc?.links
              ? productCategory?.map((obj, index2) => (
                  <a
                    className="link"
                    href={"/products#" + slugify(obj?.title ?? "")}
                    key={index2}
                  >
                    <div
                      className={`${classes.listItem} text12 secondary mp-semi`}
                    >
                      {obj.title}
                    </div>
                  </a>
                ))
              : ""}
          </div>
        </Grid>
      ))}
      <Grid item xs={12}>
        <div
          className={`${classes.btmText} text12 secondary centerDiv stretch`}
        >
          Copyright © 2021 GX Group | Powered by GX Group
        </div>
      </Grid>
    </Grid>
  );
};

export default Footer;
