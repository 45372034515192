import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import ImageSlides from "./ImageSlides";
import Banner from "_Shared/Banner";
import SubFooter from "_Shared/SubFooter";
import { BlackBtn } from "_Shared/Buttons";
import GlassBgCard from "_Shared/GlassBgCard";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { usePhoneLandScape, usePhablet } from "_Utils/Helpers";
import { get_all_product_Action } from "_Store/action/productAction";
import { Divider } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  parent: {
    [theme.breakpoints.down("md")]: {
      // padding: 32,
    },
  },
  prodDiv: {
    display: "flex",
    marginTop: 150,
    [theme.breakpoints.down("md")]: {
      marginTop: 60,
      // padding: 32,
    },
  },
  featuresGrid: {
    marginTop: 60,
  },
  threesixty_style: {
    height: "100%",
    width: "100%",
  },
  prodDesc: {
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      paddingTop: 32,
    },
  },
  prodSpecs: {
    padding: "130px 0px",
    background: "#fff",
    [theme.breakpoints.down("md")]: {
      padding: "60px 32px",
    },
  },
  accordian: {
    // marginBottom: 16,
    border: 0,
    borderBottom: "1 px solid #61686C",
    boxShadow: "none",
  },
  accDetail: {
    padding: "0px 16px 16px 0px",
  },
  media: {
    float: "left",
    height: "500px",
    width: "100%",
    position: "sticky",
    top: 100,
    [theme.breakpoints.between("md", "lg")]: {
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      height: 350,
    },
  },

  sticky: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      objectFit: "contain",
    },
  },
}));

const ProductInfo = () => {
  const classes = useStyle();
  const navigate = useNavigate();
  const isPhablet = usePhablet();
  const isPhone = usePhoneLandScape();
  const dispatch = useDispatch();

  const AllProducts = useSelector((state) => state.get_ALL_ProductReducer);
  const data = AllProducts && AllProducts.allProduct;
  const [params, setparams] = useState();

  const btnText = (
    <>
      <PhoneAndroidIcon />
      &nbsp;&nbsp;
      <div>CONTACT US</div>
    </>
  );
  useEffect(() => {
    dispatch(get_all_product_Action());
    const urlParams = new URLSearchParams(window.location.search).get("id");
    setparams(urlParams);
  }, []);
  let categoryParams = new URLSearchParams(window.location.search).get(
    "category"
  );

  const ExpansionPanel = ({ obj }) => {
    const [expanded, setExpanded] = useState(obj.expanded);
    return (
      <Accordion
        className={classes.accordian}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{ padding: 0, margin: "8px 0px", height: 0 }}
        >
          <div
            className={
              isPhone ? "text14 secondary mp-bold" : "text20 secondary mp-bold"
            }
          >
            <b>{obj.title}</b>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accDetail}>
          <div
            dangerouslySetInnerHTML={{
              __html: obj.description,
            }}
          ></div>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Grid container className={classes.parent} justifyContent="center">
      {data &&
        data
          .filter((item) => item._id === params)
          .map((obj, index) => {
            const specArr = obj.specifications.map((spec) => ({
              ...spec,
              expanded: false,
            }));

            return (
              <Grid
                md={12}
                xs={12}
                sm={12}
                lg={12}
                container
                key={index}
                item
                className={classes.prodDiv}
              >
                <Grid lg={1} item />
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={10}
                  item
                  container
                  style={{ padding: isPhablet ? 32 : 0 }}
                >
                  <Grid item xs={12} md={12} lg={4}>
                    <AnimatedDiv
                      children={
                        <ImageSlides imgArr={obj.imgUrl} fwd_rev={false} />
                      }
                      animate={isPhone ? "fade-up" : "fade-right"}
                    />
                  </Grid>
                  <Grid lg={1} item />
                  <Grid
                    container
                    item
                    xs={12}
                    md={7}
                    lg={6}
                    className={classes.prodDesc}
                  >
                    <AnimatedDiv
                      children={
                        <>
                          <div className="flexColumn">
                            <div
                              className={
                                isPhone
                                  ? "text14 secondary mp-bold"
                                  : "text20 secondary mp-bold"
                              }
                            >
                              {categoryParams}
                            </div>
                            <div className={isPhablet ? "text32" : "text56"}>
                              {obj.title}
                            </div>
                            <div
                              className={
                                isPhone
                                  ? "text12 secondary  mp-semi"
                                  : "text20 secondary  mp-semi"
                              }
                              style={{
                                marginBottom: isPhablet ? 16 : 24,
                                marginTop: isPhablet ? 16 : 0,
                              }}
                            >
                              {obj.shortDesc}
                            </div>
                          </div>
                          <div className="text16 darkGrey">{obj.longDesc}</div>
                          <div
                            style={{
                              marginTop: 32,
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                marginRight: isPhone ? 25 : 40,
                              }}
                            >
                              <BlackBtn
                                text="View Specs"
                                width={isPhone ? 113 : 163}
                                height={isPhone ? 33 : 40}
                                outlined={false}
                                btnHref="#specs"
                              />
                            </div>
                            <div>
                              <BlackBtn
                                text="ENQUIRE NOW"
                                width={isPhone ? 113 : 141}
                                height={isPhone ? 33 : 40}
                                outlined={true}
                                onBtnClick={() =>
                                  navigate(
                                    `/contact?id=${obj._id}&product=${obj.title}#form`,
                                    {
                                      state: JSON.stringify({
                                        category: obj.productCategory,
                                        _id: obj._id,
                                      }),
                                    }
                                  )
                                }
                              />
                            </div>
                          </div>
                        </>
                      }
                      animate={isPhone ? "fade-up" : "fade-left"}
                    />
                  </Grid>
                  <Grid lg={1} item />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  className={classes.featuresGrid}
                >
                  <Banner
                    image={obj.backgroundImage}
                    productDetail={false}
                    productDetailfeature
                    leftDiv={
                      <GlassBgCard
                        content={
                          <Grid>
                            <div
                              className={
                                isPhone ? "text16 primary" : "text26 primary"
                              }
                              style={{
                                marginLeft: 20,
                                fontWeight: 700,
                              }}
                            >
                              Product Features
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: obj.features,
                              }}
                              style={{
                                marginTop: 16,
                                lineHeight: "normal",
                                fontSize: 20,
                                color: "#2C2E30",
                              }}
                            ></div>
                          </Grid>
                        }
                        padding_xs="72px 24px 40px 24px"
                        padding_sm="45px 24px 30px 24px"
                        padding_md="35px 40px 15px 40px"
                        padding_lg="50px 48px 50px 48px"
                      />
                    }
                  />
                </Grid>
                <Grid
                  container
                  item
                  md={12}
                  xs={12}
                  sm={12}
                  lg={12}
                  className={classes.prodSpecs}
                >
                  <Grid lg={1} item />
                  <Grid container item md={12} xs={12} sm={12} lg={9}>
                    <Grid
                      container
                      item
                      xs={12}
                      md={12}
                      lg={5}
                      justifyContent="center"
                    >
                      <Grid item xs={12} md={12} lg={12}>
                        <Grid
                          lg={12}
                          md={12}
                          xs={12}
                          sm={12}
                          item
                          className={classes.media}
                        >
                          <img
                            className={classes.sticky}
                            src={obj?.additionalImages?.[0]}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid lg={1} item />
                    <Grid
                      item
                      xs={12}
                      md={10}
                      lg={5}
                      id="specs"
                      className={classes.prodDesc}
                    >
                      <AnimatedDiv
                        children={
                          <>
                            <div
                              className={
                                isPhone
                                  ? "text12 secondary mp-bold"
                                  : "text16 secondary mp-bold"
                              }
                            >
                              <b>{categoryParams}</b>
                            </div>
                            <div
                              className={
                                isPhablet
                                  ? "text24 primary mp-bold"
                                  : "text40 primary mp-bold"
                              }
                            >
                              {obj.title}
                            </div>
                            <div
                              className={
                                isPhone
                                  ? "text12 secondary mp-semi"
                                  : "text16 secondary mp-semi"
                              }
                              style={{
                                marginTop: 8,
                                marginBottom: isPhone ? 24 : 48,
                              }}
                            >
                              Product Features &amp; Specifications
                            </div>
                            <Grid
                              container
                              xs={12}
                              sm={12}
                              md={12}
                              item
                              // style={{ lineHeight: 2 }}
                            >
                              {specArr.map((spec, index) => (
                                <Grid item xs={12} key={index}>
                                  <ExpansionPanel obj={spec} />
                                  <Divider
                                    style={{
                                      width: "100%",
                                      background: "rgba(97, 104, 108, 0.3)",
                                      height: 1,
                                    }}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                            <div
                              className={isPhone ? "text14" : "text16"}
                              style={{
                                marginTop: 32,
                              }}
                            >
                              <a
                                className="link primary mp-semi"
                                href={obj.dataSheet}
                                download
                              >
                                DOWNLOAD DATASHEET
                              </a>
                            </div>
                          </>
                        }
                        animation={isPhone ? "fade-up" : "fade-left"}
                      />
                    </Grid>
                  </Grid>
                  <Grid lg={2} item />
                </Grid>
                <Grid item xs={12}>
                  <SubFooter
                    heading="Enquire Now"
                    subHeading="Build your network infrastructure with us"
                    btnText={btnText}
                    btnHref={`/contact?id=${obj._id}&product=${obj.title}#form`}
                  />
                </Grid>
              </Grid>
            );
          })}
    </Grid>
  );
};

export default ProductInfo;
