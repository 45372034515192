import { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RouterIcon from "@material-ui/icons/Router";
import { OrangeBtn } from "_Shared/Buttons";
import { usePhoneLandScape, usePhablet } from "_Utils/Helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px 10px",
    [theme.breakpoints.up("sm")]: { padding: "0px 20px" },
    [theme.breakpoints.up("md")]: { padding: "0px 30px" },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "left",
  },
  img: {
    width: "100%",
    height: "100%",
    marginBottom: 24,
    objectFit: "cover",
  },
  subtitle: {
    fontFamily: theme.typography.fontSize.subtitle,
    textAlign: "left",
  },
  imageGrid: {
    height: 400,
  },
  card: {
    padding: 24,
    boxShadow: "0px 1px 16px rgba(0, 0, 0, 0.15)",
    borderRadius: 1,
    [theme.breakpoints.up("lg")]: {
      padding: 32,
    },
  },
  btnDiv: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
}));

const ProductCard = (props) => {
  const classes = useStyles();
  const isPhablet = usePhablet();
  const isPhone = usePhoneLandScape();

  const handleScrollDiv = () => {
    const ele = document.getElementById("bwDiv");
    if (window.pageYOffset > 1040 && window.pageYOffset < 1840) {
      ele.classList.add("zoom-max");
    } else {
      ele.classList.remove("zoom-max");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollDiv);
    return () => window.removeEventListener("scroll", handleScrollDiv);
  }, []);

  const BtnText = () => (
    <>
      <RouterIcon />
      <span style={{ marginLeft: 5 }}>Know Your Bandwidth</span>
    </>
  );

  return (
    <Grid container justifyContent="center" className={classes.root} id="bwDiv">
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent style={{ padding: isPhablet ? 0 : 16 }}>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={6}>
                <div
                  className="centerDiv stretch"
                  style={{
                    height: 300,
                    // top: isPhablet ? 100 : 0,
                    position: "relative",
                  }}
                >
                  {isPhablet ? (
                    <img
                      src={props.image}
                      className={classes.img}
                      alt="image"
                    />
                  ) : null}
                </div>
              </Grid>
              <Grid container item xs={12} sm={6} alignItems="center">
                <div className="flexColumn">
                  <div
                    className={
                      isPhone
                        ? "text24 secondary mp-bold"
                        : "text40 secondary mp-bold"
                    }
                  >
                    <b>GPON - FTTH Solution</b>
                  </div>
                  <div
                    className={
                      isPhone ? "text16 darkGrey w400" : "text20 darkGrey w400"
                    }
                  >
                    We curate the best infrastructure for you
                  </div>
                  <div
                    className={classes.btnDiv}
                    style={{
                      marginTop: isPhablet ? 32 : 48,
                    }}
                  >
                    <OrangeBtn
                      text={<BtnText />}
                      width={isPhone ? 239 : 278}
                      height={isPhone ? 40 : 56}
                      padding={isPhone ? "10px 10px" : "16px 24px"}
                      btnHref="/bandwidth"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProductCard;
