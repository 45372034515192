import makeStyles from '@material-ui/core/styles/makeStyles';
import AnimatedDiv from '_Shared/AnimatedDiv';
import { usePhoneLandScape } from '_Utils/Helpers';

const useStyles = makeStyles((theme) => ({
    tncDiv: {
        background: '#2C2E30',
        marginTop: 300,
        padding: '350px 30px 50px 30px',
        [theme.breakpoints.up('xs')]: {
            marginTop: 300,
            padding: '300px 30px 50px 30px',
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 125,
            padding: '150px 100px 50px 100px',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 150,
            padding: '200px 100px 100px 100px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '160px 225px 100px 225px',
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 130,
            padding: '175px 300px 125px 300px',
        },
    },
    attrText: {
        fontSize: 14,
        lineHeight: '150%',
        color: '#FFFFFF',
        marginBottom: 72,
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
        },
    },
    listItem: {
        marginBottom: 12,
    },
}));

const TnC = () => {
    const classes = useStyles();
    const isPhone = usePhoneLandScape();

    return (
        <div className={classes.tncDiv}>
            <AnimatedDiv
                children={
                    <div className={classes.attrText}>
                        <div>
                            Icons made by{' '}
                            <b>
                                <a
                                    href="https://www.flaticon.com/authors/flat-icons"
                                    className="link white"
                                    title="Flat Icons"
                                >
                                    Flat Icons,{' '}
                                </a>{' '}
                                <a
                                    href="https://www.flaticon.com/authors/smashicons"
                                    title="Smashicons"
                                    className="link white"
                                >
                                    Smashicons,{' '}
                                </a>{' '}
                                <a
                                    href="https://www.freepik.com"
                                    title="Freepik"
                                    className="link white"
                                >
                                    Freepik
                                </a>
                            </b>{' '}
                            from{' '}
                            <b>
                                <a
                                    href="https://www.flaticon.com/"
                                    title="Flaticon"
                                    className="link white"
                                >
                                    www.flaticon.com
                                </a>
                            </b>
                        </div>
                    </div>
                }
                animation="fade-up"
            />

            <AnimatedDiv
                children={
                    <div className="flexColumn">
                        <div
                            className={
                                isPhone
                                    ? 'text16 white mp-bold'
                                    : 'text20 white mp-bold'
                            }
                            style={{ marginBottom: 16 }}
                        >
                            <b>Terms &amp; Conditions</b>
                        </div>

                        <div
                            className={
                                isPhone
                                    ? `text12 lightGrey ${classes.listItem}`
                                    : `text16 lightGrey ${classes.listItem}`
                            }
                        >
                            1. Bug reporter should be confidential in sharing
                            details and follow privacy for crucial info.
                        </div>
                        <div
                            className={
                                isPhone
                                    ? `text12 lightGrey ${classes.listItem}`
                                    : `text16 lightGrey ${classes.listItem}`
                            }
                        >
                            2. The bug should not be posted on any
                            online/offline forums.
                        </div>
                        <div
                            className={
                                isPhone
                                    ? `text12 lightGrey ${classes.listItem}`
                                    : `text16 lightGrey ${classes.listItem}`
                            }
                        >
                            3. Rewards will only be disbursed after validation
                            and acceptance from our team.
                        </div>
                        <div
                            className={
                                isPhone
                                    ? `text12 lightGrey ${classes.listItem}`
                                    : `text16 lightGrey ${classes.listItem}`
                            }
                        >
                            4. Severity of the bug will be investigated by our
                            team.
                        </div>
                        <div
                            className={
                                isPhone
                                    ? `text12 lightGrey ${classes.listItem}`
                                    : `text16 lightGrey ${classes.listItem}`
                            }
                        >
                            5. Not abiding the above conditions and misusing
                            information may attract legal action.
                        </div>
                    </div>
                }
                animation="fade-up"
            />
        </div>
    );
};

export default TnC;
