import { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import makeStyles from "@material-ui/core/styles/makeStyles";
// import AnimatedDiv from '_Shared/AnimatedDiv';
import { SlideShowData } from "_Constants/Goals";
import { usePhoneLandScape } from "_Utils/Helpers";
import "react-alice-carousel/lib/alice-carousel.css";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 640,
    position: "relative",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      height: "820px",
      alignItems: "center",
    },
  },
  img: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  box: {
    position: "absolute",
    right: 170,
    marginTop: 20,
    padding: 64,
    [theme.breakpoints.down("sm")]: {
      right: 0,
      padding: "64px 24px 24px 24px",
      margin: "0px 16px",
      marginTop: 120,
    },
    [theme.breakpoints.only("md")]: {
      right: 70,
    },
  },
  linkDiv: {
    marginBottom: 16,
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.up("md")]: { marginBottom: 24 },
  },
  arrowIcon: {
    fontSize: 8,
    marginLeft: 16,
    borderRadius: "50%",
    color: "#000",
    padding: 8,
    [theme.breakpoints.up("md")]: {
      fontSize: 12,
      marginLeft: 24,
    },
  },
}));

const SlideShow = () => {
  const classes = useStyles();
  const isPhone = usePhoneLandScape();
  const [index, setIndex] = useState(0);
  const [hoverVal, sethoverVal] = useState(null);

  const onSlideChanged = (e) => {
    setIndex(hoverVal ? hoverVal : e.item);
  };

  return (
    <Grid container className={classes.root}>
      <AliceCarousel
        autoPlay
        autoPlayStrategy="none"
        autoPlayInterval={2000}
        animationType="fadeout"
        infinite
        touchTracking={false}
        disableDotsControls
        disableButtonsControls
        onSlideChanged={onSlideChanged}
        slideToIndex={2}
      >
        {SlideShowData.map((obj, i) => (
          <div key={i} className={classes.root}>
            <img
              src={hoverVal ? hoverVal : obj.pic}
              alt="pic"
              className={classes.img}
            />
          </div>
        ))}
      </AliceCarousel>

      {/* <AnimatedDiv children={ */}

      <Box className={`${classes.box} glass-black`}>
        <div
          className={isPhone ? "text32" : "text56"}
          style={{ marginBottom: isPhone ? 4 : 8 }}
        >
          FTTH Solutions
        </div>
        <div
          className={isPhone ? "text16 lightGrey" : "text20 lightGrey"}
          style={{ marginBottom: isPhone ? 32 : 48 }}
        >
          Build your network infrastructure with our product lines
        </div>

        {SlideShowData.map((obj, i) => (
          <div key={i} className={classes.linkDiv}>
            <div className={isPhone ? "text20" : "text32"}>
              <a
                className="link"
                style={{
                  color:
                    index === i || hoverVal === obj.pic
                      ? "#fff"
                      : "rgba(255, 255, 255, 0.5)",
                  fontWeight: "bold",
                  marginTop: 8,
                  cursor: "pointer",
                }}
                href={obj.to}
                onMouseEnter={() => {
                  sethoverVal(obj.pic);
                }}
                onMouseLeave={() => sethoverVal(null)}
              >
                {obj.title}
              </a>
            </div>

            <div className="centerDiv">
              <ArrowForwardIosIcon
                className={classes.arrowIcon}
                style={{
                  background:
                    index === i || hoverVal === obj.pic
                      ? "#fff"
                      : "rgba(255, 255, 255, 0.5)",
                }}
              />
            </div>
          </div>
        ))}
      </Box>
    </Grid>
  );
};

export default SlideShow;
