import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Divider from "@material-ui/core/Divider";
import Banner from "_Shared/Banner";
import SubFooter from "_Shared/SubFooter";
import Dropdowns from "_Shared/Dropdowns";
import GlassBgCard from "_Shared/GlassBgCard";
import { usePhoneLandScape, usePhablet } from "_Utils/Helpers";
import BandwidthForm from "./components/BandwidthForm";
import BandWidthMob from "_Images/bandwidth/bw_mobile.png";
import BandWidthPc from "_Images/bandwidth/bandwidth.png";
import { DownloadsList } from "_Constants/Goals";
import { getdownloadAction } from "../../store/action/download";
import { getProductCat } from "_Store/action/productcategory";
import { get_all_product_Action } from "_Store/action/productAction";
const useStyles = makeStyles((theme) => ({
  form: {
    margin: "30px 0px 30px 0px",
    [theme.breakpoints.up("sm")]: {
      margin: "30px 50px 0px 50px",
    },
  },
  bwContent: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: 10,
    },
  },
  knowBw: {
    fontSize: 24,
    lineHeight: "150%",
    color: "#FD6A02",
    textAlign: "center",
    fontFamily: "MavenPro-Bold",
    [theme.breakpoints.up("sm")]: { fontSize: 32 },
    [theme.breakpoints.up("lg")]: {
      fontSize: 40,
    },
  },
  bwDesc: {
    fontSize: 14,
    lineHeight: "150%",
    color: "#FFF3EB",
    marginTop: 16,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: { padding: "0px 25px" },
    [theme.breakpoints.up("md")]: {
      padding: "0px 30px",
      fontSize: 16,
    },
    [theme.breakpoints.up("xl")]: {
      padding: "0px 10px",
    },
  },
  bwResult: {},
  divider: {
    width: 1,
    background: "#FFF3EB",
    borderRadius: 16,
  },
  ddDiv: {
    padding: "60px 24px 60px 24px",
    [theme.breakpoints.up("md")]: {
      padding: "100px 24px 100px 24px",
    },
  },
}));

const BandWidth = () => {
  const classes = useStyles();
  const isPhablet = usePhablet();
  const isPhone = usePhoneLandScape();
  const [formSubmit, setFormSubmit] = useState(false);
  const [totalBandwidth, settotalBandwidth] = useState(0);
  const [olt_8Port, setolt_8Port] = useState(0);
  const [olt_16Port, setolt_16Port] = useState(0);
  const [ONT, setONT] = useState();
  const dispatch = useDispatch();

  const downloadFiles = useSelector((state) => state.getDownloadReducer);
  const AllProducts = useSelector((state) => state.get_ALL_ProductReducer);
  const AllProductCat = useSelector((state) => state.getProductCat);

  useEffect(() => {
    dispatch(getdownloadAction());
    dispatch(get_all_product_Action());
    dispatch(getProductCat());
  }, []);

  let productCategory = [
    ...new Set(
      AllProductCat &&
        AllProductCat.allProductCat &&
        AllProductCat.allProductCat.map((item) => {
          return item.title;
        })
    ),
  ];

  const DownloadsArr = DownloadsList?.topics.map((topic) => ({
    topic: topic.title,
    expanded: false,
    query: topic.query,
  }));
  const ProductCatArr =
    productCategory &&
    productCategory.map((topic) => ({
      topic: topic,
      expanded: false,
    }));

  const handleFormSubmit = (val) => {
    let user_B2 = val.num_subs;
    let speed_B3 = val.avg_speed / 8;
    let totalBandWidth = (user_B2 * speed_B3) / 1000;
    let OLT_8_port = user_B2 / 500;
    let olt_16_port = user_B2 / 1000;
    setolt_8Port(OLT_8_port);
    settotalBandwidth(totalBandWidth);
    setolt_16Port(olt_16_port);
    setONT(user_B2);
    setFormSubmit(true);
  };
  console.log(`totalBandWidth`, totalBandwidth);
  const CardContent = (
    <Grid container>
      <Grid item xs={12} sm={formSubmit ? 5 : 12} className={classes.bwContent}>
        <div className="centerDiv stretch flexColumn">
          <div className={classes.knowBw}>Know Your Bandwidth</div>
          <div className={classes.bwDesc}>
            Are you an ISP, LCO or MSO looking for the best GPON setup?
            Calculate the required Internet bandwidth to setup your own
            networks.
          </div>
          {/* setFormSubmit(true) */}
          <div className={classes.form}>
            <BandwidthForm formSubmit={(val) => handleFormSubmit(val)} />
          </div>
        </div>
      </Grid>
      {formSubmit && (
        <>
          <Grid item sm={1} lg={1} container justifyContent="center">
            <Divider orientation="vertical" className={classes.divider} />
          </Grid>
          <Grid item xs={12} sm={4} lg={4} className={classes.bwResult}>
            <div
              className={
                isPhone ? "centerDiv stretch flexColumn" : "stretch flexColumn"
              }
            >
              <div
                className={
                  isPhone ? "text20 primary mp-semi" : "text24 primary mp-semi"
                }
                style={{ marginBottom: isPhone ? 12 : 24 }}
              >
                Your Network Requirements
              </div>
              <div
                className={isPhablet ? "text14 lightGrey" : "text16 lightGrey"}
              >
                Total Bandwidth required:
              </div>
              <div
                className={isPhablet ? "text20 white" : "text32 white"}
                style={{ marginBottom: isPhone ? 12 : 24 }}
              >
                <b>{totalBandwidth} </b>
                <span
                  className={
                    isPhablet ? "text16 lightGrey" : "text20 lightGrey"
                  }
                >
                  GBPS
                </span>
              </div>
              <div
                className={isPhablet ? "text14 lightGrey" : "text16 lightGrey"}
              >
                Number of OLT required in your network:
              </div>
              <div
                className={isPhablet ? "text20 white" : "text32 white"}
                style={{ marginBottom: isPhone ? 12 : 24 }}
              >
                <b>{olt_8Port} </b>
                <span
                  className={isPhone ? "text16 lightGrey" : "text20 lightGrey"}
                >
                  8-Port OLT
                </span>
              </div>

              <div
                className={isPhablet ? "text14 lightGrey" : "text16 lightGrey"}
              >
                Number of ONT required in your network:
              </div>
              <div
                className={isPhablet ? "text20 white" : "text32 white"}
                style={{ marginBottom: isPhone ? 12 : 24 }}
              >
                <b>{ONT} </b>
                <span
                  className={isPhone ? "text16 lightGrey" : "text20 lightGrey"}
                >
                  WiFi ONT/ROUTER
                </span>
              </div>
            </div>
          </Grid>
          <Grid lg={1} item />
        </>
      )}
    </Grid>
  );

  const GlassContent = (
    <GlassBgCard
      content={CardContent}
      padding_xs="32px 16px 40px 16px"
      padding_sm="60px 40px 40px 40px"
      padding_md="60px 40px 40px 40px"
      darkTheme={true}
    />
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Banner
          image={isPhone ? BandWidthMob : BandWidthPc}
          productPage={GlassContent}
          formStretched={formSubmit}
          isBwForm={true}
          productDetail={true}
        />
      </Grid>
      <Grid item xs={12} className={classes.ddDiv}>
        <div className="centerDiv flexColumn stretch">
          <div
            className={
              isPhone
                ? "text24 primary textCenter"
                : "text40 primary textCenter"
            }
          >
            Searching your Network Equipment ?
          </div>
          <div
            className={
              isPhone
                ? "text16 darkGrey textCenter"
                : "text20 darkGrey textCenter"
            }
            style={{ marginTop: 20 }}
          >
            Choose from our portfolio and get the best internet bandwidth setup
          </div>
          <Dropdowns
            listArr={downloadFiles}
            staticData={DownloadsArr}
            productCategory={ProductCatArr}
            AllProducts={AllProducts}
            bandwidthPage
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <SubFooter
          heading="Tailor-made solution for your Fiber network"
          subHeading="Get the best network setup from Market leaders."
          btnText={<>$&nbsp;&nbsp;EARN REWARDS</>}
          longText
          btnHref="/reward-program"
        />
      </Grid>
    </Grid>
  );
};

export default BandWidth;
