export const FooterConstants = [
  {
    heading: "CORPORATE REFERENCE",
    sm: 6,
    md: 4,
    lg: 3,
  },
  {
    heading: "PRODUCTS",
    sm: 6,
    md: 2,
    lg: 2,
  },
  {
    heading: "QUICK LINKS",
    sm: 6,
    md: 2,
    lg: 2,
    links: [
      { name: "DOWNLOADS", to: "/downloads" },
      { name: "NEWS & EVENTS", to: "/news" },
      { name: "OUR BLOG", to: "/" },
      { name: "CONTACT US", to: "/contact" },
    ],
  },
];
