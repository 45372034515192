import * as types from '../action.type';

// GET NEWS
export const getNewsReducer = (state = {}, action) => {
    const response = action.data;

    switch (action.type) {
        case types.GET_NEWS_EVENTS_SUCCESS:
            return response;
        case types.GET_NEWS_EVENTS_ERROR:
            return state;
        default:
            return state;
    }
};
