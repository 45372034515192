import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RouterIcon from "@material-ui/icons/Router";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Banner from "_Shared/Banner";
import SubFooter from "_Shared/SubFooter";
import GlassBgCard from "_Shared/GlassBgCard";
import { usePhoneLandScape, usedownLaptop } from "_Utils/Helpers";
import { DevNConsultList } from "_Constants/Solutions";
import Data from "_Images/software/openwrt.jpg";
import Work from "_Images/software/work.png";
import Laptop from "_Images/software/SoftwareSection1.jpg";
import Macbook from "_Images/software/macbook.png";
import SecondSectionImage from "_Images/software/software-section-2.jpg";
import Gen from "_Images/software/gen.png";
import Ip from "_Images/software/ip.png";
import SoftwareSection from "./components/software";
import Management from "./components/management";
import IopsysAndGen from "./components/Iopsys_Gen";
import MockImage from "_Images/software/mock.png";
import MockPhoneImage from "_Images/software/mockphone.png";
import OpenWrtImage from "_Images/software/openwrt.png";
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
  },
  swDesc: {
    fontSize: 16,
    lineH3ight: "150%",
    marginBottom: 24,
    color: "secondary",
    marginTop: 8,
    [theme.breakpoints.up("md")]: {
      marginBottom: 32,
      fontSize: 20,
      marginTop: 0,
    },
  },
  arrowIcon: {
    borderRadius: "50%",
    padding: 4,
    marginLeft: 14,
    background: "#2C2E30",
    color: "#FFFFFF",
    fontSize: 8,
    [theme.breakpoints.up("sm")]: {
      fontSize: 12,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 4,
      marginLeft: 20,
    },
  },
  linkDiv: {
    marginBottom: 16,
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.up("md")]: { marginBottom: 24 },
  },
  imgItem: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 12,
    [theme.breakpoints.up("sm")]: {
      marginBottom: 24,
      alignItems: "center",
    },
  },
  featureImg: {
    width: 80,
    height: 80,
  },
  featureText: {
    marginLeft: 16,
    width: "100%",
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    lineHeight: "150%",
    [theme.breakpoints.up("sm")]: {
      width: 160,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
      width: "100%",
    },
  },
  routerManagement: {
    border: "24px solid #FFFFFF",
    marginTop: 0,
    [theme.breakpoints.down("md")]: {
      border: "none",
      marginTop: 230,
    },
    [theme.breakpoints.down("xs")]: {
      border: "none",
      marginTop: 250,
    },
  },
}));

const Software = () => {
  const isPhone = usePhoneLandScape();
  const isTab = usedownLaptop();
  const classes = useStyles();

  const BtnText = () => (
    <>
      <RouterIcon />
      <span style={{ marginLeft: 5 }}>View Products</span>
    </>
  );

  const TopContent = (
    <Grid container>
      <Grid item xs={12}>
        <div className={isPhone ? "text32" : "text56"}>Software</div>
        <div className={classes.swDesc}>
          R&D team develops software for the advanced hardware to be in sync
          with the user convenience
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.linkDiv}>
          <div className={isPhone ? "text20" : "text32"}>
            <a
              href="#development-consulting"
              className="link secondary mp-bold"
              style={{ fontWeight: 700, color: "#000" }}
            >
              Wi-Fi Technology
            </a>
          </div>

          <div className="centerDiv">
            <ArrowForwardIosIcon className={classes.arrowIcon} />
          </div>
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.linkDiv}>
          <div className={isPhone ? "text20" : "text32"}>
            <a
              href="#genexis"
              className="link secondary mp-bold"
              style={{ fontWeight: 700, color: "#000" }}
            >
              OpenWrt Project
            </a>
          </div>
          <div className="centerDiv">
            <ArrowForwardIosIcon className={classes.arrowIcon} />
          </div>
        </div>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.linkDiv}>
          <div className={isPhone ? "text20" : "text32"}>
            <a
              href="#olt-management"
              className="link secondary mp-bold"
              style={{ fontWeight: 700, color: "#000" }}
            >
              OLT Management Tool
            </a>
          </div>
          <div className="centerDiv">
            <ArrowForwardIosIcon className={classes.arrowIcon} />
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.linkDiv}>
          <div className={isPhone ? "text20" : "text32"}>
            <a
              href="#router-management"
              className="link secondary mp-bold"
              style={{ fontWeight: 700, color: "#000" }}
            >
              In-home Management Apps
            </a>
          </div>
          <div className="centerDiv">
            <ArrowForwardIosIcon className={classes.arrowIcon} />
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.linkDiv}>
          <div className={isPhone ? "text20" : "text32"}>
            <a
              href="#co-development-rojects"
              className="link secondary mp-bold"
              style={{ fontWeight: 700, color: "#000" }}
            >
              Co-development Projects
            </a>
          </div>
          <div className="centerDiv">
            <ArrowForwardIosIcon className={classes.arrowIcon} />
          </div>
        </div>
      </Grid>
    </Grid>
  );

  const GenContent = (
    <>
      <div
        className={isPhone ? "text24 primary" : "text40 primary"}
        style={{ marginBottom: isPhone ? 6 : 8, fontWeight: 700 }}
      >
        <img src={Gen} alt="gen" />
      </div>
      <div
        className={isPhone ? "text12 darkGrey" : "text16 darkGrey"}
        style={{ marginBottom: isPhone ? 24 : 32 }}
      >
        An EU Fiber product manufacturer and software company shares Indian
        project portfolio with GX Group, so that there is a balance
      </div>
      <div className={isPhone ? "text14" : "text16"}>
        <a
          href="https://genexis.eu/"
          target="_blank"
          className="link secondary mp-semi"
          style={{ color: "#030201" }}
        >
          VISIT GENEXIS.EU
        </a>
      </div>
    </>
  );
  const OpenWrtContent = (
    <>
      <div
        className={isPhone ? "text24 primary" : "text40 primary"}
        style={{ marginBottom: isPhone ? 6 : 8, fontWeight: 700 }}
      >
        {/* <div className={isPhone ? "text24" : "text56"}>OpenWrt</div> */}
        <img src={OpenWrtImage} alt="gen" height="70px" />
      </div>
      <div
        className={isPhone ? "text12 darkGrey" : "text16 darkGrey"}
        style={{ marginBottom: isPhone ? 24 : 32 }}
      >
        OpenWrt is a linux based operating system for embedded devices. Our
        solutions built around the software lets us realize unique and complex
        features on our hardware.
      </div>
    </>
  );
  const IopsysContent = (
    <>
      <div
        className={isPhone ? "text24 primary" : "text40 primary"}
        style={{ marginBottom: isPhone ? 6 : 8, fontWeight: 700 }}
      >
        <img src={Ip} alt="gen" height="36px" />
      </div>
      <div
        className={isPhone ? "text12 darkGrey" : "text16 darkGrey"}
        style={{ marginBottom: isPhone ? 24 : 32 }}
      >
        The leading gateway innovator which works with the open source
        community, same as GX Group R&amp;D team, to evolve technology
      </div>
      <div className={isPhone ? "text14" : "text16"}>
        <a
          href="https://iopsys.eu/"
          target="_blank"
          className="link secondary mp-semi"
          style={{ color: "#030201" }}
        >
          VISIT IOPSYS.eu
        </a>
      </div>
    </>
  );

  function alignImgs(index) {
    if (isPhone && index % 2 != 0) {
      return "flex-start";
    }
    return "flex-start";
  }

  const DevContent = (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        justifyContent="flex-start"
        style={{ marginBottom: isPhone ? 16 : 0 }}
      >
        <div
          className={isPhone ? "text24 primary" : "text40 primary"}
          style={{ fontWeight: 700 }}
        >
          Wi-Fi Technology
        </div>
        <div className={classes.swDesc}>
          We develop and consult technology with community approach
        </div>
      </Grid>
      <Grid
        container
        item
        xs={12}
        lg={12}
        justifyContent="space-between"
        style={{ display: "flex" }}
      >
        {DevNConsultList.map((option, index) => (
          <Grid
            container
            item
            xs={12}
            sm={6}
            lg={6}
            key={index}
            justifyContent={alignImgs(index)}
          >
            <div className={classes.imgItem}>
              <div className="centerDiv">
                <img
                  src={option.img}
                  alt="img"
                  className={classes.featureImg}
                />
              </div>
              <div className={`${classes.featureText} secondary mp-semi`}>
                {option.text}
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  return (
    <Grid container className={classes.root}>
      <SoftwareSection
        bannerImage={Laptop}
        rightDiv={
          <GlassBgCard
            content={TopContent}
            padding_xs="72px 10px 40px 24px"
            padding_sm="72px 40px 40px 40px"
            padding_md="72px 57px 57px 64px"
          />
        }
      />
      <div
        style={{ border: isPhone ? "" : "24px solid #FFFFFF", width: "100%" }}
      >
        <SoftwareSection
          id="development-consulting"
          bannerImage={SecondSectionImage}
          leftDiv={
            <GlassBgCard
              content={DevContent}
              padding_xs="64px 24px 40px 24px"
              padding_sm="20px 24px 20px 24px"
              padding_md="72px 47px 72px 47px"
              padding_lg="64px 54px 45px 54px"
            />
          }
          DevContent_Software
        />
      </div>
      <Grid
        id="genexis"
        style={{
          padding: isPhone ? "" : "0px 24px ",
          background: "#fff",
          width: "100%",
        }}
      >
        <SoftwareSection
          bannerImage={Data}
          rightDiv={
            <GlassBgCard
              content={OpenWrtContent}
              padding_xs="64px 24px 40px 24px"
              padding_md="72px 47px 72px 47px"
            />
          }
          OpenWrt
        />
      </Grid>
      <Grid
        md={12}
        xs={12}
        lg={12}
        sm={12}
        item
        style={{ border: isPhone ? "" : "24px solid #FFFFFF", width: "100%" }}
      >
        <Management
          id="olt-management"
          bannerImage={MockImage}
          heading="OLT Management"
          subHeading="In-house developed EMS which doesn’t need a software installation as it is based on web-based GUI with full control over the network management"
        />
      </Grid>
      <Grid
        md={12}
        xs={12}
        lg={12}
        sm={12}
        item
        className={classes.routerManagement}
      >
        <Management
          id="router-management"
          bannerImage={MockPhoneImage}
          RouterManagement
          heading="Router Management"
          subHeading="Our end-to-end approach towards the network infrastructure allows us to create the WiFi app for managing in-home end user wireless devices for ease of management."
        />
      </Grid>
      <Grid
        md={12}
        xs={12}
        lg={12}
        sm={12}
        item
        className={classes.routerManagement}
      >
        <IopsysAndGen
          id="co-development-rojects"
          bannerImage={Macbook}
          rightDiv={
            <GlassBgCard
              content={GenContent}
              padding_xs="64px 24px 40px 24px"
              padding_md="72px 47px 72px 47px"
            />
          }
          leftDiv={
            <GlassBgCard
              content={IopsysContent}
              padding_xs="64px 24px 40px 24px"
              padding_sm="20px 24px 20px 24px"
              padding_md="72px 47px 72px 47px"
              padding_lg="64px 54px 45px 54px"
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <SubFooter
          heading="Our Products"
          subHeading="Build your network infrastructure with us "
          btnText={<BtnText />}
          btnHref="/#products"
        />
      </Grid>
    </Grid>
  );
};

export default Software;
