import { connect } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { getProductCat } from "_Store/action/productcategory";
import { slugify } from "_Utils/Helpers";
import { Grid } from "@material-ui/core";
const useStyles = makeStyles({
  prodImg: {
    width: 140,
    height: 90,
    // marginBottom: 16,
    objectFit: "cover",
    // marginRight: 16,
  },
  prodDiv: { marginRight: 20 },
});

const ProductsMenu = ({ _product, getProductCat, closeMenu }) => {
  const classes = useStyles();

  let productList = _product && _product.allProductCat;

  return (
    <Grid
      md={12}
      xs={12}
      sm={12}
      lg={12}
      item
      className="flexRow"
      style={{ display: "flex" }}
    >
      {productList &&
        productList.slice(0, 4).map((obj, index) => (
          <Grid item container spacing={2} key={index}>
            <Grid className={`${classes.prodDiv} flexColumn`}>
              <a
                href={"/products#" + slugify(obj?.title ?? "")}
                className="text16 primary link mp-bold"
              >
                <div className={classes.prodImg}>
                  <img
                    src={obj.iconImage}
                    alt="prod-img"
                    height="100%"
                    width="100%"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="textCenter" onClick={() => closeMenu()}>
                  {obj.title}
                </div>
              </a>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
};

const mapStateToProps = ({ getProductCat }) => {
  return { _product: getProductCat };
};

export default connect(mapStateToProps, { getProductCat })(ProductsMenu);
