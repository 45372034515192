import api from "_Utils/Api";

export const getproductService = (req) => {
//   let headers = new Headers();
//   headers.append("Content-Type", "application/json");
//   headers.append("Accept", "application/json");

//   headers.append("Access-Control-Allow-Origin", "http://localhost:3001");
//   headers.append("Access-Control-Allow-Credentials", "true");
//   headers.append("GET", "POST", "OPTIONS");

  return api
    .get(`/products/getproduct?productCategory=${req.data ? req.data : all}`, 
    // {
    //   withCredentials: true,
    //   headers: headers,
    // }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getALLproductService = (req) => {
  // let headers = new Headers();
  // headers.append('Content-Type', 'application/json');
  // headers.append('Accept', 'application/json');

  // headers.append('Access-Control-Allow-Origin', 'http://localhost:3001');
  // headers.append('Access-Control-Allow-Credentials', 'true');
  // headers.append('GET', 'POST', 'OPTIONS');

  return api
    .get(
      "/products/get_all_Product"
      // {
      //     withCredentials: true,
      //     headers: headers,
      // }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
