import Logo1 from "_Images/home/logo1.png";
import Logo2 from "_Images/home/logo2.png";
import Logo3 from "_Images/home/logo3.png";
import Logo4 from "_Images/home/logo4.png";
import Image1 from "_Images/solutions/home.jpg";
import Image2 from "_Images/solutions/realState.jpg";
import Image3 from "_Images/solutions/reception.jpg";

export const GoalsData = [
  {
    pic: Logo1,
    title: "Leading Fiber Manufacturers",
    subtitle: "Fastest growing FTTH/GPON vendors",
  },
  {
    pic: Logo2,
    title: "Enabling Communication",
    subtitle: "Connecting people to Digital World",
  },
  {
    pic: Logo3,
    title: "Our Vision",
    subtitle: "Improvise, Innovate and redefine FTTH",
  },
  {
    pic: Logo4,
    title: "Global Expansion",
    subtitle: "Local setup that helps grow us globally",
  },
];

export const SlideShowData = [
  {
    id: 1,
    pic: Image1,
    to: "/solutions#ftth",
    title: "In-Home FTTH Setup",
  },
  {
    id: 2,
    pic: Image2,
    to: "/solutions#real-estate",
    title: "Real Estate Infrastructure",
  },
  {
    id: 3,
    pic: Image3,
    to: "/solutions#hospitality",
    title: "Hospitality Services ",
  },
];

export const DownloadsList = {
  topics: [
    {
      title: "Documentation",
      query: "documentation",
    },
    { title: "Corporate Reference", query: "corporateReference" },

    // "ONT Datasheets",
    // "OLT Datasheets",
    // "XGPON Datasheets",
    // "Other Datasheets",
  ],
  subTopics: [
    "RMA Guidelines",
    "RMA Request Form ",
    "Product Warranty",
    "Service Level Agreement",
    "Customer Registration Form",
    "Quality Policy",
  ],
  // queryParams: ["documentation", "corporateReference"],
};

export const SoftwareList = [
  {
    topic: "ONT Datasheets",
    expanded: false,
    subTopics: [
      "RMA Guidelines",
      "RMA Request Form ",
      "Product Warranty",
      "Service Level Agreement",
      "Customer Registration Form",
      "Quality Policy",
    ],
  },
  {
    topic: "OLT Datasheets",
    expanded: false,
    subTopics: [
      "RMA Guidelines",
      "RMA Request Form ",
      "Product Warranty",
      "Service Level Agreement",
      "Customer Registration Form",
      "Quality Policy",
    ],
  },
];
