import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AnimatedDiv from "_Shared/AnimatedDiv";
import {
  GenerateIcon,
  usePhoneLandScape,
  usePhablet,
  slugify,
} from "_Utils/Helpers";

const SolutionInfo = ({ solnObj = {}, dark = false, realtedProduct }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "32px 20px 20px 20px",
      backgroundColor: dark ? "#2C2E30" : "#FFFFFF",

      [theme.breakpoints.up("sm")]: {
        padding: "48px 40px 64px 40px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "48px 20px 64px 20px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "48px 40px 64px 40px",
      },
    },
    coverImg: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: 2,
    },
    featureImg: {
      width: 70,
      height: 70,
      marginBottom: 5,
    },
    products: {
      marginTop: 32,
      [theme.breakpoints.between("md", "lg")]: {
        paddingLeft: 10,
      },
    },
    imgItem: {
      display: "flex",
      flexDirection: "row",

      [theme.breakpoints.up("md")]: {
        flexDirection: "column",
        alignItems: "center",
        width: 150,
      },
      [theme.breakpoints.up("md")]: {
        width: 200,
      },
      [theme.breakpoints.up("xl")]: {
        flexDirection: "row",
        width: 300,
      },
    },
    featureText: {
      marginLeft: 16,
      [theme.breakpoints.up("md")]: {
        marginLeft: 20,
      },
    },
    relatedDiv: {
      marginTop: 32,
      marginBottom: 12,
      [theme.breakpoints.up("md")]: {
        marginTop: 56,
        marginBottom: 16,
      },
    },
    altProdsImg: {
      width: "100%",
      height: "100%",
      marginBottom: 16,
      objectFit: "contain",
    },
    prodImageGrid: {
      height: 130,
    },
  }));

  const classes = useStyles();
  const isPhablet = usePhablet();
  const isPhone = usePhoneLandScape();

  function alignImgs(index) {
    if (isPhone && index % 2 != 0) {
      return "flex-start";
    }
    return "flex-start";
  }

  return (
    <>
      <Grid
        md={12}
        xs={12}
        sm={12}
        lg={12}
        item
        container
        className={classes.root}
        justifyContent="center"
      >
        <Grid lg={1} item />
        <Grid lg={10} md={12} xs={12} sm={12} item>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sm={12}
            container
            justifyContent="center"
            style={{ marginBottom: isPhone ? 16 : 48 }}
          >
            <div className={isPhablet ? "flexColumn" : "centerDiv flexColumn"}>
              <div
                className={isPhone ? "text24 primary" : "text56"}
                style={{ marginBottom: 10, fontWeight: 700 }}
              >
                {solnObj.title}
              </div>
              <div className={isPhone ? "text12" : "text20"}>
                <span className={dark ? "white" : "darkGrey"}>
                  {solnObj.subTitle}
                </span>
              </div>
            </div>
          </Grid>
          <Grid md={12} lg={12} sm={12} xs={12} item container direction="row">
            <Grid item xs={12} md={5} lg={5}>
              <div
                className={isPhone ? "" : "centerDiv stretch"}
                style={{ height: 270, width: "100%" }}
              >
                <img
                  src={solnObj.coverImg}
                  alt="cover"
                  className={classes.coverImg}
                />
              </div>
            </Grid>
            <Grid lg={1} item />
            <Grid
              container
              item
              xs={12}
              md={7}
              lg={6}
              alignItems="center"
              justifyContent="space-between"
              className={classes.products}
            >
              {solnObj.features.map((feature, index) => (
                <Grid
                  container
                  item
                  xs={12}
                  md={6}
                  lg={5}
                  key={index}
                  justifyContent={alignImgs(index)}
                >
                  <div className={classes.imgItem}>
                    <div>
                      <GenerateIcon
                        iconPath={feature.icon}
                        alt={feature.alt}
                        className={classes.featureImg}
                      />
                    </div>
                    <div
                      className={
                        // isPhone
                        //   ? `${classes.featureText} text12 centerDiv`
                        `${classes.featureText} text16 centerDiv textCenter`
                      }
                      style={{ textAlign: "start" }}
                    >
                      <span
                        className={dark ? "white mp-semi" : "secondary mp-semi"}
                        style={{ fontWeight: 700 }}
                      >
                        {feature.text}
                      </span>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            className={classes.relatedDiv}
            container
            justifyContent="center"
          >
            <div
              className={isPhone ? "text20 centerDiv" : "text32 centerDiv"}
              style={{ marginBottom: isPhone ? 12 : 16 }}
            >
              <span
                className={dark ? "white" : "secondary"}
                style={{ fontWeight: 700 }}
              >
                Related Products
              </span>
            </div>
          </Grid>

          <Grid
            md={12}
            sm={12}
            xs={12}
            item
            justifyContent="center"
            container
            spacing={2}
          >
            {realtedProduct.map((prod, index) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={4}
                lg={4}
                justifyContent="center"
                container
                key={index}
              >
                <Grid md={8} item>
                  <a
                    href={"/products#" + slugify(prod?.name ?? "")}
                    style={{ textDecoration: "none" }}
                  >
                    <Grid className={classes.prodImageGrid}>
                      <img
                        src={prod.img}
                        className={classes.altProdsImg}
                        alt="products"
                      />
                    </Grid>
                    <div
                      className={isPhone ? "text16" : "text24"}
                      style={{ textAlign: "center", marginTop: 24 }}
                    >
                      <span className={dark ? "lightGrey" : "darkGrey"}>
                        <b>{prod.name}</b>
                      </span>
                    </div>
                  </a>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid lg={1} item />
      </Grid>
    </>
  );
};

export default SolutionInfo;
