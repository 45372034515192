import JoinUs from './components/JoinUs';
import Banner from '_Shared/Banner';
import GlassBgCard from '_Shared/GlassBgCard';
import BannerImage from '_Images/about/people.png';

const Careers = () => {
    const cardText = (
        <>
            <b>Communication</b> has always been the main driving force for any
            society. Digitalization of a society is not a surprise to anyone.
            Unfortunately, there isn’t enough digital infrastructure that allows
            everyone to be part of digital society.
            <br />
            <br />
            <b>Our mission</b> is to develop the right product for each market,
            supporting highest quality connectivity solutions for our clients:
            network operators, service providers, system integrators, and the
            end user.
        </>
    );

    const GlassDiv = (
        <GlassBgCard
            content={cardText}
            padding_xs="48px 24px 67px 24px"
            padding_md="85px 46px 56px 46px"
        />
    );

    return (
        <>
            <Banner image={BannerImage} leftDiv={GlassDiv} />
            <JoinUs />
        </>
    );
};

export default Careers;
