import * as types from '../action.type';
export const getProductReducer = (state = {}, action) => {
    const response = action.data;

    switch (action.type) {
        case types.GETPRODUCT_SUCCESS:
            return response;
        case types.GETPRODUCT_ERROR:
            return state;
        default:
            return state;
    }
};

export const get_ALL_ProductReducer = (state = {}, action) => {
    const response = action.data;

    switch (action.type) {
        case types.GET_ALL_PRODUCT_SUCCESS:
            return response;
        case types.GET_ALL_PRODUCT_ERROR:
            return state;
        default:
            return state;
    }
};
