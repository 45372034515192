import makeStyles from '@material-ui/core/styles/makeStyles';
import AnimatedDiv from '_Shared/AnimatedDiv';
import { usePhoneLandScape } from '_Utils/Helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'linear-gradient(104.04deg, #FFFFFF 0%, #FFF3EB 100%)',
        padding: '30px 30px 30px 30px',
    },
}));

const HallofFame = () => {
    const classes = useStyles();
    const isPhone = usePhoneLandScape();

    return (
        <div className={`${classes.root} centerDiv flexColumn`}>
            <AnimatedDiv
                children={
                    <>
                        <div
                            className={
                                isPhone ? 'text32 mp-bold' : 'text56 mp-bold'
                            }
                            style={{ marginBottom: 8 }}
                        >
                            Hall of Fame
                        </div>
                        <div
                            className={
                                isPhone ? 'text14 darkGrey' : 'text20 darkGrey'
                            }
                        >
                            Coming Soon...
                        </div>
                    </>
                }
                animation="fade-up"
            />
        </div>
    );
};

export default HallofFame;
