import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const AnimatedDiv = ({ children, animation = "fade-up" }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div
      data-aos={animation}
      data-aos-duration={600}
      data-aos-once={true}
      style={{ width: "100%" }}
    >
      {children}
    </div>
  );
};

export default AnimatedDiv;
