import { Card, Grid, useMediaQuery } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { managementData } from "_Constants/ManagementInfo";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { usePhoneLandScape } from "_Utils/Helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "75px 0px",
    height: "100%",

    [theme.breakpoints.between("sm", "md")]: {
      height: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
    },
  },

  avatar: {
    height: "100%",
    width: "100%",
    overflow: "inherit",
  },
  card: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "14px",
      marginBottom: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
      marginBottom: "16px",
    },
  },
  ceoQuote: {
    display: "flex",
    marginTop: 100,
    justifyContent: "center",
    textAlign: "center",
  },
  cardTitle: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "125%",
    textAlign: "center",
    color: "#030201",
    marginTop: "8px",
  },
  cardSubTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "125%",
    textAlign: "center",
    color: "#FD6A02",
    margin: "4px 0px",
  },
  ceoPos: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "125%",
    textAlign: "center",
    color: "#2C2E30",
    marginBottom: "16px",
    minHeight: "20px",
  },
}));

const AboutCeo = () => {
  const classes = useStyles();
  const isPhone = usePhoneLandScape();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Grid
      xs={12}
      sm={12}
      md={12}
      lg={12}
      item
      container
      justifyContent="center"
      className={classes.root}
    >
      <Grid
        container
        item
        xs={11}
        sm={11}
        md={10}
        lg={9}
        xl={8}
        direction="row"
        justifyContent="center"
      >
        <Grid container item xs={12} direction="row" justifyContent="center">
          <AnimatedDiv
            children={
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="center"
                spacing={isSmallScreen ? false : 3}
              >
                {managementData.map((obj, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    key={index}
                    direction="row"
                    justifyContent="center"
                  >
                    <AnimatedDiv
                      children={
                        <Card className={classes.card}>
                          <div className={classes.cityImg}>
                            <img
                              src={obj.pic}
                              alt="pic"
                              height="100%"
                              width="100%"
                              style={{ objectFit: "cover" }}
                            />
                          </div>
                          <div>
                            <div className={classes.cardTitle}>{obj.title}</div>
                            <div className={classes.cardSubTitle}>
                              {obj.address}
                            </div>
                            <div className={classes.ceoPos}>
                              {obj?.contact ?? " "}
                            </div>
                          </div>
                        </Card>
                      }
                      animation="fade-up"
                    />
                  </Grid>
                ))}
              </Grid>
            }
            animation={isPhone ? "fade-up" : "fade-right"}
          />
        </Grid>

        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          className={classes.ceoQuote}
        >
          <AnimatedDiv
            children={
              <>
                <div
                  className={isPhone ? "text16 secondary" : "text20 secondary"}
                  style={{ textAlign: isPhone ? "center" : "flex-start" }}
                >
                  "Today a world class digital connectivity is a crucial driver
                  for productivity and growth, supported by innovative products
                  making digital transformation possible! "
                </div>
                <div
                  className={
                    isPhone
                      ? "text20 primary mp-bold"
                      : "text24 primary  mp-bold"
                  }
                  style={{
                    marginTop: isPhone ? 24 : 32,
                    textAlign: isPhone ? "center" : "flex-start",
                  }}
                >
                  Paritosh Prajapati, CEO
                </div>
              </>
            }
            animation={isPhone ? "fade-up" : "fade-left"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutCeo;
