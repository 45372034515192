import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { BlackBtn, OrangeBtn } from "_Shared/Buttons";
import ProductsMenu from "_Shared/ProductsMenu";
import { GenerateIcon } from "_Utils/Helpers";
import LogoIcon from "_Icons/logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { getProductCat } from "_Store/action/productcategory";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  header: {
    boxShadow: "none",
    position: "fixed",
    right: 0,
    width: "100%",
    height: 70,
    zIndex: 30,
    paddingTop: "2%",
    paddingLeft: "2.5%",
    paddingRight: "2.5%",
  },
  logo: {
    width: 54.25,
    height: 54.25,
  },
  menuDiv: {
    width: "100%",
    height: 72,
    padding: "0px 16px",
    boxShadow: "16px 16px 32px rgba(0, 0, 0, 0.08)",
    alignItems: "center",
  },
  // menuItem: {
  //   height: 24,
  //   // padding: "8px 48px 8px 0px",
  // },
  link: {
    textDecoration: "none",
    // color: "#0a1219",
    textTransform: "none",
    fontFamily: "MavenPro-SemiBold",
    fontSize: 16,
  },
  activeLink: {
    paddingBottom: 10,
    borderBottom: "2px solid #0a1219",
  },
  btn: {
    textTransform: "none",
    padding: 0,
    color: "#FFF3EB",
    background: "#FD6A02",
    borderRadius: "12px",
    width: "92px",
    height: "40px",
  },
  menuButton: {
    padding: 0,
    // marginRight: 48,
  },
});

const Header = () => {
  const classes = useStyles();
  const location = useLocation();

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [path, setPath] = useState();
  // const AllProductCat = useSelector((state) => state.getProductCat);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(getProductCat());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (location?.pathname) {
      setPath(location?.pathname);
    }
  }, [location]);

  return (
    <Grid container className={classes.header}>
      <Grid item xs={5} lg={5}>
        <NavLink to="/">
          <GenerateIcon iconPath={LogoIcon} alt="logo" />
        </NavLink>
      </Grid>
      <Grid container item justifyContent="flex-end" xs={10} lg={7}>
        <Grid
          lg={12}
          md={12}
          item
          className={`${classes.menuDiv} flexRowAround glass`}
        >
          <Grid>
            <Button className={classes.menuButton} onClick={handleClick}>
              <Grid className={`${classes.link} text16`}>PRODUCTS</Grid>
              <ExpandMoreIcon />
            </Button>

            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              PaperProps={{
                style: {
                  marginTop: 30,
                  marginLeft: "-6.1%",
                  padding: "32px 20px 24px 32px", //20,

                  background:
                    "linear-gradient(147.7deg,rgba(255, 255, 255, 0.6) 0%,rgba(255, 255, 255, 0.4) 100.77%)",
                  border: "1px solid rgba(255, 255, 255, 0.3)",
                  boxSizing: "border-box",
                  boxShadow: "16px 16px 32px rgba(255, 255, 255, 0.08)",
                  backdropFilter: "blur(32px)",
                  borderRadius: 18,
                  overflow: "hidden",
                },
              }}
            >
              <ProductsMenu closeMenu={handleClose} />
            </Menu>
          </Grid>
          <Grid className={classes.menuItem}>
            <NavLink
              className={({ isActive }) => {
                const linkClasses = [classes.link];
                if (isActive) linkClasses.push(classes.activeLink);
                return linkClasses.join(" ");
              }}
              to="/solutions"
              // className={classes.link}
              // activeClassName={classes.activeLink}
            >
              <span style={{ color: "#0a1219" }}> SOLUTIONS</span>
            </NavLink>
          </Grid>
          <Grid className={classes.menuItem}>
            <NavLink
              to="/software"
              // className={classes.link}
              // activeClassName={classes.activeLink}
              className={({ isActive }) => {
                const linkClasses = [classes.link];
                if (isActive) linkClasses.push(classes.activeLink);
                return linkClasses.join(" ");
              }}
            >
              <span style={{ color: "#0a1219" }}> SOFTWARE</span>
            </NavLink>
          </Grid>
          <Grid className={classes.menuItem}>
            <NavLink
              to="/about"
              // className={classes.link}
              // activeClassName={classes.activeLink}
              className={({ isActive }) => {
                const linkClasses = [classes.link];
                if (isActive) linkClasses.push(classes.activeLink);
                return linkClasses.join(" ");
              }}
            >
              <span style={{ color: "#0a1219" }}> ABOUT</span>
            </NavLink>
          </Grid>
          <Grid className={classes.menuItem}>
            <NavLink
              to="/careers"
              // className={classes.link}
              // activeClassName={classes.activeLink}
              className={({ isActive }) => {
                const linkClasses = [classes.link];
                if (isActive) linkClasses.push(classes.activeLink);
                return linkClasses.join(" ");
              }}
            >
              <span style={{ color: "#0a1219" }}> CAREER</span>
            </NavLink>
          </Grid>
          <Grid>
            {path === "/contact" ? (
              <BlackBtn
                text="CONTACT"
                width={120}
                height={40}
                padding="8px 24px"
                btnHref="/contact"
                weight={700}
              />
            ) : (
              <OrangeBtn
                text="CONTACT"
                width={120}
                height={40}
                padding="8px 24px"
                btnHref="/contact"
                weight={700}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
