import makeStyles from "@material-ui/core/styles/makeStyles";
import { usePhoneLandScape } from "_Utils/Helpers";
import Grid from "@material-ui/core/Grid";

const GlassBgCard = ({
  content,
  padding_xs = 0,
  padding_sm,
  padding_md = 0,
  padding_lg,
  darkTheme = false,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: padding_xs,
      [theme.breakpoints.up("sm")]: {
        padding: padding_sm ?? padding_xs,
      },
      [theme.breakpoints.up("md")]: {
        padding: padding_md,
      },
      [theme.breakpoints.up("lg")]: {
        padding: padding_lg ?? padding_md,
      },
    },
  }));

  const classes = useStyles();
  const isPhone = usePhoneLandScape();

  return (
    <Grid
      md={12}
      xs={12}
      sm={12}
      item
      lg={12}
      container
      className={
        darkTheme ? `${classes.root} glass-black` : `${classes.root} glass`
      }
    >
      <div className={isPhone ? "text16 secondary" : "text20 secondary"}>
        {content}
      </div>
    </Grid>
  );
};

export default GlassBgCard;
