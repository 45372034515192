import { useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { usePhoneLandScape, useSmallPhone } from "_Utils/Helpers";
import EMSImage from "../../../assets/images/home/Helpdesk.png";
import MobileImage from "../../../assets/images/software/mockphone.png";
// import AnimatedDiv from "_Shared/AnimatedDiv";
import { IconButton } from "@material-ui/core";
import GooglePlayStore from "../../../assets/images/home/google.png";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// import AOS from "aos";
import "aos/dist/aos.css";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 100,
    padding: 32,
    position: "relative",
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
      padding: 0,
    },
  },
  supportButton: {
    textDecoration: "none",
    color: "#030201",
    background: "#FFFFFF",
    borderRadius: 32,
    padding: "12px 14px",
    textTransform: "none",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  arrows: {
    display: "flex",
    marginTop: 24,
  },
  arrowbutton: {
    padding: 8,
  },
  BodyTextGrid: {
    minHeight: 672,
    paddingRight: 16,
    display: "flex",
    position: "relative",
    background: "#2C2E30",
    paddingBottom: 50,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 16,
      minHeight: "100%",
      alignItems: "flex-start",
    },
    [theme.breakpoints.only("sm")]: {
      // marginTop: "51%",
      paddingLeft: 16,
      minHeight: "100%",
      alignItems: "flex-start",
    },
  },
  preparedImageGrid: {
    height: "auto",
    zIndex: 0,
    position: "absolute",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      top: 90,
      padding: 20,
      justifyContent: "center",
    },
  },
  preparedImage: {
    height: "100%",
    width: "100%",
    // objectFit: "contain",
  },
  title: {
    color: "#FD6A02",
    fontSize: 40,
    fontWeight: 700,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
  },
  body: {
    color: "#FFFFFF",
    fontSize: 20,
    marginTop: 16,
    marginBottom: 16,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  googleImage: {
    marginTop: 48,
    height: 55,
  },
  content: {
    [theme.breakpoints.down("md")]: {
      marginTop: 80,
    },
  },
  MObileGrid: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  arrowIcon: {
    fontSize: 8,
    borderRadius: "50%",
    color: "#000",
    padding: 8,
    [theme.breakpoints.up("md")]: {
      fontSize: 12,
    },
  },
}));

const MobilApp = ({}) => {
  const classes = useStyles();
  const matches = usePhoneLandScape();
  const isPhone = useSmallPhone();
  const [prevSlide, setprevSlide] = useState(false);
  const [nextSlide, setnextSlide] = useState(false);
  let data = [
    {
      image: MobileImage,
      title: "In-home Wifi management app",
      body: "Our end-to-end approach towards the network infrastructure allows us to create the WiFi app for managing in-home end user wireless devices for ease of management.",
      playstoreImage: GooglePlayStore,
    },
    {
      image: EMSImage,
      title: "Helpdesk and Support",
      body: "Our end-to-end approach towards the network infrastructure allows us to create the WiFi app for managing in-home end user wireless devices for ease of management",
      // playstoreImage: GooglePlayStore,
    },
  ];
  const [productData] = useState(data);
  const [slideIndex, setSlideIndex] = useState(0);

  // arrows slider

  const previous = () => {
    if (slideIndex === 0) {
      return setSlideIndex(data && data.length - 1);
    }

    setSlideIndex(slideIndex - 1);
    setprevSlide(true);
    setnextSlide(false);

    setTimeout(() => {
      setprevSlide(false);
    }, 500);
  };
  const next = () => {
    setSlideIndex(slideIndex + 1);
    setnextSlide(true);
    setprevSlide(false);
    setTimeout(() => {
      setnextSlide(false);
    }, 500);
  };
  let length = data && data.length - 1;

  return (
    <Grid container className={classes.root}>
      <Grid md={12} xs={12} sm={12} item container alignItems="center">
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          md={12}
          xs={12}
          sm={12}
          item
          className={classes.MObileGrid}
        >
          <Grid />
          <Grid
            md={9}
            item
            container
            justifyContent="space-between"
            className={classes.BodyTextGrid}
            data-aos={"fade-left"}
            data-aos-duration={600}
            data-aos-once={true}
          >
            <Grid md={5} xs={3} sm={3} item />

            <Grid
              md={6}
              xs={12}
              sm={12}
              item
              container
              alignItems="center"
              className={
                prevSlide
                  ? "mySlides fadeLeftText"
                  : nextSlide
                  ? "mySlides fadeRightText"
                  : ""
              }
            >
              <Grid md={12} xs={12} sm={12} item>
                <div className={classes.content}>
                  <Typography className={classes.title}>
                    {data[slideIndex].title}
                  </Typography>
                  <Typography className={classes.body}>
                    {data[slideIndex].body}
                  </Typography>
                  {data[slideIndex].playstoreImage ? (
                    <Button style={{ padding: 0, marginTop: 40 }}>
                      <Typography className={classes.supportButton}>
                        DOWNLOAD NOW
                      </Typography>
                    </Button>
                  ) : (
                    <Button style={{ padding: 0, marginTop: 40 }}>
                      <Typography className={classes.supportButton}>
                        COMING SOON
                      </Typography>
                    </Button>
                  )}
                </div>
                <Grid
                  md={12}
                  xs={12}
                  sm={12}
                  lg={12}
                  item
                  className={classes.arrows}
                >
                  <IconButton
                    disabled={slideIndex === 0 ? true : false}
                    className={classes.arrowbutton}
                    onClick={previous}
                  >
                    <ArrowForwardIosIcon
                      className={classes.arrowIcon}
                      style={{
                        background:
                          slideIndex === 0
                            ? "rgba(255, 255, 255, 0.5)"
                            : "#FFFFFF",
                        transform: "rotate(180deg)",
                      }}
                    />
                  </IconButton>

                  <IconButton
                    disabled={slideIndex === length ? true : false}
                    onClick={next}
                    className={classes.arrowbutton}
                  >
                    <ArrowForwardIosIcon
                      className={classes.arrowIcon}
                      style={{
                        background:
                          slideIndex === length
                            ? "rgba(255, 255, 255, 0.5)"
                            : "#FFFFFF",
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid md={1} item />
          </Grid>
          <Grid
            md={6}
            xs={12}
            sm={12}
            container
            item
            className={classes.preparedImageGrid}
            data-aos={"fade-right"}
            data-aos-once={true}
          >
            <Grid
              md={11}
              sm={12}
              xs={12}
              item
              style={{
                transition: "1s",
                opacity: 1,
                zIndex: 0,
                height: matches ? "auto" : "100%",
                width: matches ? "100%" : "100%",
              }}
              className={
                prevSlide
                  ? "mySlides fade"
                  : nextSlide
                  ? "mySlides fadeRight"
                  : ""
              }
            >
              <img
                src={data[slideIndex].image}
                alt="rd"
                className={classes.preparedImage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MobilApp;
