import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ThreeDRotationIcon from "@material-ui/icons/ThreeDRotation";

const useStyles = makeStyles((theme) => ({
  root: {},
  imgDiv: {
    height: 450,
    // background: "#99958a",
    width: "100%",
  },
  prodImg: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  iconBtn: {
    fontSize: 32,
    color: "#2C2E30",
    padding: 10,
    background: "#FFF3EB",
    height: 24,
    width: 24,
    borderRadius: "50%",
    margin: "0px 8px",
  },
}));

const ImageSlides = ({ imgArr, fwd_rev = true }) => {
  const classes = useStyles();
  const len = imgArr.length;
  const [index, setIndex] = useState(0);

  const decIndex = () =>
    index - 1 < 0 ? setIndex(len - 1) : setIndex(index - 1);

  const incIndex = () => (index + 1 >= len ? setIndex(0) : setIndex(index + 1));

  return (
    <Grid container className={classes.root}>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        className={classes.imgDiv}
      >
        <img src={imgArr[index]} alt="prodImg" className={classes.prodImg} />
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <div>
          <ChevronLeftIcon
            onClick={() => decIndex()}
            className={classes.iconBtn}
            style={{ cursor: "pointer" }}
          />
          <ChevronRightIcon
            onClick={() => incIndex()}
            className={classes.iconBtn}
            style={{ cursor: "pointer" }}
          />
        </div>
      </Grid>

      {/* {!fwd_rev && (
        <Grid container item xs={12} justifyContent="center">
          <IconButton onClick={() => incIndex()}>
            <ThreeDRotationIcon />
            &nbsp;&nbsp;
            <div className="text16 secondary">360º view</div>
          </IconButton>
        </Grid>
      )} */}
    </Grid>
  );
};

export default ImageSlides;
