import Grid from "@material-ui/core/Grid";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FacebookIcon from "_Icons/fb-orange.svg";
import LinkedInIcon from "_Icons/lkd-orange.svg";
import { usePhoneLandScape, GenerateIcon } from "_Utils/Helpers";
import Banner from "_Shared/Banner";
import GlassBgCard from "_Shared/GlassBgCard";
import BannerImage from "_Images/contact/map.png";
import MapMobile from "_Images/contact/map_mobile.png";

const useStyles = makeStyles((theme) => ({
  emailText: {
    display: "flex",
    flexDirection: "row",
    marginTop: 16,
    [theme.breakpoints.down("xs")]: {
      marginRight: 25,
    },
  },
  socialIcon: {
    width: 20,
    height: 20,
    marginRight: 12,
  },
  locationIcon: {
    color: "#FD6A02",
    width: 28,
    height: 40,
    [theme.breakpoints.up("md")]: {
      width: 40,
      height: 60,
    },
  },
  addressContainer: {
    [theme.breakpoints.up("lg")]: {
      width: 446,
    },
  },
}));

const ContactDetail = () => {
  const isPhone = usePhoneLandScape();
  const classes = useStyles();

  const AddressText = (
    <div className={classes.addressContainer}>
      {/* {isPhone && ( */}
      <div
        className="text12 lightGrey"
        style={{ fontWeight: 700, marginBottom: 8, color: "#fff" }}
      >
        HEADQUARTERS
        <br />
      </div>
      {/* )} */}
      <div
        className={isPhone ? "text16 lightGrey" : "text24 lightGrey"}
        style={{ color: "#fff" }}
      >
        <b> GX International B.V.</b>
      </div>
      <div
        className={isPhone ? "text12 white" : "text16 white"}
        style={{ color: "#FFF3EB", marginTop: 8 }}
      >
        Waldfeuchterbaan, 124 6105 BP
        <br /> Maria Hoop, The Netherlands
      </div>
      <div
        className={isPhone ? "text14 lightGrey" : "text16 lightGrey"}
        style={{ marginTop: isPhone ? 31 : 40, marginBottom: 7, color: "#fff" }}
      >
        <b>Contact Details</b>
      </div>
      <div
        className={isPhone ? "text12 white" : "text14 white"}
        style={{ color: "#FFF3EB", marginTop: 8 }}
      >
        +46 8437 37942 (For International)
        <br /> +91 124 422 4113 (For India)
        <div className={classes.emailText}>
          <div style={{ marginRight: 16, color: "#fff", fontWeight: 700 }}>
            info@gxgroup.eu
          </div>
          <div>
            <a href="https://www.facebook.com/gxgroup.eu">
              <GenerateIcon
                iconPath={FacebookIcon}
                alt="facebook"
                className={classes.socialIcon}
              />
            </a>
          </div>
          <div>
            <a href="https://www.linkedin.com/company/gxgroup">
              <GenerateIcon
                iconPath={LinkedInIcon}
                alt="linkedin"
                className={classes.socialIcon}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  const AddressDiv = (
    <Grid md={12} xs={12} sm={12} lg={12} item>
      <Grid md={12} xs={12} sm={12} lg={12} item>
        <GlassBgCard
          content={AddressText}
          darkTheme={true}
          padding_xs="80px 24px 48px 24px"
          padding_sm="50px 24px 30px 24px"
          padding_md="40px 40px 40px 40px"
          address={true}
        />
      </Grid>
    </Grid>
  );

  const AddressMarker = (
    <Grid container>
      <Grid container item xs={2} justifyContent="center" alignItems="center">
        <LocationOnIcon className={classes.locationIcon} />
      </Grid>
      <Grid container item xs={10} justifyContent="space-between">
        <div
          className={
            isPhone ? "text16 primary mp-bold" : "text24 primary mp-bold "
          }
        >
          GX International B.V.
        </div>
        <div
          className={
            isPhone ? "text12 darkGrey mp-semi" : "text16 darkGrey mp-semi"
          }
        >
          Waldfeuchterbaan, 124 6105 BP
        </div>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <Banner
        image={isPhone ? MapMobile : BannerImage}
        leftDiv={AddressDiv}
        addressDiv={AddressMarker}
        smallSize
        // contact_Second_banner
        contactbanner
      />
    </div>
  );
};

export default ContactDetail;
