import React from "react";
import ContactForm from "./components/ContactForm";
import ContactDetail from "./components/ContactDetail";
import Locations from "./components/Locations";
import Banner from "_Shared/Banner";
import BannerImage from "_Images/contact/banner.jpg";

const Contact = () => {
  return (
    <div style={{ overflowX: "hidden" }}>
      <Banner image={BannerImage} rightDiv={<ContactForm />} ContactForm />
      <ContactDetail />
      <Locations />
    </div>
  );
};

export default Contact;
