export const GETVACANCY = "GETVACANCY";
export const GETVACANCY_SUCCESS = "GETVACANCY_SUCCESS";
export const GETVACANCY_ERROR = "GETVACANCY_ERROR";

export const GET_NEWS_EVENTS = "GET_NEWS_EVENTS";
export const GET_NEWS_EVENTS_SUCCESS = "GET_NEWS_EVENTS_SUCCESS";
export const GET_NEWS_EVENTS_ERROR = "GET_NEWS_EVENTS_ERROR";

export const GET_PRODUCT_CATEGORY = "GET_PRODUCT_CATEGORY";
export const GET_PRODUCT_CATEGORY_SUCCESS = "GET_PRODUCT_CATEGORY_SUCCESS";
export const GET_PRODUCT_CATEGORY_ERROR = "GET_PRODUCT_CATEGORY_ERROR";

export const GETPRODUCT = "GETPRODUCT";
export const GETPRODUCT_SUCCESS = "GETPRODUCT_SUCCESS";
export const GETPRODUCT_ERROR = "GETPRODUCT_ERROR";

export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCT_ERROR = "GET_ALL_PRODUCT_ERROR";

//COntact
export const POST_CONTACT = "POST_CONTACT";
export const POST_CONTACT_SUCCESS = "POST_CONTACT_SUCCESS";
export const POST_CONTACT_ERROR = "POST_CONTACT_ERROR";
// job apply
export const JOB_APPLY = "JOB_APPLY";
export const JOB_APPLY_SUCCESS = "JOB_APPLY_SUCCESS";
export const JOB_APPLY_ERROR = "JOB_APPLY_ERROR";

export const DOWNLOAD = "DOWNLOAD";
export const DOWNLOAD_SUCCESS = "DOWNLOAD_SUCCESS";
export const DOWNLOAD_ERROR = "DOWNLOAD_ERROR";
