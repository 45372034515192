import makeStyles from "@material-ui/core/styles/makeStyles";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import SubFooter from "_Shared/SubFooter";
import SlideShow from "_Shared/SlideShow";
import SolutionInfo from "./components/SolutionInfo";
import { FTTHObj, RealEstateObj, HospitalityObj } from "_Constants/Solutions";
import Grid from "@material-ui/core/Grid";
import {
  RelatedProductsHome,
  RelatedProductsHospitality,
  RelatedProductsRealEstate,
} from "_Constants/Solutions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    position: "relative",
  },
  whiteDiv: {
    height: 800,
    background: "linear-gradient(104.04deg, #ffffff 0%, #fff3eb 100%)",
    [theme.breakpoints.up("md")]: {
      height: 600,
    },
  },
  blackDiv: {
    background: "#2C2E30",
  },
  contentBox: {
    boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.15)",
    borderRadius: 1,
    position: "absolute",
    marginTop: -700,
    margin: "0px 16px",
    // padding: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: -500,
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: -400,
    },
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
  },
  blackContent: {
    background: "#2C2E30",
    padding: "100px 0px",
    // marginTop: 165,
    [theme.breakpoints.up("sm")]: {
      padding: "300px 20px 60px 20px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "350px 20px 60px 20px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "520px 20px 60px 20px",
    },
  },
}));

const Solutions = () => {
  const classes = useStyles();

  const btnText = (
    <>
      <PhoneAndroidIcon />
      &nbsp;&nbsp;
      <div>CONTACT US</div>
    </>
  );

  return (
    <div className={classes.root}>
      <SlideShow />

      <div className={classes.whiteDiv}></div>

      <Grid
        md={12}
        xs={12}
        sm={12}
        item
        container
        justifyContent="center"
        id="ftth"
      >
        <Grid md={10} item className={classes.contentBox}>
          <SolutionInfo
            solnObj={FTTHObj}
            realtedProduct={RelatedProductsHome}
          />
        </Grid>
      </Grid>

      <div className={classes.blackContent} id="real-estate">
        <SolutionInfo
          solnObj={RealEstateObj}
          dark
          realtedProduct={RelatedProductsRealEstate}
        />
      </div>
      <div className={classes.whiteDiv}></div>
      <Grid
        md={12}
        xs={12}
        sm={12}
        item
        container
        justifyContent="center"
        id="hospitality"
      >
        <Grid md={10} item className={classes.contentBox}>
          <SolutionInfo
            solnObj={HospitalityObj}
            realtedProduct={RelatedProductsHospitality}
          />
        </Grid>
      </Grid>

      <SubFooter
        heading="Reach out to us"
        subHeading="Build your network infrastructure with us"
        btnText={btnText}
        btnHref="/contact#form"
        extendedLong
      />
    </div>
  );
};

export default Solutions;
