import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch, connect } from "react-redux";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RHFTextField, PhoneInputField, RHFSelect } from "_Shared/FormComps";
import { OrangeBtn } from "_Shared/Buttons";
import { usePhoneLandScape, makeToast, useSmallPhone } from "_Utils/Helpers";
import { postContact } from "_Store/action/contactaction";
import { InputLabel, MenuItem } from "@material-ui/core";
import api from "../../../utils/Api";

const useStyles = makeStyles({
  root: {
    padding: 24,
  },
  inputPhone: {
    backgroundColor: "#FFF3EB",
    fontSize: 16,
    padding: 0,
    marginLeft: 15,
    width: "90%",
  },
});

const ContactForm = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const isPhone = usePhoneLandScape();
  const isMobile = useSmallPhone();
  /* If the user wants to know more about a product */
  const locState = JSON.parse(location.state);

  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [selected, setSelected] = useState("sales");
  const [params, setparams] = useState();
  const [paramsTitle, setParamsTitle] = useState();
  const Response = useSelector((state) => state.postContactReducer);

  useEffect(() => {
    let paramsId = new URLSearchParams(window.location.search).get("id");
    let paramsTitle = new URLSearchParams(window.location.search).get(
      "product"
    );
    setparams(paramsId);
    setParamsTitle(paramsTitle);
  }, []);

  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: { product_name: paramsTitle },
  });

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setPhone(formattedValue);
    setCountryCode(country?.dialCode);
  };

  const submitForm = async (data) => {
    if (phone === "" || countryCode === "") {
      makeToast("Please enter your phone number", false);
      return;
    }
    let contactObj = {
      ...data,
      countryCode,
      phone,
      Product_id: params,
      type: selected,
    };

    api
      .post("/contact/addContact", contactObj)
      .then((res) => {
        console.log(res);
        const response = res.data;
        if (response.success) {
          makeToast("We will connect with you soon!", true);

          reset(
            {},
            {
              keepDirty: true,
              keepIsSubmitted: true,
              keepTouched: true,
              keepIsValid: true,
              keepSubmitCount: true,
            }
          );
          setPhone("+91 ");
        }
        if (response.status == 422) {
          makeToast(
            response && response && response.data && response.data.message,
            false
          );
        }
      })
      .catch((err) => err.response);
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Grid
        container
        className={`${classes.root} glass-black`}
        spacing={isMobile ? 0 : 3}
        id="form"
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{ marginBottom: isPhone ? 24 : 32 }}
        >
          <Grid container direction="column">
            <div
              className={isPhone ? "text24 primary" : "text40 primary"}
              style={{ textAlign: "center" }}
            >
              Contact Us
            </div>
            <div
              className={isPhone ? "text12" : "text16"}
              style={{ textAlign: "center", color: "#FFFFFF" }}
            >
              Get in touch for your concerns and queries
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="fullName"
            label="Name"
            inputRef={register({
              required: {
                value: true,
                message: "Please fill this field",
              },
              minLength: {
                value: 2,
                message: "Please enter atleast 2 characters",
              },
            })}
            error={errors?.fullName ? true : false}
            helperText={errors?.fullName?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneInputField value={phone} onPhoneChange={handlePhoneChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="email"
            label="Email"
            inputRef={register({
              required: {
                value: true,
                message: "Please fill this field",
              },
              minLength: {
                value: 8,
                message: "Please enter atleast 8 characters",
              },
            })}
            error={errors?.email ? true : false}
            helperText={errors?.email?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="location"
            label="Location"
            inputRef={register({
              required: {
                value: true,
                message: "Please fill this field",
              },
              minLength: {
                value: 2,
                message: "Please enter atleast 2 characters",
              },
            })}
            error={errors?.location ? true : false}
            helperText={errors?.location?.message}
          />
        </Grid>
        {paramsTitle && (
          <Grid item xs={12} sm={6}>
            <RHFTextField
              name="product_name"
              disabled={true}
              // label="Product"
              value={paramsTitle}
              inputRef={register()}
              error={errors?.product_name ? true : false}
              helperText={errors?.product_name?.message}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="message"
            label="Message"
            inputRef={register({
              required: {
                value: true,
                message: "Please fill this field",
              },
              minLength: {
                value: 2,
                message: "Please enter atleast 2 characters",
              },
            })}
            error={errors?.message ? true : false}
            helperText={errors?.message?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFSelect
            value={selected}
            onChange={(event) => {
              setSelected(event.target.value);
            }}
            label="Select a category"
            disableUnderline
          >
            <MenuItem value="sales">Sales</MenuItem>
            <MenuItem value="support">Support</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </RHFSelect>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <OrangeBtn
            text="SUBMIT QUERY"
            width={isPhone ? 140 : 163}
            height={isPhone ? 33 : 40}
            btnType="submit"
          />
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = ({ postContactReducer }) => ({
  _contact: postContactReducer,
});

export default connect(mapStateToProps, { postContact })(ContactForm);
