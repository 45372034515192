import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import Participate from './components/Participate';
import Prize from './components/Prize';
import TnC from './components/TnC';
import HallofFame from './components/HallofFame';
import SubFooter from '_Shared/SubFooter';

const RewardProgram = () => {
    const btnText = (
        <>
            <PhoneAndroidIcon />
            &nbsp;&nbsp;
            <div>CONTACT US</div>
        </>
    );

    return (
        <>
            <Participate />
            <Prize />
            <TnC />
            <HallofFame />
            <SubFooter
                heading="Got Questions?"
                subHeading="Let us know in detail."
                btnText={btnText}
                btnHref="/contact#form"
            />
        </>
    );
};

export default RewardProgram;
