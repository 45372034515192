import Stockholm from "_Images/contact/stockholm.png";
import Gurgaon from "_Images/contact/gurgaon.png";
import Mumbai from "_Images/contact/mumbai.png";
import Manesar from "_Images/contact/manesar.jpg";
// import Dubai from "_Images/contact/dubai.jpg";
export const locationData = [
  {
    pic: Stockholm,
    title: "GX Sweden",
    address: "Stensätravägen 13, 127 39 Skärholmen, Stockholm, Sweden",
    contact: "+46 8437 37942 | info@gxgroup.eu",
    url: "https://goo.gl/maps/bGNXv8kcoyi9twHw9",
  },
  // {
  //   pic: Dubai,
  //   title: "GX Group AB",
  //   contact: "+46 8437 37942 | info@gxgroup.eu",
  //   comingSoon: "Coming Soon",
  // },
  {
    pic: Gurgaon,
    title: "GX Delhi",
    address:
      "401, 4th Floor, JMD Regent Plaza, M-G Road, Gurugram 122001, India",
    contact: "+91 124 422 4113 | info@gxgroup.eu",
    url: "https://g.page/gx-group?share",
  },
  {
    pic: Mumbai,
    title: "GX Mumbai",
    address:
      "413/14, Solus building, Hiranandani Estate, Thane west, Mumbai 400607",
    contact: "+91 22 496 812 91 | info@gxgroup.eu",
    url: "https://goo.gl/maps/cm1S5EMYLfoGidNNA",
  },
  {
    pic: Manesar,
    title: "GX Production Facility",
    address: "594/595, Sector 8 – IMT Manesar, Haryana - 122052",
    contact: "+91 22 496 812 91 | info@gxgroup.eu",
    url: "https://goo.gl/maps/3ERXxr188whaiMjM7",
  },
];
