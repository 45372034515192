import Image1 from "_Images/solutions/home.jpg";
import Image2 from "_Images/solutions/realState.jpg";
import Image3 from "_Images/solutions/reception.jpg";
import SpeedIcon from "_Icons/speed.svg";
import ConnectionIcon from "_Icons/connection.svg";
import HomeIcon from "_Icons/home.svg";
import SignalIcon from "_Icons/signal.svg";
import IntercomIcon from "_Icons/intercom.svg";
import MonitorIcon from "_Icons/monitor.svg";
import WifiIcon from "_Icons/wifi.svg";
import CctvIcon from "_Icons/cctv.svg";
import TvIcon from "_Icons/tv.svg";
import Home1 from "_Images/solutions/home1.png";
import Home2 from "_Images/solutions/hom2.png";
import Home3 from "_Images/solutions/hom3.png";
import Real1 from "_Images/solutions/real1.png";
import Real2 from "_Images/solutions/real2.png";
import Real3 from "_Images/solutions/real3.png";
import Hosp1 from "_Images/solutions/hosp1.png";
import Hosp2 from "_Images/solutions/hosp2.png";
import Hosp3 from "_Images/solutions/hosp3.png";
import AI from "_Images/software/A.I.svg";
import Automation from "_Images/software/Automation.svg";
import Easy from "_Images/software/easy.svg";
import Ftth from "_Images/software/ftth.svg";
import NextGen from "_Images/software/nextGen.svg";
import TR from "_Images/software/tr.svg";
export const FTTHObj = {
  title: "In-Home FTTH Setup",
  subTitle: "Our European design is the best fit for in-home gateway setups",
  coverImg: Image1,
  features: [
    { text: "Fast internet", icon: SpeedIcon, alt: "speed" },
    {
      text: "Multiple Gateway Connections",
      icon: ConnectionIcon,
      alt: "connection",
    },
    { text: "Smart Home Capability", icon: HomeIcon, alt: "home" },
    { text: "Greater Range & Coverage", icon: SignalIcon, alt: "signal" },
  ],
};

export const RealEstateObj = {
  title: "Real Estate Infrastructure",
  subTitle: "Delivering end to end Fiber solutions to commercial structures",
  coverImg: Image2,
  features: [
    { text: "Intercom & Security", icon: IntercomIcon, alt: "intercom" },
    {
      text: "Surveillance & monitoring",
      icon: MonitorIcon,
      alt: "monitor",
    },
    { text: "Greater Range & Coverage", icon: SignalIcon, alt: "signal" },
    { text: "Live TV / IP TV", icon: CctvIcon, alt: "cctv" },
  ],
};

export const HospitalityObj = {
  title: " Hospitality Solution",
  subTitle: "Converting legacy structures to modern day Internet technology",
  coverImg: Image3,
  features: [
    { text: "Guest WiFi Solution", icon: WifiIcon, alt: "wifi" },
    { text: "CCTV and Intercom", icon: TvIcon, alt: "speed" },
    { text: "IPTV Services", icon: CctvIcon, alt: "cctv" },
    { text: "Hi-Speed Coverage", icon: SpeedIcon, alt: "speed" },
  ],
};

export const RelatedProductsHome = [
  { alt: "GPON ONT", img: Home1, name: "Earth 1000R" },
  { alt: "GPON OLT", img: Home2, name: "Platinum 4410" },
  { alt: "XGPON", img: Home3, name: "Earth 2022" },
];

export const RelatedProductsRealEstate = [
  { alt: "GPON ONT", img: Real1, name: "Earth 4222" },
  { alt: "GPON OLT", img: Real2, name: "Earth P4200R" },
  { alt: "XGPON", img: Real3, name: "Saturn 16 port OLT" },
];

export const RelatedProductsHospitality = [
  { alt: "GPON ONT", img: Hosp1, name: "Titanium 2122A" },
  { alt: "GPON OLT", img: Hosp2, name: "Earth 4220RF" },
  { alt: "XGPON", img: Hosp3, name: "Mars OLT 8 port" },
];

export const DevNConsultList = [
  { text: "TR-369", img: TR },
  { text: "Easy Mesh", img: Easy },
  { text: "Next Generation Diagnostic", img: NextGen },
  { text: "FTTH Infra", img: Ftth },
  { text: "A.I.", img: AI },
  { text: "Automation Framework", img: Automation },
];
