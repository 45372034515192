import axios from "axios";

const api = axios.create({
  baseURL: "https://api.gxgroup.eu/api",
  // baseURL: "http://localhost:5000/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 10000,
});

export default api;
