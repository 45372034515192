import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { usePhoneLandScape } from "_Utils/Helpers";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "40px 0px 100px 0px",
  },
  ddCard: {
    boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.15)",
    borderRadius: 1,
    width: "100%",
    padding: 5,
  },
  listItem: {
    marginBottom: 16,
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      marginBottom: 24,
    },
  },
  gridItem: { marginBottom: 40 },
}));

const Dropdowns = ({
  listArr,
  staticData,
  productCategory,
  AllProducts,
  bandwidthPage,
}) => {
  const classes = useStyles();
  const isPhone = usePhoneLandScape();

  const ExpansionPanel = ({ obj }) => {
    const [expanded, setExpanded] = useState(obj.expanded);
    let dynamicData = "";

    if (expanded) {
      dynamicData =
        listArr &&
        listArr.downloadFiles &&
        listArr &&
        listArr.downloadFiles
          .filter((type) => obj.query === type.type)
          .map((obj) => {
            return obj;
          });
    }
    const onTestSaveFile = (obj) => {
      const doc = new jsPDF();

      doc.text(obj.docUrl, 10, 10);
      doc.save("a4.pdf");
    };
    return (
      <Accordion
        className={classes.ddCard}
        onChange={() => setExpanded(!expanded)}
        expanded={expanded}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div
            className={
              isPhone ? "text16 secondary mp-bold" : "text24 secondary  mp-bold"
            }
          >
            {obj.topic}
          </div>
        </AccordionSummary>

        <AccordionDetails className={classes.expanded}>
          <div className="flexColumn">
            {dynamicData &&
              dynamicData.map((st, idx) => (
                <div
                  className={isPhone ? `text14 primary` : `text16 primary`}
                  key={idx}
                  style={{ fontWeight: 400, marginBottom: 16 }}
                >
                  <a
                    href={st.docUrl}
                    download="d.pdf"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    className={isPhone ? `text14 primary` : `text16 primary`}
                  >
                    {st.title.toUpperCase()}
                  </a>
                </div>
              ))}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };
  const DynamicExpansionPanel = ({ obj }) => {
    const [expanded, setExpanded] = useState(obj.expanded);
    let dynamicData = "";

    if (expanded) {
      dynamicData =
        AllProducts &&
        AllProducts.allProduct &&
        AllProducts.allProduct
          .filter((type) => type.productCategory === obj?.topic?._id)
          .map((obj) => {
            return obj;
          });
    }

    return (
      <Accordion
        className={classes.ddCard}
        onChange={() => setExpanded(!expanded)}
        expanded={expanded}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div
            className={
              isPhone ? "text16 secondary mp-bold" : "text24 secondary  mp-bold"
            }
          >
            {obj?.topic?.title}
          </div>
        </AccordionSummary>

        <AccordionDetails className={classes.expanded}>
          <div className="flexColumn">
            {dynamicData &&
              dynamicData.map((st, idx) => (
                <div
                  className={isPhone ? `text14 primary` : `text16 primary`}
                  key={idx}
                  style={{ fontWeight: 400, marginBottom: 16 }}
                >
                  <a
                    href={st.dataSheet}
                    download
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    className={isPhone ? `text14 primary` : `text16 primary`}
                  >
                    {st.title.toUpperCase()}
                  </a>
                </div>
              ))}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Grid
      md={12}
      xs={12}
      sm={12}
      lg={12}
      item
      container
      className={classes.root}
      justifyContent="center"
      spacing={2}
    >
      <Grid md={1} item />
      <Grid md={10} xs={12} sm={12} lg={10} item container spacing={5}>
        {staticData.map((listItem, index) => (
          <Grid
            container
            justifyContent="center"
            item
            xs={12}
            sm={6}
            key={index}
            // className={classes.gridItem}
          >
            {!bandwidthPage ? (
              <div className="stretch">
                <AnimatedDiv
                  children={<ExpansionPanel obj={listItem} />}
                  animation="fade-up"
                />
              </div>
            ) : (
              ""
            )}
          </Grid>
        ))}

        {productCategory &&
          productCategory.map((listItem, index) => (
            <Grid
              container
              justifyContent="center"
              item
              xs={12}
              sm={6}
              key={index}
              // className={classes.gridItem}
            >
              <div className="stretch">
                <AnimatedDiv
                  children={<DynamicExpansionPanel obj={listItem} />}
                  animation="fade-up"
                />
              </div>
            </Grid>
          ))}
      </Grid>
      <Grid md={1} item />
    </Grid>
  );
};

export default Dropdowns;
