import { useState, useEffect } from "react";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { usePhoneLandScape, usePhablet, useSmallPhone } from "_Utils/Helpers";
import { getAllVacancy } from "_Store/action/careeraction";
import { BlackBtn } from "_Shared/Buttons";
import AnimatedDiv from "_Shared/AnimatedDiv";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    [theme.breakpoints.up("md")]: {
      marginTop: 50,
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 100px",
    },
  },
  card: {
    margin: "0px 20px 25px 20px",
    padding: 24,
    [theme.breakpoints.up("lg")]: {
      margin: "0px 15px 40px 15px",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "0px 20px 40px 20px",
      padding: 32,
    },
  },
}));

const VacancyCard = (props) => {
  const classes = useStyles();
  const isPhablet = usePhablet();
  const isPhone = usePhoneLandScape();
  const isSmallPhone = useSmallPhone();

  const [open, setOpen] = useState(false);
  const [file, setFile] = useState("");

  useEffect(() => {
    props.getAllVacancy();
  }, []);

  const { _vacancy } = props;

  const handleOpenPdf = (url) => {
    setOpen(true);
    setFile(url);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBtnClick = (job_name) => {
    props.setJobValue(job_name);
    var elmnt = document.getElementById("form");
    elmnt.scrollIntoView();
  };

  return (
    <Grid container className={classes.root} justifyContent="center">
      {_vacancy &&
        _vacancy.Jobs &&
        _vacancy.Jobs.map((obj, index) => (
          <Grid item key={index} xs={12} sm={6}>
            <AnimatedDiv
              children={
                <Card className={classes.card}>
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="space-between"
                    style={{
                      display: "flex",
                      marginBottom: 4,
                    }}
                  >
                    <div
                      className={
                        isPhone
                          ? "text16 secondary mp-bold"
                          : "text24 secondary mp-bold"
                      }
                    >
                      <b>{obj.Jobtitle}</b>
                    </div>
                    {!isPhablet && (
                      <div>
                        <BlackBtn
                          text="APPLY NOW"
                          width={140}
                          height={40}
                          padding={"8px 16px"}
                          outlined={true}
                          onBtnClick={() => handleBtnClick(obj._id)}
                        />
                      </div>
                    )}
                  </Grid>
                  <div
                    className={
                      isPhone
                        ? "text12 nuetralGrey mp-semi"
                        : "text14 nuetralGrey mp-semi"
                    }
                  >
                    {`${obj.city},${obj.country}`}
                  </div>
                  <div
                    className={isPhone ? "text12 darkGrey" : "text16 darkGrey"}
                    style={{ marginTop: 15 }}
                  >
                    {obj.shortDescription}
                  </div>
                  <span>
                    <div
                      className={
                        isSmallPhone ? "flexColumn" : "flexRow stretch"
                      }
                      style={{
                        marginTop: 20,
                      }}
                    >
                      <div
                        className={
                          isPhone
                            ? "text14 primary link"
                            : "text16 primary centerDiv link"
                        }
                        onClick={() => handleOpenPdf(obj.JobDesc)}
                      >
                        <b>
                          {isPhablet
                            ? "JOB DESCRIPTION"
                            : "VIEW JOB DESCRIPTION"}
                        </b>
                      </div>
                      {isPhablet && (
                        <div
                          style={{
                            marginTop: isSmallPhone ? 20 : 0,
                          }}
                        >
                          <BlackBtn
                            text="APPLY NOW"
                            width={133}
                            height={33}
                            padding="6px 16px"
                            outlined={true}
                            onBtnClick={() => handleBtnClick(obj._id)}
                          />
                        </div>
                      )}
                    </div>
                    <Dialog open={open} onClose={() => handleClose()}>
                      <object
                        data={file}
                        type="application/pdf"
                        width="500px"
                        height="800px"
                      />
                    </Dialog>
                  </span>
                </Card>
              }
              animation="fade-up"
            />
          </Grid>
        ))}
    </Grid>
  );
};

const mapStateToProps = ({ getAllVacancyReducer }) => {
  return { _vacancy: getAllVacancyReducer };
};

export default connect(mapStateToProps, { getAllVacancy })(VacancyCard);
