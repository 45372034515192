import { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AnimatedDiv from "_Shared/AnimatedDiv";
import GlassBgCard from "_Shared/GlassBgCard";
import BannerImage from "_Images/about/banner.jpg";
import { usePhoneLandScape } from "_Utils/Helpers";
const useStyles = makeStyles((theme) => ({
  bannerImg: {
    width: "100%",
    height: "100%",
    objectFit: "Cover",

    [theme.breakpoints.up("1200px")]: {
      height: "820px",
    },
  },

  content: {
    position: "absolute",
    bottom: 70,
    left: 20,
    right: 20,
    [theme.breakpoints.up("sm")]: {
      left: 40,
      right: 40,
      width: 425,
    },
    [theme.breakpoints.up("md")]: {
      left: 60,
      right: 60,
      width: 540,
    },
    [theme.breakpoints.up("lg")]: {
      left: 50,
      width: 678,
    },
    [theme.breakpoints.up("xl")]: {
      left: 148,
    },
    [theme.breakpoints.down("xs")]: {
      bottom: 48,
    },
  },
}));

const AboutBanner = () => {
  const classes = useStyles();
  const [content, showContent] = useState(false);

  setTimeout(() => showContent(true), 3000);
  const isPhone = usePhoneLandScape();
  const GlassDiv = (
    <GlassBgCard
      content={
        <>
          <div
            className={isPhone ? "text24 primary" : "text56 primary"}
            style={{ fontWeight: 700, marginBottom: 24 }}
          >
            About Us
          </div>
          <b>GX Group</b> is a European market leading Fiber-to-the-Home (FTTH)
          company with 20 years experience, focused on Manufacturing and
          Development of FTTH products. Quality and innovation is in our focus
          where we invest major part in our R&amp;D team developing next
          generation PON (GPON/XGPON/XGSPON) solutions. Our motto is to build
          advanced Fiber products which can sustain the oncoming future demands
          for a Digital world
        </>
      }
      padding_xs="72px 24px 40px 24px"
      padding_md="100px 56px 56px 56px"
      padding_lg="90px 56px 56px 56px"
    />
  );

  return (
    <div className={"zoomImg"}>
      <img src={BannerImage} alt="cover-img" className={classes.bannerImg} />
      {content && (
        <div className={classes.content}>
          <AnimatedDiv
            children={GlassDiv}
            animation="fade-right"
            delay={5000}
          />
        </div>
      )}
    </div>
  );
};

export default AboutBanner;
