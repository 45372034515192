import { put, call } from "redux-saga/effects";
import { getDownloadService } from "../service/download.service";
import { getDownloadSuccess } from "../action/download";
import * as types from "../action.type";

export function* getDownloadSaga(data) {
  const response = yield call(getDownloadService, data);
  if (response && !response.error) {
    yield put(getDownloadSuccess(response.data));
  } else {
    yield put({ type: types.DOWNLOAD_ERROR, response });
  }
}
