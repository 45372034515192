import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { usePhoneLandScape, GenerateIcon } from "_Utils/Helpers";
import { RewardPerks } from "_Constants/Rewards";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    background: "#FFFFFF",
    padding: "24px 24px 24px 24px",
    boxShadow: "0px 1px 16px rgba(0, 0, 0, 0.15)",
    borderRadius: 1,
  },
  headingDiv: {
    paddingBottom: 32,
    marginLeft: "24px",
    [theme.breakpoints.up("sm")]: { paddingBottom: 48 },
  },
  listItem: {
    padding: 5,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 8,
    },
  },
  iconDiv: {
    textAlign: "center",
    marginBottom: 15,
    [theme.breakpoints.up("md")]: {
      marginBottom: 20,
    },
  },
  rewardIcon: {
    width: 75,
    height: 75,
    [theme.breakpoints.up("md")]: {
      width: 85,
      height: 85,
    },
    [theme.breakpoints.up("xl")]: {
      width: 96,
      height: 96,
    },
  },
}));

const Prize = () => {
  const classes = useStyles();
  const isPhone = usePhoneLandScape();

  return (
    <Grid container justifyContent="center">
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={10}
        lg={10}
        justifyContent="center"
        className={classes.headingDiv}
      >
        <AnimatedDiv
          children={
            <span className={isPhone ? "text24 secondary" : "text40 secondary"}>
              What do you get?
            </span>
          }
          animation="fade-up"
        />
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Grid
          container
          item
          xs={11}
          sm={10}
          xl={9}
          spacing={2}
          className={classes.root}
        >
          {RewardPerks.map((perk, index) => (
            <Grid item xs={12} sm={4} className={classes.listItem} key={index}>
              <AnimatedDiv
                children={
                  <div>
                    <div className={classes.iconDiv}>
                      <GenerateIcon
                        iconPath={perk.icon}
                        alt={perk.alt}
                        className={classes.rewardIcon}
                      />
                    </div>
                    <div
                      className={
                        isPhone
                          ? "text16 secondary textCenter mp-bold"
                          : "text20 secondary textCenter mp-bold"
                      }
                      style={{ marginBottom: 8 }}
                    >
                      {perk.heading}
                    </div>
                    <div
                      className={
                        isPhone
                          ? "text12 darkGrey textCenter"
                          : "text16 darkGrey textCenter"
                      }
                    >
                      {perk.title}
                    </div>
                  </div>
                }
                animation="fade-up"
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Prize;
