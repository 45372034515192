import { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { usePhablet, usePhoneLandScape } from "_Utils/Helpers";

const useStyles = makeStyles((theme) => ({
  headingDiv: {
    paddingBottom: 32,
    paddingTop: 130,
    [theme.breakpoints.up("sm")]: { paddingBottom: 48 },
    [theme.breakpoints.down("md")]: { paddingTop: 0 },
  },
  articleDiv: {
    display: "flex",
    background: "#FFFFFF",
    flexDirection: "column",
    boxShadow: "0px 1px 16px rgba(0, 0, 0, 0.15)",
    borderRadius: 1,
    zIndex: 300,
    position: "absolute",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  card: {
    padding: 0,
  },
  blogImg: {
    width: "100%",
    [theme.breakpoints.up("xl")]: {
      height: 368,
    },
  },
  content: {
    padding: "15px 24px 40px 24px",
    [theme.breakpoints.up("md")]: {
      padding: "45px 24px 45px 24px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "45px 24px 45px 24px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "45px 48px 45px 48px",
    },
  },
}));

const Blog = () => {
  const classes = useStyles();
  const isPhablet = usePhablet();
  const isPhone = usePhoneLandScape();
  const [post, setPost] = useState({});
  const feedURL = "https://medium.com/feed/@gx-group";
  const mediumURL = `https://api.rss2json.com/v1/api.json?rss_url=${feedURL}`;

  useEffect(() => {
    axios.get(mediumURL).then((res) => {
      const len = res?.data?.items?.length;
      setPost(res?.data?.items[len - 1]);
      // feed.link for user profile
    });
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} className={`${classes.headingDiv} centerDiv`}>
        {/* <AnimatedDiv
          children={
           
          }
          animation="fade-up"
        /> */}
        <div className={isPhone ? "text32" : "text56"}>Our Blog</div>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Grid item xs={11} sm={10} lg={8}>
          <AnimatedDiv
            children={
              <Grid md={12} xs={12} sm={12} item className={classes.articleDiv}>
                <Grid md={5} xs={12} sm={12} item className={classes.blogImg}>
                  <img
                    src={post?.thumbnail}
                    alt="latest-post-img"
                    height="100%"
                    width="100%"
                  />
                </Grid>

                <Grid md={8} item className={classes.content}>
                  <div className="flexColumn">
                    <div
                      className={
                        isPhablet ? "text20 secondary" : "text32 secondary"
                      }
                      style={{ marginBottom: 16 }}
                    >
                      <b>{post?.title}</b>
                    </div>
                    <div
                      className={
                        isPhablet ? "text14 secondary" : "text16 secondary"
                      }
                    >
                      <div
                      // style={{
                      //   whiteSpace: "nowrap",
                      //   width: "500px",
                      //   overflow: "hidden",
                      //   textOverflow: "clip",
                      //   border: "1px solid #000000",
                      // }}
                      // dangerouslySetInnerHTML={{
                      //   __html: post?.description,
                      // }}
                      ></div>

                      {/* {post?.content} */}
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: isPhablet ? 8 : 16,
                    }}
                  >
                    <a
                      className="link primary"
                      href={post?.link}
                      target="_blank"
                    >
                      READ BLOG
                    </a>
                  </div>
                </Grid>
              </Grid>
            }
            animation="fade-up"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Blog;
