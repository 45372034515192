import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import VacancyCard from "./VacancyCard";
import { usePhoneLandScape } from "_Utils/Helpers";
import AnimatedDiv from "_Shared/AnimatedDiv";
import JobForm from "./JobForm";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "50px 0px 0px 0px",
    background: "linear-gradient(104.04deg, #FFFFFF 0%, #FFF3EB 100%)",
    display: "block",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      padding: "100px 0px 0px 0px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "100px 0px 0px 0px",
    },
  },
  texts: { padding: "0 40px" },
  headerDiv: { marginTop: 100 },
  rightDiv: {
    position: "relative",
    top: 80,
    [theme.breakpoints.up("sm")]: {
      top: 100,
    },
    [theme.breakpoints.up("md")]: {
      top: 120,
    },
  },
  blackDiv: {
    background: "#2C2E30",
    marginTop: -150,
    height: 484,
    [theme.breakpoints.up("sm")]: {
      height: 400,
    },
  },
}));

const JoinUs = () => {
  const isPhone = usePhoneLandScape();
  const classes = useStyles();
  const [jobSelected, setJobSelected] = useState("");

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.texts}>
        {/* <AnimatedDiv
          children={ */}
        <div className="textCenter">
          <div className={isPhone ? "text32" : "text56"}>Come Join Us</div>
          <div className={isPhone ? "text16 darkGrey" : "text20 darkGrey"}>
            Find your best opportunity today
          </div>
        </div>
        {/* } */}
        {/* // animation="fade-up" */}
        {/* /> */}
      </Grid>
      <Grid item xs={12}>
        <VacancyCard setJobValue={(jobName) => setJobSelected(jobName)} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        className={classes.rightDiv}
        justifyContent="center"
      >
        <Grid item xs={10} md={9} lg={8}>
          <JobForm jobName={jobSelected} />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.blackDiv}></Grid>
    </Grid>
  );
};

export default JoinUs;
