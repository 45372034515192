import { put, call } from 'redux-saga/effects';
import { postcontactService } from '../service/contact.js';
import { postContactSuccess } from '../action/contactaction';
import * as types from '../action.type';

function* postContactSaga(data) {
    const response = yield call(postcontactService, data);
    if (response && !response.error) {
        yield put({ type: types.POST_CONTACT_SUCCESS, response });
    } else {
        yield put({ postContactSuccess, response });
    }
}

export { postContactSaga };
