import * as types from '../action.type';

//get News
export const getNews = (data) => {
    return {
        type: types.GET_NEWS_EVENTS,
        data,
    };
};
export const getNewsSuccess = (data) => {
    return {
        type: types.GET_NEWS_EVENTS_SUCCESS,
        data,
    };
};

export const getNewsError = (data) => {
    return {
        type: types.GET_NEWS_EVENTS_ERROR,
        data,
    };
};
