import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { OrangeBtn } from "_Shared/Buttons";
import { usePhablet } from "_Utils/Helpers";
import { get_all_product_Action } from "_Store/action/productAction";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "24px 24px 32px 24px",
    // height: "100vh",
    height: "100%",

    [theme.breakpoints.up("lg")]: {
      padding: "20px 24px 20px 24px",
      width: "100%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "100%",
    },
  },
  heading: {
    fontSize: 32,
    color: "#FD6A02",
    fontWeight: 700,
  },
  prodImgsDiv: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 40,
    [theme.breakpoints.up("sm")]: {
      width: 450,
    },
    [theme.breakpoints.up("lg")]: {
      flexWrap: "wrap",
    },
  },
  prodDiv: {
    marginBottom: 24,
    padding: 16,
    position: "relative",
    left: 3,
    [theme.breakpoints.down("md")]: {
      marginTop: 8,
    },
  },
  imgDiv: {
    width: 100,
    height: 100,
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  prodDesc: {
    marginTop: 24,
    paddingLeft: 32,
  },
  title: {
    textAlign: "center",
    color: "#fff",
    paddingTop: 8,
  },
  subcategory: {
    width: "100%",
    // border: "1px solid #FFF3EB",
    borderRadius: 16,
    textTransform: "none",
    fontSize: 14,
    // marginRight: 16,
    height: 34,
    width: 110,
    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
      height: 24,
      width: "max-content",
    },
    "&:hover": {
      background: "#ffa86b !important",
      color: "#000 !important",
      fontWeight: "bold",
    },
  },
  hoverData: {
    position: "absolute",
    top: 0,
    right: 0,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  textCenter: {
    textAlign: "initial",
    [theme.breakpoints.down("md")]: {
      textAlign: "end",
    },
  },
  paddingTop: {
    color: "#2C2E30",
    paddingTop: 4,
  },
  titleGrid: {
    textAlign: "initial",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  subtitle: {
    fontSize: 16,
    margin: "8px 0px 16px 0px",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
  subcatGrid: {
    marginTop: 16,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
}));

const ProductList = ({
  title,
  subtitle,
  heading,
  idVal,
  textColor,
  descriptionColor,
  isEven,
  subCategory,
}) => {
  const AllProducts = useSelector((state) => state.get_ALL_ProductReducer);
  let data = AllProducts && AllProducts.allProduct;

  const filterValue = data
    ? data && data.filter((item) => item.productCategory === title)
    : null;

  const [filterDta, setFilterDta] = useState();
  const [subCat, setsubCat] = useState();
  const [hover, sethover] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();

  const isPhablet = usePhablet();
  const dispatch = useDispatch();
  // const isPhone = usePhoneLandScape();

  useEffect(() => {
    dispatch(get_all_product_Action());

    if (window.location.hash.substring(1) === idVal) {
      const element = document.getElementById(idVal);
      element.scrollIntoView();
    }
  }, []);

  const handledetail = (id, hover) => {
    sethoverId(id);
    sethover(hover);
    /* for smaller devices, directly view the product info on item click */
    if (isPhablet) {
      navigate(`/product-detail?id=${id}`);
    } else if (!isPhablet) {
      let filterData = data && data.filter((item) => item._id === id);
      setFilterDta(filterData);
    }
  };

  let initialVal = filterValue && filterValue[0];
  let initialState = initialVal && initialVal._id;

  const [hoverId, sethoverId] = useState();

  // let productSubCategory = data?.map((obj, i) => {
  //   return obj.productSubCategory;
  // });
  let uniqueSubCat = [
    ...new Set(
      subCategory?.map((item) => {
        return item;
      })
    ),
  ];
  const handleSubcategory = (subCat) => {
    setsubCat(subCat);
  };

  return (
    <section id={idVal} style={{ marginTop: 50 }}>
      <Grid lg={12} xs={12} md={12} sm={12} direction="row" item container>
        <Grid
          md={12}
          xs={12}
          sm={12}
          lg={12}
          item
          container
          justifyContent="flex-start"
        >
          <Grid lg={1} item />
          <Grid
            lg={11}
            xs={12}
            md={12}
            sm={12}
            justifyContent="flex-start"
            container
            item
          >
            <Grid
              md={12}
              xs={12}
              sm={12}
              lg={12}
              item
              container
              justifyContent="center"
              direction="column"
              className={classes.titleGrid}
            >
              <span className={classes.heading}>{heading}</span>

              <span
                className={classes.subtitle}
                style={{ color: !isEven ? "#FFF3EB" : "#2C2E30" }}
              >
                {subtitle}
              </span>
            </Grid>
            <Grid
              md={12}
              xs={12}
              sm={12}
              lg={12}
              item
              container
              direction="row"
              spacing={1}
              className={classes.subcatGrid}
            >
              {uniqueSubCat?.map((obj, i) =>
                obj === undefined || obj === "undefined" ? (
                  ""
                ) : (
                  <Grid key={i} style={{ padding: "0px 12px 0px 0px" }}>
                    {obj ? (
                      <Button
                        onClick={() => handleSubcategory(obj)}
                        style={{
                          background:
                            obj === subCat && isEven
                              ? "#030201"
                              : obj !== subCat && isEven
                              ? "#FFF3EB"
                              : obj === subCat && !isEven
                              ? "#FFFFFF"
                              : obj !== subCat && isEven
                              ? "#61686C"
                              : "#61686C",
                          color:
                            obj === subCat && isEven
                              ? "#fff"
                              : obj !== subCat && isEven
                              ? "#61686C"
                              : obj === subCat && !isEven
                              ? "#FD6A02"
                              : obj !== subCat && isEven
                              ? "#ffff"
                              : "#FFF3EB",
                          fontWeight:
                            obj === subCat
                              ? 700
                              : obj === subCat || !isEven
                              ? 700
                              : obj === subCat && isEven
                              ? 700
                              : 500,
                        }}
                        className={classes.subcategory}
                      >
                        {obj}
                      </Button>
                    ) : (
                      ""
                    )}
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid lg={1} item />
        <Grid
          container
          item
          xs={12}
          lg={6}
          direction="row"
          justifyContent="flex-start"
          className={classes.prodImgsDiv}
        >
          {subCat
            ? data &&
              data
                .filter(
                  (item) =>
                    item.productCategory === title &&
                    item.productSubCategory === subCat
                )
                .map((obj, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    lg={3}
                    key={index}
                    className={classes.prodDiv}
                    style={{
                      cursor: "pointer",
                      boxShadow:
                        hover && hoverId === obj._id
                          ? "0px 0px 8px rgba(0, 0, 0, 14%)"
                          : initialState === obj._id && !hover
                          ? "0px 0px 8px rgba(0, 0, 0, 14%)"
                          : "",
                      background:
                        hover && hoverId === obj._id && isEven
                          ? "#fff"
                          : hover && hoverId === obj._id && !isEven
                          ? "#61686C"
                          : initialState === obj._id && !hover && isEven
                          ? "#fff"
                          : initialState === obj._id && !hover && !isEven
                          ? "#61686C"
                          : "",
                      height: "161px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      onClick={() => handledetail(obj._id, true)}
                      className="centerDiv"
                    >
                      <div className={classes.imgDiv}>
                        <img
                          src={obj.featuredImage}
                          alt="featuredImage"
                          className={classes.img}
                        />
                      </div>
                    </div>

                    <div
                      className={classes.title}
                      style={{
                        fontSize:
                          hover && hoverId === obj._id && !isPhablet
                            ? 16
                            : initialState === obj._id && !hover && !isPhablet
                            ? 16
                            : isPhablet
                            ? 14
                            : 16,
                        cursor: "pointer",
                        color: textColor,
                        fontWeight:
                          hover && hoverId === obj._id && !isPhablet
                            ? 700
                            : initialState === obj._id && !hover && !isPhablet
                            ? 700
                            : isPhablet
                            ? 700
                            : 500,
                      }}
                    >
                      {obj.title}
                    </div>
                  </Grid>
                ))
            : data &&
              data
                .filter((item) => item.productCategory === title)
                .map((obj, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    lg={3}
                    key={index}
                    className={classes.prodDiv}
                    style={{
                      boxShadow:
                        hover && hoverId === obj._id && !isPhablet
                          ? "0px 0px 8px rgba(0, 0, 0, 14%)"
                          : initialState === obj._id && !hover && !isPhablet
                          ? "0px 0px 8px rgba(0, 0, 0, 14%)"
                          : "",
                      background:
                        hover && hoverId === obj._id && isEven && !isPhablet
                          ? "#fff"
                          : hover &&
                            hoverId === obj._id &&
                            !isEven &&
                            !isPhablet
                          ? "#61686C"
                          : initialState === obj._id &&
                            !hover &&
                            isEven &&
                            !isPhablet
                          ? "#fff"
                          : initialState === obj._id &&
                            !hover &&
                            !isEven &&
                            !isPhablet
                          ? "#61686C"
                          : "",
                      height: "161px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      onClick={() => handledetail(obj._id, true)}
                      // onMouseEnter={() => handledetail(obj._id, true)}
                      className="centerDiv"
                    >
                      <div className={classes.imgDiv}>
                        <img
                          src={obj.featuredImage}
                          alt="featuredImage"
                          className={classes.img}
                        />
                      </div>
                    </div>

                    <div
                      className={classes.title}
                      style={{
                        fontSize:
                          hover && hoverId === obj._id && !isPhablet
                            ? 16
                            : initialState === obj._id && !hover && !isPhablet
                            ? 16
                            : isPhablet
                            ? 14
                            : 16,
                        cursor: "pointer",
                        color: textColor,
                        fontWeight:
                          hover && hoverId === obj._id && !isPhablet
                            ? 700
                            : initialState === obj._id && !hover && !isPhablet
                            ? 700
                            : isPhablet
                            ? 700
                            : 500,
                      }}
                    >
                      {obj.title}
                    </div>
                  </Grid>
                ))}
        </Grid>
        <Grid lg={1} item />
        {initialVal ? (
          <Grid container item xs={12} lg={4} className={classes.hoverData}>
            {!hover && initialVal !== undefined && !isPhablet ? (
              <Grid
                md={12}
                xs={12}
                sm={12}
                lg={12}
                item
                container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid
                  lg={12}
                  xs={12}
                  sm={12}
                  item
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid xs={12} md={12} lg={12} item style={{ height: 300 }}>
                    <img
                      src={initialVal && initialVal.featuredImage}
                      alt="pic"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} className={classes.prodDesc}>
                    <div
                      className="text24 white"
                      style={{ color: descriptionColor }}
                    >
                      <b>{initialVal && initialVal.title}</b>
                    </div>
                    <div
                      className="text16 lightGrey"
                      style={{ marginTop: "8px", color: descriptionColor }}
                    >
                      {initialVal && initialVal.shortDesc}
                    </div>
                    <div style={{ marginTop: 24 }}>
                      <OrangeBtn
                        text="VIEW DETAILS"
                        width={154}
                        height={40}
                        padding="8px 24px"
                        btnHref={`/product-detail?id=${
                          initialVal && initialVal._id
                        }&category=${heading}`}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              filterDta &&
              filterDta.map((obj, index) => (
                <Grid
                  md={12}
                  xs={12}
                  sm={12}
                  lg={12}
                  item
                  container
                  key={index}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className={classes.hoverData}
                >
                  <Grid
                    lg={12}
                    xs={12}
                    sm={12}
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid xs={12} md={12} lg={12} item style={{ height: 300 }}>
                      <img
                        src={obj.featuredImage}
                        alt="pic"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Grid>
                    <Grid item lg={12} className={classes.prodDesc}>
                      <div
                        className="text24 white"
                        style={{ color: descriptionColor }}
                      >
                        <b>{obj.title}</b>
                      </div>
                      <div
                        className="text16 lightGrey"
                        style={{ marginTop: "8px", color: descriptionColor }}
                      >
                        {obj.shortDesc}
                      </div>
                      <div style={{ marginTop: 24 }}>
                        <OrangeBtn
                          text="VIEW DETAILS"
                          width={154}
                          height={40}
                          padding="8px 24px"
                          btnHref={`/product-detail?id=${
                            obj && obj._id
                          }&category=${heading}`}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            )}
          </Grid>
        ) : null}
        <Grid lg={1} item />
      </Grid>
    </section>
  );
};

export default ProductList;
