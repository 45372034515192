import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { RHFTextField, PhoneInputField } from "_Shared/FormComps";
import { OrangeBtn } from "_Shared/Buttons";
import { usePhoneLandScape, makeToast } from "_Utils/Helpers";
import { getAllVacancy, JobApply } from "_Store/action/careeraction";

const useStyles = makeStyles({
  root: {
    boxShadow: "0px 1px 16px rgba(0, 0, 0, 0.15)",
    borderRadius: 1,
    padding: "50px 24px",
    background: "#FFFFFF",
  },
  inputPhone: {
    backgroundColor: "#FFF3EB",
    fontSize: 16,
    padding: 0,
    marginLeft: 15,
    width: "90%",
  },
  jobOps: {
    padding: "10px 10px 10px 10px",
    fontSize: 14,
    cursor: "pointer",
  },
  inputRoot: {
    backgroundColor: "#FFF3EB",
    borderRadius: 8,
    ["&:hover"]: {
      backgroundColor: "#FFF3EB",
    },
  },
  formControl: {
    width: "100%",
  },
});

const JobForm = (props) => {
  const isPhone = usePhoneLandScape();
  const classes = useStyles();

  const [phone, setPhone] = useState("");
  const [resumeFile, setResumeFile] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const { _vacancy, jobName } = props;

  const { register, handleSubmit, errors, setValue, control, watch, reset } =
    useForm();

  let jobVal = watch("jobProfile");

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setPhone(formattedValue);
    setCountryCode(country?.dialCode);
  };

  useEffect(() => {
    props.getAllVacancy();
    setValue("jobProfile", jobName);
  }, [jobName]);

  const submitForm = (data) => {
    if (phone === "" || countryCode === "") {
      makeToast("Please enter your phone number", false);
      return;
    }

    let jobObj = {
      ...data,
      resumeUrl: data.resumeUrl[0],
      code: countryCode,
      phone,
    };

    if (data?.User_Choice) {
      jobObj = { ...jobObj, others: "others" };
    }
    props.JobApply(jobObj);

    if (props && props._applyNow && props._applyNow.success) {
      makeToast(props && props._applyNow && props._applyNow.message, true);
      reset(
        {},
        {
          keepDirty: true,
          keepIsSubmitted: true,
          keepTouched: true,
          keepIsValid: true,
          keepSubmitCount: true,
        }
      );
    }
    if (props && props._applyNow && props._applyNow.status == 422) {
      makeToast(props && props._applyNow && props._applyNow.message, false);
    }
  };

  return (
    <form id="form" onSubmit={handleSubmit(submitForm)}>
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        item
        container
        className={classes.root}
      >
        <Grid lg={1} item />
        <Grid lg={10} md={12} xs={12} sm={12} item>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            style={{ marginBottom: isPhone ? 24 : 32 }}
          >
            <div className={isPhone ? "text24 primary" : "text40 primary"}>
              Apply Now
            </div>
          </Grid>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="jobProfile"
                type="select"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ onChange, onBlur, value }) => (
                  <Select
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    fullWidth
                    variant="filled"
                    placeholder="Applying for..."
                    inputProps={{
                      classes: {
                        root: classes.inputRoot,
                      },
                    }}
                  >
                    <option
                      aria-label="None"
                      value=""
                      className={classes.jobOps}
                      disabled
                      style={{ opacity: 0.5 }}
                    >
                      ---- Select Job Profile ----
                    </option>
                    {_vacancy &&
                      _vacancy?.Jobs?.map((obj, index) => (
                        <option
                          value={obj._id}
                          key={index}
                          className={classes.jobOps}
                        >
                          {obj.Jobtitle}
                        </option>
                      ))}
                    <option value="others" className={classes.jobOps}>
                      Others
                    </option>
                  </Select>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <RHFTextField
                name="User_Choice"
                disabled={jobVal === "others" ? false : true}
                label="Applying for..."
                inputRef={register({
                  minLength: {
                    value: 2,
                    message: "Please enter atleast 2 characters",
                  },
                })}
                error={errors?.User_Choice ? true : false}
                helperText={errors?.User_Choice?.message}
                style={{ marginBottom: 4 }}
              />
              <Grid
                container
                justifyContent="flex-end"
                style={{ fontSize: 10, color: "#61686C" }}
              >
                In case you selected{" "}
                <span style={{ margin: "0px 4px", fontWeight: 700 }}>
                  others
                </span>{" "}
                in job role
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={6}>
              <RHFTextField
                name="name"
                label="Name"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Please fill this field",
                  },
                  minLength: {
                    value: 2,
                    message: "Please enter atleast 2 characters",
                  },
                })}
                error={errors?.name ? true : false}
                helperText={errors?.name?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFTextField
                name="email"
                label="Email"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Please fill this field",
                  },
                  minLength: {
                    value: 2,
                    message: "Please enter atleast 2 characters",
                  },
                })}
                error={errors?.email ? true : false}
                helperText={errors?.email?.message}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={6}>
              <PhoneInputField onPhoneChange={handlePhoneChange} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFTextField
                name="Experience"
                label="Experience"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Please fill this field",
                  },
                  minLength: {
                    value: 1,
                    message: "Please enter atleast 1 characters",
                  },
                })}
                error={errors?.Experience ? true : false}
                helperText={errors?.Experience?.message}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={6}>
              <RHFTextField
                name="highestQualification"
                label="Highest Qualification"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Please fill this field",
                  },
                  minLength: {
                    value: 2,
                    message: "Please enter atleast 2 characters",
                  },
                })}
                error={errors?.highestQualification ? true : false}
                helperText={errors?.highestQualification?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFTextField
                name="linkedInProfile"
                label="Linkedin Profile"
                inputRef={register({
                  required: {
                    value: true,
                    message: "Please fill this field",
                  },
                  minLength: {
                    value: 2,
                    message: "Please enter atleast 2 characters",
                  },
                })}
                error={errors?.linkedInProfile ? true : false}
                helperText={errors?.linkedInProfile?.message}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={6}>
              <RHFTextField
                label="Resume"
                value={resumeFile}
                InputProps={{
                  classes: {
                    root: classes.inputRoot,
                  },
                  disableUnderline: true,
                  endAdornment: (
                    <IconButton variant="contained" component="label">
                      <div className="text16 primary">Upload</div>
                      <input
                        type="file"
                        name="resumeUrl"
                        accept=".pdf"
                        multiple={false}
                        style={{ display: "none" }}
                        ref={register({
                          required: {
                            value: true,
                            message: "Please upload only pdf or docx file",
                          },
                        })}
                        onChange={(e) =>
                          setResumeFile(e.target?.files[0]?.name)
                        }
                      />
                    </IconButton>
                  ),
                  readOnly: true,
                }}
                error={errors?.resumeUrl ? true : false}
                helperText={errors?.resumeUrl?.message}
                style={{
                  background: "#FFF3EB",
                  borderRadius: 8,
                  marginBottom: 4,
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
              <Grid
                container
                justifyContent="flex-end"
                style={{ fontSize: 10, color: "#61686C" }}
              >
                uploaded only pdf or docx files
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={6}
              justifyContent="center"
              alignItems="center"
            >
              <OrangeBtn
                text="SUBMIT APPLICATION"
                width={isPhone ? 174 : 211}
                height={isPhone ? 33 : 40}
                btnType="submit"
              />
            </Grid>
          </Grid>
          <Grid lg={1} item />
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = ({ JobApplyReducer, getAllVacancyReducer }) => {
  return { _applyNow: JobApplyReducer, _vacancy: getAllVacancyReducer };
};
export default connect(mapStateToProps, { JobApply, getAllVacancy })(JobForm);
