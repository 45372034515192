import { put, call } from 'redux-saga/effects';
import { getAllVacancy, JobApplyService } from '../service/career';
import { getAllVacancySuccess, JobApplySuccess } from '../action/careeraction';
import * as types from '../action.type';

export function* getVacancySaga(data) {
    const response = yield call(getAllVacancy, data);
    if (response && !response.error) {
        yield put(getAllVacancySuccess(response.data));
    } else {
        yield put({ type: types.GETVACANCY_ERROR, response });
    }
}

export function* JobApplySaga(data) {
    const response = yield call(JobApplyService, { ...data });
    if (response && !response.error) {
        yield put(JobApplySuccess(response.data));
    } else {
        yield put({ type: types.JOB_APPLY_ERROR, response });
    }
}
