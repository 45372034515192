import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
// import News from "../../news";
import { BlackBtn } from "_Shared/Buttons";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { usePhoneLandScape, usePhablet } from "_Utils/Helpers";
import LaptopImg from "_Images/software/SoftwareSection1.jpg";
import MobileApp from "./mobileApp";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(104.04deg, #FFFFFF 0%, #FFF3EB 100%)",
    position: "relative",
    paddingTop: 130,
    flexWrap: "nowrap",
    [theme.breakpoints.down("md")]: {
      padding: "64px 32px 32px 32px ",
    },
  },
  subParent: {
    flexWrap: "nowrap",
  },
  swImg: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  swDiv: {
    [theme.breakpoints.down("md")]: {
      marginTop: 24,
    },
    [theme.breakpoints.up("lg")]: {
      alignItems: "flex-start",
      paddingRight: 20,
    },
  },
  swText: {
    margin: "8px 0px 40px 0px",

    [theme.breakpoints.up("lg")]: { margin: "8px 20px 80px 0px" },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  blogSection: {
    marginTop: 75,
    [theme.breakpoints.up("md")]: {
      marginTop: 100,
    },
  },
}));

const Software = () => {
  const classes = useStyles();
  const isPhablet = usePhablet();
  const isPhone = usePhoneLandScape();

  const KnowMore = () => (
    <div className="centerDiv">
      <FindInPageOutlinedIcon />
      <span style={{ marginLeft: 5, width: "max-content" }}>KNOW MORE</span>
    </div>
  );

  return (
    <div>
      <Grid container className={classes.root}>
        <Grid
          container
          item
          xs={12}
          md={12}
          sm={12}
          lg={12}
          justifyContent="space-around"
        >
          <Grid lg={1} item />
          <Grid
            lg={10}
            xs={12}
            md={12}
            sm={12}
            item
            container
            justifyContent="space-between"
            direction={isPhablet ? "column-reverse" : "row"}
            className={classes.subParent}
          >
            <Grid
              item
              xs={12}
              lg={6}
              md={12}
              className={
                isPhone
                  ? `centerDiv flexColumn ${classes.swDiv}`
                  : `centerDiv flexColumn ${classes.swDiv}`
              }
            >
              {/* <AnimatedDiv
                children={
                  <> */}
              <div className={isPhone ? "text32 centerDiv" : "text56"}>
                Software R&D
              </div>
              <div className={classes.swText}>
                <span
                  className={isPhone ? "text16 darkGrey" : "text20 darkGrey"}
                >
                  Our In-house software pioneers help you customize and unlock
                  your network's true potential.
                </span>
              </div>
              <div className={isPhone ? "centerDiv" : ""}>
                <BlackBtn
                  text={<KnowMore />}
                  width={isPhone ? 158 : 184}
                  height={isPhone ? 41 : 56}
                  padding={isPhone ? "0px" : "16px 24px"}
                  outlined={false}
                  btnHref="/software"
                />
              </div>
              {/* </> */}
              {/* }
                animation={isPhablet ? "fade-up" : "fade-right"}
              /> */}
            </Grid>
            <Grid item xs={12} lg={6}>
              <AnimatedDiv
                children={
                  <div
                    className="centerDiv stretch"
                    style={{
                      height: isPhablet ? 280 : 540,
                      marginTop: isPhablet ? 52 : 0,
                    }}
                  >
                    <img
                      src={LaptopImg}
                      alt="image"
                      className={classes.swImg}
                    />
                  </div>
                }
                animation={isPhablet ? "fade-up" : "fade-left"}
              />
            </Grid>
          </Grid>
          <Grid lg={1} item />
        </Grid>
        {/* <MobileApp />  */}
      </Grid>
      <MobileApp />
    </div>
  );
};

export default Software;
