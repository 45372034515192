import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ProductCard from "./ProductCard";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { GoalsData } from "_Constants/Goals";
import { usePhoneLandScape, usePhablet } from "_Utils/Helpers";
import Image1 from "../../../assets/images/home/explodedfullview.png";
import Image3 from "../../../assets/images/home/03.png";
import Image4 from "../../../assets/images/home/02.png";
import Image5 from "../../../assets/images/home/01.png";
import CombineImage from "../../../assets/images/home/closing.png";
import { useState, useEffect } from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(
      104.04deg
      , #FFFFFF 0%, #FFF3EB 100%)`,

    padding: "64px 10px 0px 10px",
    [theme.breakpoints.up("xs")]: {
      padding: "64px 15px 0px 15px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "80px 40px 0px 40px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "80px 80px 0px 80px",
    },
  },
  imgDiv: {
    background: "transparent",
    zIndex: 1,
    [theme.breakpoints.down(1023)]: { display: "none" },
  },
  largeImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    transition: "1.3s ease",
  },
  card: {
    margin: "24px 0px",
    width: "100%",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    // [theme.breakpoints.up("md")]: {
    //   width: 500,
    // },
  },
  img: {
    height: 50,
    objectFit: "contain",
  },
  fftpCard: {
    position: "relative",
    top: 120,
  },
}));

const CompanyGoals = () => {
  const classes = useStyles();
  const isPhablet = usePhablet();
  const isPhone = usePhoneLandScape();
  const [DynamicImage, setDynamicImage] = useState();

  useEffect(() => {
    const adjustImg = () => {
      let offset = window.pageYOffset;

      const el_final = document.getElementById("finalDiv");

      let diff = offset - el_final.offsetHeight * 2;

      if (diff > 0) {
        let res = diff > 100 && diff < 280 ? diff : diff > 280 ? 280 : diff;

        var scrolled = (window.innerHeight / window.scrollY) * 1.2,
          transformValue = "scale(" + scrolled + ")";
        el_final.style.top = res * 1.8 + "px";

        if (scrolled > 0.8) {
          el_final.style.WebkitTransform = transformValue;
          el_final.style.MozTransform = transformValue;
          el_final.style.OTransform = transformValue;
          el_final.style.transform = transformValue;
        }
      }

      const largediv = document.getElementById("largeImg");
      let eldiv = offset - largediv.offsetHeight * 1.5;
      if (eldiv > 0) {
        let res = eldiv > 0 && eldiv < 200 ? eldiv : eldiv > 200 ? 200 : eldiv;
        largediv.style.top = res + "px";

        setDynamicImage(res);
      }

      const middleImage = document.getElementById("middleImage");
      let elmiddleImage = offset - middleImage.offsetHeight * 1.5;

      if (elmiddleImage > 0) {
        let res =
          elmiddleImage > 0 && elmiddleImage < 200
            ? elmiddleImage
            : elmiddleImage > 200
            ? 200
            : elmiddleImage;
        middleImage.style.top = res / 2 + 100 + "px";
      }
    };
    window.addEventListener("scroll", adjustImg, true);

    return () => window.removeEventListener("scroll", adjustImg, true);
  });

  const ImgDiv = (
    <AnimatedDiv
      children={
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            id="finalDiv"
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
              height: "500px",
            }}
          >
            <img
              id="largeImg"
              src={Image5}
              alt="Image1"
              className={classes.largeImg}
              style={{
                top: 0,
                position: "absolute",
                zIndex: 2,
              }}
            />

            <img
              id="middleImage"
              src={Image4}
              alt="Image1"
              className={classes.largeImg}
              style={{
                top: 100,
                zIndex: 1,
                position: "absolute",
              }}
            />
            <img
              id="lstImage"
              src={Image3}
              alt="Image3"
              className={classes.largeImg}
              style={{
                top: 200,
                position: "absolute",
              }}
            />
          </div>
        </div>
      }
      animation="fade-right"
    />
  );

  const GoalsContainer = (
    <Grid md={12} xs={12} sm={12} lg={12} item>
      <Grid md={12} xs={12} sm={12} lg={12} item style={{ display: "flex" }}>
        <Grid lg={1} md={2} item />
        <Grid xs={12} lg={10} md={8} item>
          {GoalsData.map((obj, i) => (
            <AnimatedDiv
              key={i}
              children={
                <Card className={classes.card} style={{ width: "100%" }}>
                  <CardContent>
                    <Grid xs={12} container item>
                      <Grid
                        xs={4}
                        sm={3}
                        item
                        container
                        justifyContent="center"
                      >
                        <div className="centerDiv">
                          <img
                            src={obj.pic}
                            alt="logo"
                            className={classes.img}
                          />
                        </div>
                      </Grid>
                      <Grid xs={8} sm={9} item>
                        <div
                          className={
                            isPhone
                              ? "text16 secondary mp-bold"
                              : "text24 secondary mp-bold"
                          }
                          style={{
                            marginBottom: isPhone ? 4 : 8,
                          }}
                        >
                          {obj.title}
                        </div>
                        <div
                          className={
                            isPhone
                              ? "text12 darkGrey w400"
                              : "text16 darkGrey w400"
                          }
                        >
                          {obj.subtitle}
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              }
              animation={isPhablet ? "fade-up" : "fade-left"}
            />
          ))}
        </Grid>
        <Grid lg={1} md={2} item />
      </Grid>
    </Grid>
  );

  return (
    <Grid container className={classes.root}>
      <Grid item lg={6} className={classes.imgDiv}>
        {ImgDiv}
      </Grid>
      <Grid
        container
        item
        xs={12}
        lg={6}
        // md={12}
        // sm={12}
        justifyContent={isPhablet ? "center" : "flex-end"}
      >
        {GoalsContainer}
      </Grid>

      <Grid item xs={12} className={classes.fftpCard}>
        <ProductCard image={CombineImage} />
      </Grid>
    </Grid>
  );
};

export default CompanyGoals;
