import { Grid, Card } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DirectionsIcon from "@material-ui/icons/Directions";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { usePhoneLandScape } from "_Utils/Helpers";
import { locationData } from "_Constants/ContactInfo";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#2C2E30",
    padding: "64px 32px 100px 32px",
    [theme.breakpoints.up("lg")]: {
      padding: "64px 100px 120px 100px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "64px 140px 120px 140px",
    },
  },
  cardsDiv: {
    marginTop: 32,
    [theme.breakpoints.up("md")]: {
      marginTop: 48,
    },
  },
  card: {
    width: "inherit",
    // padding: 20,
    height: 380,
  },
  imgDiv: { padding: "0px 20px" },
  cityImg: {
    height: 180,
    width: "100%",
    marginBottom: 16,
  },
  directionsBtn: {
    display: "flex",
    // marginTop: 24,
    cursor: "pointer",
    position: "absolute",
    bottom: 28,
    right: 4,
  },
}));

const Locations = () => {
  const classes = useStyles();
  const isPhone = usePhoneLandScape();

  return (
    <Grid container className={classes.root}>
      <Grid container item xs={12} justifyContent="center">
        {/* <AnimatedDiv
          children={
            <div className={isPhone ? "text32" : "text48 primary"}>
              Other Locations
            </div>
          }
          animation="fade-up"

/> */}
        <div className={isPhone ? "text32" : "text48 primary"}>
          Other Locations
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.cardsDiv}
        // justifyContent="center"
        spacing={3}
        container
      >
        {locationData.map((obj, index) => (
          <Grid item xs={12} sm={6} lg={3} key={index}>
            <AnimatedDiv
              children={
                <Card className={classes.card}>
                  <div className={classes.cityImg}>
                    <img
                      src={obj.pic}
                      alt="pic"
                      height="100%"
                      width="100%"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <div style={{ margin: 12 }}>
                    <div
                      className={
                        isPhone
                          ? "text16 textCenter secondary mp-bold"
                          : "text20 textCenter secondary mp-bold"
                      }
                    >
                      <b>{obj.title}</b>
                    </div>
                    <div
                      className={
                        isPhone
                          ? "text12 darkGrey mp-semi"
                          : "text12 darkGrey mp-semi"
                      }
                      style={{ marginTop: 8, textAlign: "center" }}
                    >
                      {obj.address}
                    </div>
                    <div
                      className={
                        isPhone ? "text12 nuetralGrey" : "text12 nuetralGrey"
                      }
                      style={{
                        marginTop: 16,
                        textAlign: "center",
                        fontWeight: 600,
                      }}
                    >
                      {obj.contact}
                    </div>
                    <Grid
                      container
                      justifyContent="center"
                      className={classes.directionsBtn}
                    >
                      {obj.url ? (
                        <a
                          href={obj.url}
                          target="_blank"
                          style={{ textDecoration: "none", display: "flex" }}
                        >
                          <DirectionsIcon
                            style={{
                              color: "#FD6A02",
                              marginRight: isPhone ? 8 : 12,
                              fontSize: isPhone ? 20 : 25,
                            }}
                          />
                          <div
                            className={
                              isPhone ? "text14 primary" : "text16 primary"
                            }
                          >
                            Get Directions
                          </div>
                        </a>
                      ) : (
                        <div
                          className={
                            isPhone ? "text14 primary" : "text16 primary"
                          }
                        >
                          Coming Soon
                        </div>
                      )}
                    </Grid>
                  </div>
                </Card>
              }
              animation="fade-up"
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Locations;
