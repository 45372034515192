import { Provider } from "react-redux";
import { MuiThemeProvider } from "@material-ui/core/styles";
import IndexRoutes from "_Constants/IndexRoutes";
import Theme from "_Utils/theme";
import configureStore from "_Store/index.store";
import GA4React, { useGA4React } from "ga-4-react";
import ReactGA from "react-ga";
import { useEffect } from "react";

// ReactGA.initialize("UA-164391524-2");
// const ga4react = new GA4React("G-JV15G9JYE0");

function App() {
  const store = configureStore();
  // useEffect(() => {
  //   // ReactGA.pageview(window.location.pathname + window.location.search);
  //   const initGA = async () => {
  //     await ga4react.initialize();
  //     await ga4react.pageview(
  //       window.location.pathname + window.location.search
  //     );
  //   };
  //   initGA();
  // }, []);
  return (
    <MuiThemeProvider theme={Theme}>
      <Provider store={store}>
        <IndexRoutes />
      </Provider>
    </MuiThemeProvider>
  );
}

export default App;
