import CompanyGoals from "./components/CompanyGoals";
import Products from "./components/Products";
import Software from "./components/Software";
import Blog from "./components/Blog";
// import Banner from "_Shared/Banner";
import SlideShow from "_Shared/SlideShow";
import SubFooter from "_Shared/SubFooter";
import BannerImage from "_Images/home/newBanner.jpg";
import MobileBanner from "_Images/home/homebanner_mobil.jpg";
// import MobileApp from "./components/mobileApp";
import { usePhablet } from "_Utils/Helpers";
import News from "../news";
import { Grid, makeStyles } from "@material-ui/core";
//home page

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
  },
  bannerTextGrid: {
    position: "absolute",
    top: "32%",
    // left: 50,
    [theme.breakpoints.down(900)]: {
      padding: 16,
      top: "52%",
      textAlign: "center",
    },
  },
  bannerText: {
    fontSize: 56,
    color: "#61686C",
    fontWeight: 700,

    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
  },
  bannerGrid: {
    backgroundImage: `url(
      ${BannerImage} )`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "840px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: "100vh",
      backgroundPosition: "50%",
      backgroundImage: `url(
        ${MobileBanner} )`,
    },
  },
}));
const Home = () => {
  const classes = useStyles();
  const isMobile = usePhablet();
  return (
    <div className={classes.root}>
      <Grid
        md={12}
        xs={12}
        sm={12}
        lg={12}
        item
        className={classes.bannerGrid}
      />
      <Grid
        container
        justifyContent={isMobile ? "center" : ""}
        direction="row"
        className={classes.bannerTextGrid}
        style={{ left: !isMobile ? 50 : "" }}
      >
        <Grid>
          <p className={classes.bannerText}>
            {" "}
            <span>
              {" "}
              We design your <br />
            </span>
            <span style={{ color: "#FF5500", fontSize: isMobile ? 32 : "" }}>
              DIGITAL WORLD
            </span>
          </p>
        </Grid>
      </Grid>
      {/* <Banner image={BannerImage} vdo={true} /> */}
      <CompanyGoals />
      <Products />
      <SlideShow />
      <Software />
      <Grid
        item
        md={12}
        xs={12}
        sm={12}
        lg={12}
        container
        justifyContent="center"
      >
        <News partial={true} />
      </Grid>
      <Blog />
      <SubFooter
        heading="Reward Program"
        subHeading="Find bugs, get rewarded!"
        btnText="$&nbsp;Earn Rewards"
        extended
        btnHref="/reward-program"
        homepage
      />
    </div>
  );
};

export default Home;
