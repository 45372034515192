import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import WorkIcon from "@material-ui/icons/Work";
import { OrangeBtn } from "_Shared/Buttons";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { getNews } from "_Store/action/news_eventsaction";
import { usePhoneLandScape, usePhablet } from "_Utils/Helpers";
import "./news.css";
const useStyle = makeStyles((theme) => ({
  root: {
    paddingTop: 140,
    paddingRight: 32,
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
      paddingTop: 110,
    },
  },
  newsImage: {
    width: "100%",
    maxWidth: "100%",
    maxHeight: "100%",
    // height: "100%",
    objectFit: "cover",
  },
  newsText: {
    marginTop: 16,
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
    },
    [theme.breakpoints.down("md")]: {
      padding: 32,
    },
  },
  tags: {
    width: "100%",
    marginTop: 36,
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.up("lg")]: {
      marginTop: 48,
    },
  },
  tagItem: {
    padding: "4px 8px",
    margin: "12px 24px 12px 0px",
    fontSize: 12,
    fontWeight: 700,
    height: 22,
    lineHeight: "150%",
    background: "#FFFFFF",
    boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    color: "#030201",
  },
}));

const NewsDetail = () => {
  let { id } = useParams();

  const isPhablet = usePhablet();
  const isPhone = usePhoneLandScape();
  const dispatch = useDispatch();
  const AllNews = useSelector((state) => state.getNewsReducer);
  const data = AllNews.newsEvents;

  const classes = useStyle();

  useEffect(() => {
    dispatch(getNews());
  }, []);

  const RegisterBtnText = (
    <>
      <WorkIcon />
      &nbsp;&nbsp;REGISTER NOW
    </>
  );

  /*  item.longDesc is in html format that needs to be parsed, thus using
        "dangerouslySetInnerHTML" in its div. Can also use "react-html-parser" lib.
    */
  return (
    <Grid
      lg={12}
      md={12}
      xs={12}
      sm={12}
      item
      container
      className={classes.root}
    >
      <Grid
        lg={12}
        md={12}
        xs={12}
        sm={12}
        item
        container
        style={{ display: "flex" }}
      >
        {data &&
          data
            .filter((obj) => obj._id === id)
            .map((item, index) => (
              <Grid xs={12} lg={12} md={12} sm={12} item container key={index}>
                <Grid lg={1} item />
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={5}
                  style={{ padding: isPhablet ? 32 : 0 }}
                >
                  <AnimatedDiv
                    children={
                      <img
                        src={item.imgUrl}
                        alt="news_img"
                        className={classes.newsImage}
                      />
                    }
                    animation={isPhablet ? "fade-up" : "fade-right"}
                  />
                </Grid>
                <Grid lg={1} item />
                <Grid
                  container
                  item
                  xs={12}
                  lg={5}
                  className={classes.newsText}
                >
                  <Grid item xs={12} sm={12} md={12} lg={11}>
                    <AnimatedDiv
                      children={
                        <>
                          <div
                            className={
                              isPhone
                                ? "text14 secondary mp-semi"
                                : "text20 secondary mp-semi"
                            }
                          >
                            <b>{item.type.toUpperCase()}</b>
                          </div>
                          <div
                            className={
                              isPhone
                                ? "text20 primary mp-bold"
                                : "text32 mp-bold"
                            }
                            style={{
                              marginTop: 6,
                              marginBottom: isPhone ? 24 : 32,
                            }}
                          >
                            <b>{item.title}</b>
                          </div>
                        </>
                      }
                      animation={isPhablet ? "fade-up" : "fade-left"}
                    />

                    {item.shortDesc && (
                      <Grid
                        item
                        xs={12}
                        className="text16 darkGrey"
                        style={{
                          marginBottom: isPhone ? 32 : 40,
                        }}
                      >
                        <AnimatedDiv
                          children={item.shortDesc}
                          animation={isPhablet ? "fade-up" : "fade-left"}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <AnimatedDiv
                        children={
                          <div
                            className="text16 darkGrey"
                            dangerouslySetInnerHTML={{
                              __html: item.longDesc,
                            }}
                          />
                        }
                        animation={isPhablet ? "fade-up" : "fade-left"}
                      />
                    </Grid>

                    {item.date && (
                      <Grid item xs={12}>
                        <AnimatedDiv
                          children={
                            <div className="text24 secondary mp-semi">
                              <b>
                                {new Date(item.date)
                                  .toLocaleDateString("en-GB", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                  })
                                  .split(" ")
                                  .join(" ")}{" "}
                                <span className="nuetralGrey">at </span>
                                {new Date(item.time).toLocaleTimeString(
                                  "en-us",
                                  {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }
                                )}
                              </b>
                            </div>
                          }
                          animation={isPhablet ? "fade-up" : "fade-left"}
                        />
                      </Grid>
                    )}
                    {item.place && (
                      <Grid item xs={12}>
                        <AnimatedDiv
                          children={
                            <>
                              <div className="text24 nuetralGrey">
                                <b>{item.place}</b>
                              </div>
                              <div
                                style={{
                                  marginTop: 24,
                                }}
                              >
                                <OrangeBtn
                                  text={RegisterBtnText}
                                  width={isPhone ? 165 : 200}
                                  height={isPhone ? 40 : 56}
                                  padding={isPhone ? "10px 16px" : "16px 24px"}
                                  btnHref={item.link}
                                />
                              </div>
                            </>
                          }
                          animation={isPhablet ? "fade-up" : "fade-left"}
                        />
                      </Grid>
                    )}

                    <Grid
                      item
                      xs={12}
                      lg={12}
                      md={12}
                      sm={12}
                      className={classes.tags}
                    >
                      <AnimatedDiv
                        children={
                          <Grid
                            xs={12}
                            lg={12}
                            md={12}
                            sm={12}
                            container
                            direction="flex"
                            // className="flexRow"
                            style={{ flexWrap: "wrap" }}
                          >
                            {Array.isArray(item.tags) &&
                              item.tags &&
                              item.tags.map((tag, index) => (
                                <Grid key={index} className={classes.tagItem}>
                                  {tag}
                                </Grid>
                              ))}
                          </Grid>
                        }
                        animation={isPhablet ? "fade-up" : "fade-left"}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
      </Grid>
    </Grid>
  );
};

export default NewsDetail;
