import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import GooglePlay from "_Images/software/googleplay.png";
import ViewMorePDF from "_Images/pdfs/viewppt.pdf";
import AOS from "aos";
import "aos/dist/aos.css";
const Software = (props) => {
  const { bannerImage, OpenWrt, RouterManagement, heading, subHeading } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      height: 670,
      alignItems: "centre",
      position: "relative",
      background: "#fff",
      flexDirection: RouterManagement ? "row-reverse" : "row",
      [theme.breakpoints.down("md")]: {
        height: "100%",
      },
    },
    bannerImage: {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },

    heading: {
      fontSize: 40,
      color: "#FD6A02",
      fontWeight: 700,
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
    },
    text: {
      fontSize: 20,
      color: "#fff",
      marginTop: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    ppt: {
      background: "#fff",
      borderRadius: 32,
      width: "max-content",
      padding: "8px 16px",
      marginTop: 48,
      color: "#030201",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },

    contentGrid: {
      background: "#2C2E30",
      [theme.breakpoints.down("md")]: {
        padding: "60px 16px 32px 16px",
        position: "absolute",
        bottom: -200,
      },
      [theme.breakpoints.down("xs")]: {
        padding: "60px 16px 32px 16px",
        position: "absolute",
        bottom: -260,
      },
    },

    ImageGrid: {
      position: "absolute",
      right: 0,
      top: 0,
      left: 0,
      left: RouterManagement ? 0 : "",
      height: 670,
      [theme.breakpoints.down("md")]: {
        height: "auto",
        position: "relative",
      },
    },
    playStore: {
      marginTop: 48,
      [theme.breakpoints.down("sm")]: {
        height: 35,
      },
    },
  }));
  const classes = useStyles();
  AOS.init();
  return (
    <Grid
      md={12}
      xs={12}
      sm={12}
      lg={12}
      item
      container
      className={classes.root}
      id={props?.id}
    >
      <Grid
        md={12}
        xs={12}
        sm={12}
        lg={9}
        item
        container
        justifyContent={RouterManagement ? "flex-end" : "flex-start"}
        className={classes.contentGrid}
      >
        <Grid lg={1} item />
        <Grid
          lg={8}
          xs={12}
          sm={12}
          md={12}
          item
          container
          justifyContent={RouterManagement ? "flex-end" : "flex-start"}
          data-aos={RouterManagement ? "fade-left" : "fade-right"}
          data-aos-duration={600}
          data-aos-once={true}
        >
          <Grid
            lg={9}
            container
            justifyContent="center"
            alignItems="center"
            item
            className={classes.textGrid}
          >
            <Grid lg={10} item>
              <Typography className={classes.heading}>{heading}</Typography>
              <Typography className={classes.text}>{subHeading}</Typography>
              {RouterManagement ? (
                <a
                  href={"http://onelink.to/yh5yn5"}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Typography className={classes.ppt}>DOWNLOAD NOW</Typography>
                </a>
              ) : (
                <a
                  href={ViewMorePDF}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Typography className={classes.ppt}>
                    VIEW MORE ON PPT
                  </Typography>
                </a>
              )}
            </Grid>
          </Grid>
          <Grid lg={1} item />
        </Grid>
      </Grid>
      <Grid
        md={12}
        xs={12}
        sm={12}
        lg={6}
        item
        container
        justifyContent="center"
        alignItems="center"
        className={classes.ImageGrid}
        data-aos={RouterManagement ? "fade-right" : "fade-left"}
        data-aos-duration={600}
        data-aos-once={true}
      >
        <Grid
          md={12}
          xs={12}
          sm={12}
          lg={12}
          container
          justifyContent="center"
          className={classes.bannerImageGrid}
        >
          <img src={bannerImage} alt="bannerImage" height="100%" width="100%" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Software;
