import * as types from '../action.type';
// get all opening
export const getAllVacancy = (data) => {
    return {
        type: types.GETVACANCY,
        data,
    };
};
export const getAllVacancySuccess = (data) => {
    return {
        type: types.GETVACANCY_SUCCESS,
        data,
    };
};

export const getAllVacancyError = (data) => {
    return {
        type: types.GETVACANCY_ERROR,
        data,
    };
};
// jobAPply
export const JobApply = (data) => {
    return {
        type: types.JOB_APPLY,
        data,
    };
};
export const JobApplySuccess = (data) => {
    return {
        type: types.JOB_APPLY_SUCCESS,
        data,
    };
};

export const JobApplyError = (data) => {
    return {
        type: types.JOB_APPLY_ERROR,
        data,
    };
};
