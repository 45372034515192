import * as types from '../action.type';
export const getAllVacancyReducer = (state = {}, action) => {
    const response = action.data;

    switch (action.type) {
        case types.GETVACANCY_SUCCESS:
            return response;
        case types.GETVACANCY_ERROR:
            return state;
        default:
            return state;
    }
};

export const JobApplyReducer = (state = {}, action) => {
    const response = action.data;

    switch (action.type) {
        case types.JOB_APPLY_SUCCESS:
            return response;
        case types.JOB_APPLY_ERROR:
            return state;
        default:
            return state;
    }
};
