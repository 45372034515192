import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import WorkIcon from "@material-ui/icons/Work";
import Backdrop from "@material-ui/core/Backdrop";
import makeStyles from "@material-ui/core/styles/makeStyles";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import { OrangeBtn, WhiteBtn } from "_Shared/Buttons";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { usePhoneLandScape } from "_Utils/Helpers";
import ClientsImg from "_Images/about/clients.png";
import Certificate1 from "_Images/about/c1.png";
import Certificate2 from "_Images/about/c2.png";
import Certificate3 from "_Images/about/c3.png";
import Certificate4 from "_Images/about/c4.png";
import Certificate5 from "_Images/about/c5.png";
import Certificate6 from "_Images/about/c6.png";

const useStyles = makeStyles((theme) => ({
  parent: {
    background: "#2C2E30",
    position: "relative",
    padding: "0px 20px 80px 20px",
    marginTop: 380,
    // transform:"2s ease"
    [theme.breakpoints.up("sm")]: {
      padding: "60px 20px 80px 20px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "150px 20px 80px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 150,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 70,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  peopleText: {
    margin: "100px 0px 20px 0px",
    zIndex: 1,
    [theme.breakpoints.up("xs")]: {
      margin: "93px 0px 20px 0px",
    },
    [theme.breakpoints.up("sm")]: {
      margin: "200px 0px 20px 0px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "210px 0px 20px 0px",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "264px 0px 20px 0px",
    },
  },
  longText: {
    padding: "0 20px",
  },
  certDiv: {
    marginTop: 75,
    marginBottom: 50,
    [theme.breakpoints.up("md")]: {
      marginBottom: 100,
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 150,
      marginBottom: 150,
    },
  },
  certText: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
  },
  cardsDiv: {
    marginTop: 50,
    [theme.breakpoints.up("md")]: {
      marginTop: 70,
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
    },
  },
  card: {
    height: "100%",
    width: "100%",
    // [theme.breakpoints.up("xs")]: {
    //   height: 140,
    //   width: 140,
    // },
    // [theme.breakpoints.up("md")]: {
    //   height: 160,
    //   width: 160,
    // },
  },
  image: {
    objectFit: "contain",
    height: 50,
    width: 50,
    [theme.breakpoints.up("xs")]: {
      height: 70,
      width: 70,
    },
    [theme.breakpoints.up("md")]: {
      height: 90,
      width: 90,
    },
  },
  btnDiv: {
    marginTop: 33,
    [theme.breakpoints.up("md")]: {
      marginTop: 50,
    },
  },
  backdropImg: {
    width: 240,
    height: 180,
    [theme.breakpoints.up("xs")]: {
      width: 320,
      height: 240,
    },
    [theme.breakpoints.up("sm")]: {
      width: 440,
      height: 330,
    },
    [theme.breakpoints.up("md")]: {
      width: 600,
      height: 450,
    },
    [theme.breakpoints.up("lg")]: {
      width: 720,
      height: 540,
    },
  },
  cardGrid: {
    height: 120,
    margin: 8,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: 8,
    },
  },
}));

const Certificates = () => {
  const classes = useStyles();
  const isPhone = usePhoneLandScape();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const JoinBtnText = () => (
    <>
      <WorkIcon />
      &nbsp;&nbsp;COME JOIN US
    </>
  );
  const SolutionsBtnText = () => (
    <>
      <EmojiObjectsOutlinedIcon />
      &nbsp;&nbsp;OUR SOLUTIONS
    </>
  );

  return (
    <Grid container justifyContent="center" className={classes.parent}>
      <Grid item xs={12} md={10} className={classes.peopleText}>
        <AnimatedDiv
          children={
            <div
              className={
                isPhone
                  ? "text24 lightGrey textCenter"
                  : "text40 lightGrey textCenter"
              }
              style={{ position: "relative", zIndex: 2 }}
            >
              The people who made it happen
              {/* <span className="primary link" onClick={handleToggle}>
                <u>it</u>
              </span>{" "} */}
            </div>
          }
          animation="fade-up"
        />
      </Grid>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <div className="centerDiv stretch">
          <img src={ClientsImg} alt="clients" className={classes.backdropImg} />
        </div>
      </Backdrop>
      <Grid
        container
        item
        xs={12}
        md={10}
        lg={8}
        justifyContent="center"
        className={classes.longText}
      >
        <AnimatedDiv
          children={
            <>
              <div
                className={isPhone ? "text16 lightGrey" : "text20 lightGrey"}
                style={{ textAlign: "center" }}
              >
                Our people are many things. Some are entrepreneurs, creators,
                developers and innovators. Others are thinkers, listeners,
                planners and strategists. They come from different cultures and
                diverse backgrounds. What connects them all is their passion and
                drive to develop in-home connectivity solutions that surpass our
                clients’ expectations. They are what make the company a success.
              </div>
              <div className={`${classes.btnDiv} centerDiv`}>
                <OrangeBtn
                  text={<JoinBtnText />}
                  width={isPhone ? 159 : 193}
                  height={isPhone ? 41 : 56}
                  padding={isPhone ? "10px 16px" : "16px 24px"}
                  btnHref="/contact#form"
                />
              </div>
            </>
          }
          animation="fade-up"
        />
      </Grid>
      <Grid container item xs={12} className={classes.certDiv}>
        <Grid item lg={1} />
        <Grid
          item
          xs={12}
          lg={4}
          md={4}
          container
          justifyContent={isPhone ? "center" : "flex-start"}
          alignItems="flex-end"
        >
          <div className={`${classes.certText}`}>
            <Grid
              md={12}
              xs={12}
              sm={12}
              lg={12}
              item
              container
              justifyContent={isPhone ? "center" : "flex-start"}
              className={isPhone ? "text32" : "text56"}
            >
              Certificates
            </Grid>
            <Grid
              md={12}
              xs={12}
              sm={12}
              lg={12}
              item
              container
              justifyContent={isPhone ? "center" : "flex-start"}
              className={isPhone ? "text16 lightGrey" : "text20 lightGrey"}
            >
              Accredited by certified organisations
            </Grid>
            <Grid
              md={12}
              xs={12}
              sm={12}
              lg={12}
              item
              container
              justifyContent={isPhone ? "center" : "flex-start"}
              className={classes.btnDiv}
            >
              <WhiteBtn
                text={<SolutionsBtnText />}
                width={isPhone ? 175 : 210}
                height={isPhone ? 40 : 56}
                padding={isPhone ? "10px 16px" : "16px 24px"}
                btnHref="/solutions"
              />
            </Grid>
          </div>
        </Grid>
        <Grid item lg={1} />
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={8}
          lg={5}
          justifyContent="center"
          className={classes.cardsDiv}
        >
          <AnimatedDiv
            children={
              <Grid
                xs={12}
                sm={12}
                md={12}
                item
                container
                justifyContent="flex-end"
              >
                <Grid container item xs={12} sm={12} md={3}>
                  <Grid
                    container
                    item
                    md={12}
                    xs={6}
                    sm={6}
                    justifyContent="center"
                    className={classes.cardGrid}
                  >
                    <Card className={classes.card}>
                      <div className="centerDiv stretch">
                        <img
                          src={Certificate1}
                          alt="certificate1"
                          className={classes.image}
                        />
                      </div>
                    </Card>
                  </Grid>
                  <Grid
                    container
                    item
                    md={12}
                    xs={6}
                    sm={6}
                    justifyContent="center"
                    className={classes.cardGrid}
                  >
                    <Card className={classes.card}>
                      <div className="centerDiv stretch">
                        <img
                          src={Certificate2}
                          alt="certificate2"
                          className={classes.image}
                        />
                      </div>
                    </Card>
                  </Grid>
                </Grid>

                <Grid container item xs={12} md={3} sm={12}>
                  <Grid
                    container
                    item
                    md={12}
                    xs={6}
                    sm={6}
                    justifyContent="center"
                    className={classes.cardGrid}
                  >
                    <Card
                      className={classes.card}
                      style={{ marginTop: isPhone ? 0 : 40 }}
                    >
                      <div className="centerDiv stretch">
                        <img
                          src={Certificate3}
                          alt="certificate3"
                          className={classes.image}
                        />
                      </div>
                    </Card>
                  </Grid>
                  <Grid
                    container
                    item
                    md={12}
                    xs={6}
                    sm={6}
                    justifyContent="center"
                    className={classes.cardGrid}
                  >
                    <Card
                      className={classes.card}
                      style={{ marginTop: isPhone ? 0 : 40 }}
                    >
                      <div className="centerDiv stretch">
                        <img
                          src={Certificate4}
                          alt="certificate4"
                          className={classes.image}
                        />
                      </div>
                    </Card>
                  </Grid>
                </Grid>

                <Grid container item xs={12} sm={12} md={3}>
                  <Grid
                    container
                    item
                    md={12}
                    xs={6}
                    sm={6}
                    justifyContent="center"
                    className={classes.cardGrid}
                  >
                    <Card className={classes.card}>
                      <div className="centerDiv stretch">
                        <img
                          src={Certificate5}
                          alt="certificate5"
                          className={classes.image}
                        />
                      </div>
                    </Card>
                  </Grid>
                  <Grid
                    container
                    item
                    md={12}
                    xs={6}
                    sm={6}
                    justifyContent="center"
                    className={classes.cardGrid}
                  >
                    <Card className={classes.card}>
                      <div className="centerDiv stretch">
                        <img
                          src={Certificate6}
                          alt="certificate6"
                          className={classes.image}
                        />
                      </div>
                    </Card>
                  </Grid>
                </Grid>
                <Grid item lg={1} />
              </Grid>
            }
            animation={isPhone ? "fade-up" : "fade-left"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Certificates;
