import makeStyles from "@material-ui/core/styles/makeStyles";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { usePhoneLandScape } from "_Utils/Helpers";
// import BannerVedio from "_Images/home/video.mp4";
import BannerImage from "_Images/home/newBanner.jpg";
const Banner = ({
  image,
  videoSrc,
  thumbnail,
  ContactForm,
  leftDiv,
  rightDiv,
  contactdetail,
  smallSize = false,
  raised = false,
  isBwForm = false,
  formStretched = false,
  forceBtm = false,
  zoomImg = false,
  imgMargin = false,
  addressDiv,
  vdo,
  DevContent_Software,
  address,
  contact_Second_banner,
  contactbanner,
  productDetail,
  productPage,
  formSubmit,
  productDetailfeature,
  bannerborder = false,
  softwarePage,
}) => {
  const useStyles = makeStyles((theme) => ({
    parent: {
      background: image ? `url(${image}) center center no-repeat` : "none",
      backgroundSize: "cover",
      "-webkit-background-size": "cover",
      "-moz-background-size": "cover",
      "-o-background-size": "cover",
      width: "100%",
      position: "relative",
      minHeight: contact_Second_banner ? "100%" : "820px",
      // height: contact_Second_banner ? "100%" : "820px",
      padding: 0,
      paddingBottom: contact_Second_banner ? 32 : 40,
      margin: 0,
      // [theme.breakpoints.up("md")]: {
      //   height: 720,
      // },
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        height: softwarePage ? "120vh" : "100%",
      },
      [theme.breakpoints.down("sm")]: {
        height: ContactForm ? "120vh" : "",
        minHeight: ContactForm ? "120vh" : "",
      },
    },

    leftDiv: {
      marginBottom: contact_Second_banner ? 0 : 40,
      marginLeft: 20,
      marginRight: contact_Second_banner ? 40 : 20,
      // paddingTop: smallSize ? 100 : raised ? 30 : forceBtm ? 225 : 100,
      [theme.breakpoints.down("xs")]: {
        // paddingTop: address ? 200 : 32,
        paddingTop: smallSize
          ? 300
          : raised
          ? 30
          : forceBtm
          ? 400
          : isBwForm
          ? 92
          : productDetailfeature
          ? 150
          : softwarePage
          ? 32
          : 132,
      },
      [theme.breakpoints.up("sm")]: {
        paddingTop: contact_Second_banner
          ? 100
          : contactbanner
          ? 200
          : imgMargin
          ? 200
          : 200,
        // paddingTop: forceBtm ? 150 : 100,
        marginLeft: 40,
        marginRight: 40,
        width: smallSize
          ? 300
          : formStretched
          ? 650
          : isBwForm
          ? 500
          : "fit-content",
        height: formStretched ? "100%" : "fit-content",
      },
      [theme.breakpoints.up("md")]: {
        // paddingTop: forceBtm ? 450 : 200,
        Left: 60,
        marginRight: contact_Second_banner ? 40 : 60,
        width: smallSize
          ? 375
          : formStretched
          ? "100%"
          : isBwForm
          ? 550
          : "fit-content",
      },
      [theme.breakpoints.up("lg")]: {
        // paddingTop: forceBtm ? 325 : 200,
        marginLeft: formStretched ? 0 : 148,
        width: smallSize ? 475 : formStretched ? "100%" : isBwForm ? 600 : 678,
      },
      [theme.breakpoints.up("xl")]: {
        // paddingTop: raised ? 50 : forceBtm ? 300 : 150,
        marginLeft: formStretched ? 0 : 148,
        width: formStretched ? "100%" : isBwForm ? 542 : 678,
      },
    },
    attrText: {
      padding: "0px 20px",
      textAlign: "center",
      fontSize: 14,
      lineHeight: "150%",
      color: "#FFFFFF",
      marginBottom: 50,
      marginTop: 24,

      [theme.breakpoints.up("sm")]: {
        textAlign: "center",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
        marginLeft: 148,
      },
    },
    addressDiv: {
      position: "absolute",
      top: 125,
      // left: 65,
      [theme.breakpoints.up("xs")]: {
        top: 166,
        // left: 85,
      },
      [theme.breakpoints.up("sm")]: {
        top: 125,
        left: 375,
      },
      [theme.breakpoints.up("md")]: {
        top: 300,
        left: 445,
      },
      [theme.breakpoints.up("lg")]: {
        top: 300,
        left: 645,
      },
      [theme.breakpoints.up("xl")]: {
        top: 300,
        left: 885,
      },
    },

    rightDiv_new: {
      top: imgMargin ? 250 : 160,
      position: "relative",

      [theme.breakpoints.down("sm")]: {
        margin: "0px 16px",
        // top: 120,
      },
      [theme.breakpoints.down("md")]: {
        padding: 32,
      },
    },
    bannerTextGrid: {
      position: "absolute",
      top: "32%",
      left: 50,
      [theme.breakpoints.down("md")]: {
        left: 0,
        padding: 16,
      },
    },
  }));

  const classes = useStyles();
  let isPhone = usePhoneLandScape();

  // useEffect((e) => {
  //   if (vdo) {
  //     document.getElementById("video").play();
  //   }
  // }, []);
  return (
    <Grid md={12} xs={12} sm={12} item lg={12}>
      {!vdo ? (
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          item
          container
          className={classes.parent}
          style={{
            border: imgMargin ? "24px solid #FFFFFF" : 0,
            boxSizing: "border-box",
          }}
        >
          {leftDiv && (
            <Grid lg={12} md={12} sm={12} xs={12} item container>
              <Grid
                lg={productDetail ? 5 : 6}
                md={12}
                sm={12}
                xs={12}
                item
                container
                justifyContent="flex-start"
                className={classes.leftDiv}
              >
                {addressDiv ? (
                  <Grid xs={12} sm={12} md={12} lg={10} item>
                    <AnimatedDiv children={leftDiv} animation="fade-right" />
                  </Grid>
                ) : (
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={!productDetail ? 11 : 12}
                    item
                  >
                    <AnimatedDiv children={leftDiv} animation="fade-right" />
                  </Grid>
                )}
              </Grid>
              {addressDiv ? <Grid lg={1} item /> : null}
              {addressDiv && (
                <Grid lg={5} item className={`${classes.addressDiv}`}>
                  {addressDiv}
                </Grid>
              )}
            </Grid>
          )}

          {rightDiv && (
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              container
              justifyContent="flex-end"
            >
              <Grid
                lg={6}
                md={12}
                sm={12}
                xs={12}
                item
                container
                justifyContent="flex-end"
                className={classes.rightDiv_new}
              >
                {ContactForm ? (
                  <Grid lg={11} item>
                    <AnimatedDiv children={rightDiv} animation="fade-left" />
                  </Grid>
                ) : (
                  <AnimatedDiv children={rightDiv} animation="fade-left" />
                )}
              </Grid>
              <Grid md={1} item />
            </Grid>
          )}
          {productPage && (
            <Grid
              lg={!formStretched ? 7 : 12}
              md={12}
              sm={12}
              xs={12}
              item
              container
              style={{ display: "flex" }}
            >
              <Grid lg={1} md={1} item />
              <Grid
                lg={10}
                md={10}
                sm={12}
                xs={12}
                item
                container
                justifyContent="flex-start"
                className={classes.leftDiv}
              >
                <Grid md={12} lg={12} item>
                  <AnimatedDiv children={productPage} animation="fade-right" />
                </Grid>
              </Grid>
              <Grid lg={1} md={1} item />
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid
          md={12}
          xs={12}
          sm={12}
          lg={12}
          item
          style={{
            // maxHeight: "90vh",
            width: "100%",
            height: "820px",
            position: "relative",
            // minHeight: "90vh",
          }}
        >
          <img
            src={BannerImage}
            alt="bannerimage"
            height="100%"
            width="100%"
            style={{
              objectFit: "cover",
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Banner;
