import api from "_Utils/Api";

export const getDownloadService = (req) =>
  api
    .get("/downloads/getAll", {
      withCredentials: true,
      data: req.data,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
