import CertificateIcon from '_Icons/certificate.svg';
import ChequeIcon from '_Icons/cheque.svg';
import HallOfFameIcon from '_Icons/hall-of-fame.svg';

export const RewardPerks = [
    {
        heading: 'Certificate of Recognition',
        title:
            'Get your work recognized, as we share you the certificate for finding a bug',
        icon: CertificateIcon,
        alt: 'certificate',
    },
    {
        heading: 'Prize Money',
        title:
            'Earn money on your research. Get $2, $5 or $10 depending on the criticality of the bug',
            icon: ChequeIcon,
            alt: 'cheque',
    },
    {
        heading: 'Hall of Fame entry',
        title:
            'Become a star, get enrolled in our Hall of fame and brag about it',
            icon: HallOfFameIcon,
            alt: 'hall-of-fame',
    },
];
