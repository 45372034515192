import cogoToast from "cogo-toast";
import Icon from "@material-ui/core/Icon";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "./theme";

/* Custom hook to detect if device screen width > 1024 */
export const usePhablet = () => {
  const checkMob = useMediaQuery(theme.breakpoints.down(1023));
  return checkMob;
};
export const usedownLaptop = () => {
  const checkTab = useMediaQuery(theme.breakpoints.down(1199));
  return checkTab;
};

export const useTablet = () => {
  const checkTab = useMediaQuery(theme.breakpoints.up("md"));
  return checkTab;
};

export const usePhoneLandScape = () => {
  const checkPhone = useMediaQuery(theme.breakpoints.down("sm"));
  return checkPhone;
};

export const useSmallPhone = () => {
  const checkPhone = useMediaQuery(theme.breakpoints.down("xs"));
  return checkPhone;
};

export const GenerateIcon = ({ iconPath, alt, className }) => {
  return (
    <Icon className="textCenter">
      <img alt="icon" src={iconPath} alt={alt} className={className} />
    </Icon>
  );
};

export function makeToast(msg = "", success) {
  if (success) {
    return cogoToast.success(msg, {
      position: "top-right",
    });
  }
  return cogoToast.error(msg, {
    position: "top-right",
  });
}

export function slugify(text) {
  return text
    .trim()
    .toLowerCase()
    .replace(/[.,_']/g, "")
    .replace(/ /g, "-");
}
