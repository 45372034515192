import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { OutlinedInput, Select } from "@material-ui/core";

const useStyles = makeStyles({
  textField: {
    marginBottom: 16,
    width: "100%",
    backgroundColor: "#FFF3EB",
    borderRadius: 8,
    color: "#000",
    "&:hover": {
      background: "#FFF3EB",
    },
    ".MuiInputBase-input.Mui-disabled": {
      color: "#000",
    },
  },
  inputLabel: {
    fontSize: 16,
    color: "#61686C",
  },
  labelFocused: {
    color: "#FD6A02",
  },
  helperText: {
    marginLeft: 0,
    width: "auto",
    background: "#fff",
  },
  inputRoot: {
    backgroundColor: "#FFF3EB",
    border: 0,
    borderRadius: 8,
    color: "#030201",
    webkitAutofill: "#fff",
    background: "transparent",
  },
  selectField: {
    marginBottom: 16,
    width: "100%",
    backgroundColor: "#FFF3EB",
    borderRadius: 8,
    color: "#000",
    "&:hover": {
      background: "#FFF3EB",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "#FFF3EB",
      borderRadius: 8,
      color: "#000",
    },
    ".MuiInputBase-input.Mui-disabled": {
      color: "#000",
    },
  },
});

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    "& $notchedOutline": {
      borderColor: "#FFF3EB",
    },
    "&:hover $notchedOutline": {
      borderColor: "#FFF3EB",
    },
    "&$focused $notchedOutline": {
      borderColor: "#FFF3EB",
    },
  },
  focused: {},
  notchedOutline: {},
}));

export const RHFTextField = (props) => {
  const classes = useStyles();

  return (
    <TextField
      variant="filled"
      name={props.name}
      label={props.label}
      inputRef={props.inputRef}
      fullWidth
      helperText={props.helperText}
      error={props.error}
      className={classes.textField}
      InputLabelProps={{
        classes: {
          root: classes.inputLabel,
          focused: classes.labelFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
        },
        disableUnderline: true,
      }}
      {...props}
    />
  );
};

export const RHFSelect = (props) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  return (
    <Select
      variant="filled"
      name={props.name}
      label={props.label}
      inputRef={props.inputRef}
      fullWidth
      helperText={props.helperText}
      error={props.error}
      className={classes.selectField}
      InputLabelProps={{
        classes: {
          root: classes.inputLabel,
          focused: classes.labelFocused,
        },
      }}
      input={<OutlinedInput classes={outlinedInputClasses} />}
      InputProps={{
        classes: {
          root: classes.inputRoot,
        },
      }}
      {...props}
    ></Select>
  );
};

export const PhoneInputField = ({ value, onPhoneChange }) => (
  <PhoneInput
    country={"in"}
    disableSearchIcon={false}
    countryCodeEditable={true}
    enableSearch={true}
    value={value}
    onChange={(value, country, e, formattedValue) =>
      onPhoneChange(value, country, e, formattedValue)
    }
    containerStyle={{ marginBottom: 16 }}
    inputStyle={{
      color: "#030201",
      fontSize: 16,
      fontFamily: "MavenPro-Regular",
      height: "match-parent",
      backgroundColor: "#FFF3EB",
      height: 56,
      width: "100%",
      borderRadius: 8,
      border: 0,
    }}
    buttonStyle={{
      border: 0,
      backgroundColor: "#FFF3EB",
    }}
  />
);
