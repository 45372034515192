import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import ProductList from "./components/ProductList";
import Banner from "_Shared/Banner";
import { slugify } from "_Utils/Helpers";
import { getProductCat } from "_Store/action/productcategory";
import { Skeleton } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  prod_card: {
    marginTop: -500,
  },
}));

const Products = () => {
  const classes = useStyles();
  const _product = useSelector((state) => state.getProductCat);
  let productList = _product && _product.allProductCat;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProductCat());
  }, []);

  return (
    <Grid style={{ width: "100%" }}>
      {productList ? (
        productList &&
        productList.map((obj, i) => (
          <section
            id={slugify(obj.title ?? "")}
            key={i}
            style={{
              background: i % 2 === 0 ? "" : "#2C2E30",
              // paddingBottom: i % 2 === 0 ? 50 : 100,
              minHeight: 750,
            }}
          >
            <Banner
              // image={obj.imgUrl}
              productPage={
                <ProductList
                  title={obj._id}
                  heading={obj.title}
                  subtitle={obj.shortDesc}
                  subCategory={obj.subCategory}
                  requirements
                  idVal={slugify(obj.title ?? "")}
                  textColor={i % 2 === 0 ? "#61686C" : "#fff"}
                  descriptionColor={i % 2 === 0 ? "#2C2E30" : "#fff"}
                  isEven={i % 2 === 0 ? true : false}
                />
              }
              raised
              formStretched
              bannerborder={i > 0 ? true : false}
              contact_Second_banner
            />
          </section>
        ))
      ) : (
        <Skeleton variant="rect" height={900} width="100%" />
      )}
    </Grid>
  );
};

export default Products;
