import { combineReducers } from "redux";

import { postContactReducer } from "./contactReducer";
import { getAllVacancyReducer, JobApplyReducer } from "./careerReducer";
import { getNewsReducer } from "./news_eventsReducer";
import { getProductCat } from "./productCategoryReducer";
import { getDownloadReducer } from "./download.reducer";
import { getProductReducer, get_ALL_ProductReducer } from "./productReducer";
const rootReducer = combineReducers({
  getAllVacancyReducer,
  JobApplyReducer,
  postContactReducer,
  getNewsReducer,
  getProductCat,
  getProductReducer,
  get_ALL_ProductReducer,
  getDownloadReducer,
});

export default rootReducer;
