import React from "react";
import AnimatedDiv from "_Shared/AnimatedDiv";
import { Grid, makeStyles } from "@material-ui/core";

const Software = (props) => {
  const { bannerImage, rightDiv, leftDiv, OpenWrt, both_Iopsys_Gen } = props;
  const useStyles = makeStyles((theme) => ({
    root: {
      position: "relative ",
      height: "980px",
    },
    bannerImage: {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
    attrText: {
      padding: "0px 20px",
      textAlign: "start",
      fontSize: 14,
      lineHeight: "150%",
      color: "#FFFFFF",
      marginTop: 24,
    },
    right_content_div: {
      position: "absolute",
      width: "100%",
      top: OpenWrt ? "30%" : 130,
      padding: 16,
      [theme.breakpoints.down("xs")]: {
        top: 64,
        padding: 16,
      },
    },
    left_content_div: {
      position: "absolute",
      width: "100%",
      marginTop: 144,
      padding: 24,
      display: "flex",
      overFlowX: "inherit",
      left: 0,
      [theme.breakpoints.down("md")]: {
        padding: 16,
      },
    },
  }));
  const classes = useStyles();
  return (
    <Grid
      md={12}
      xs={12}
      sm={12}
      lg={12}
      item
      container
      className={classes.root}
      id={props?.id}
    >
      <Grid md={12} xs={12} sm={12} item className={classes.bannerImage}>
        <img
          src={bannerImage}
          alt="bannerImage"
          height="100%"
          width="100%"
          style={{ objectFit: "cover" }}
        />
        <Grid
          md={12}
          xs={12}
          lg={12}
          sm={12}
          item
          className={classes.right_content_div}
        >
          <Grid lg={11} item container justifyContent="flex-end">
            <Grid
              lg={7}
              sm={12}
              md={12}
              xs={12}
              item
              container
              justifyContent="flex-end"
            >
              <Grid lg={11} item>
                {rightDiv && (
                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    item
                    container
                    justifyContent="flex-end"
                  >
                    <AnimatedDiv children={rightDiv} animation="fade-left" />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid lg={1} item container justifyContent="flex-end" />
          <Grid
            md={12}
            xs={12}
            lg={12}
            sm={12}
            item
            className={classes.left_content_div}
          >
            <Grid lg={1} item />
            <Grid lg={11} item>
              <Grid
                lg={7}
                sm={12}
                md={12}
                xs={12}
                item
                container
                justifyContent="flex-end"
              >
                <Grid lg={11} item>
                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    item
                    container
                    justifyContent="flex-end"
                  >
                    <AnimatedDiv children={leftDiv} animation="fade-right" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Software;
