import * as types from '../action.type';
export const getProductAction = (data) => {
    return {
        type: types.GETPRODUCT,
        data,
    };
};
export const getProductActionSuccess = (data) => {
    return {
        type: types.GETPRODUCT_SUCCESS,
        data,
    };
};

export const getProductActionError = (data) => {
    return {
        type: types.GETPRODUCT_ERROR,
        data,
    };
};

export const get_all_product_Action = (data) => {
    return {
        type: types.GET_ALL_PRODUCT,
        data,
    };
};
export const get_all_product_ActionSuccess = (data) => {
    return {
        type: types.GET_ALL_PRODUCT_SUCCESS,
        data,
    };
};

export const get_all_product_ActionError = (data) => {
    return {
        type: types.GET_ALL_PRODUCT_ERROR,
        data,
    };
};
