import { takeLatest } from "redux-saga/effects";
import * as types from "../action.type";

import { postContactSaga } from "./contactsaga";
import { getVacancySaga, JobApplySaga } from "./careersaga";
import { getNewsSaga } from "./news_eventssaga";

import { getProductCatSAGA } from "./productCatsaga";

import { getProductSaga, get_ALL_ProductSaga } from "./productSaga";
import { getDownloadSaga } from "./download.saga";
function* watcher() {
  yield takeLatest(types.GETVACANCY, getVacancySaga);
  yield takeLatest(types.JOB_APPLY, JobApplySaga);
  yield takeLatest(types.POST_CONTACT, postContactSaga);

  yield takeLatest(types.GET_NEWS_EVENTS, getNewsSaga);

  yield takeLatest(types.GET_PRODUCT_CATEGORY, getProductCatSAGA);

  yield takeLatest(types.GETPRODUCT, getProductSaga);
  yield takeLatest(types.GET_ALL_PRODUCT, get_ALL_ProductSaga);
  yield takeLatest(types.DOWNLOAD, getDownloadSaga);
}

export default watcher;
