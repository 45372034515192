import { put, call } from 'redux-saga/effects';
import { getProductCat } from '../service/productcat';
import { getProductCatSuccess } from '../action/productcategory';
import * as types from '../action.type';

export function* getProductCatSAGA(data) {
    const response = yield call(getProductCat, data);
    if (response && !response.error) {
        yield put(getProductCatSuccess(response.data));
    } else {
        yield put({ type: types.GET_PRODUCT_CATEGORY_ERROR, response });
    }
}
